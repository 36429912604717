import React from "react";
import styles from "./includeSection.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import smallcircyle from "../../../assests/soletrade/smallcircyle.svg";
import lineWhitesBar from "../../../assests/soletrade/linesWhite.svg";
import bigcyrcle from "../../../assests/soletrade/bigcyrcle.svg";
import linerightBottom from "../../../assests/soletrade/linerightBottom.svg";
import linerighttop from "../../../assests/soletrade/linerighttop.svg";

interface DataItem {
  title: string;
  description: React.ReactNode;
  logo: string; // Assuming logo is a URL
  subp: string;
  sub: string;
}

interface Include {
  dataInclude: DataItem[]; // Use the defined interface for the data prop
}
const IncludeSection: React.FC<Include> = ({ dataInclude }) => {
  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="justify-content-center">
          {dataInclude.map((item, index) => (
            <React.Fragment key={index}>
              {index % 2 === 0 ? ( // Check if the index is even
                <>
                  <Row className=" p-0 ">
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={styles.includeSection}>
                        <h6>{item.sub}</h6>
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <h4>{item.subp}</h4>
                      </div>
                    </Col>
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={styles.includeImg}>
                        <div className={styles.soleImg}>
                          <img src={smallcircyle} alt="dummy" />
                        </div>
                        <div className={styles.soleLineImg}>
                          <img src={lineWhitesBar} alt="dummy" />
                        </div>
                        <div className={styles.soleBigImg}>
                          <img src={bigcyrcle} alt="dummy" />
                        </div>
                        <img src={item.logo} alt={item.title} />
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                // If the index is odd, swap the order
                <>
                  <Row className="mobileResponsive p-0">
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={styles.includeImg}>
                        <div
                          className={`${styles.soleImg} ${styles.soleImgInculde}`}
                        >
                          <img src={smallcircyle} alt="dummy" />
                        </div>
                        <div className={`${styles.soleLightImg}`}>
                          <img src={linerightBottom} alt="dummy" />
                        </div>
                        <div className={`${styles.linerighttop}`}>
                          <img src={linerighttop} alt="dummy" />
                        </div>
                        <img src={item.logo} alt={item.title} />
                      </div>
                    </Col>
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={styles.includeSection}>
                        <h6>{item.sub}</h6>
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <h4>{item.subp}</h4>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default IncludeSection;
