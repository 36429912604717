/* eslint-disable react/jsx-no-target-blank */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "./contactUs.module.scss";
import Form from "react-bootstrap/Form";
import { Col } from "react-bootstrap";
import CustomBtn from "../../../../common/theme/button";
import contactUs2 from "../../../../assests/contactUs2.png";
import lineBluelIght from "../../../../assests/lineBluelIght.svg";
import lineGrays from "../../../../assests/lineGray.svg";
import circleLight from "../../../../assests/circleLight.svg";
import whiteCircle from "../../../../assests/whiteCrcle.svg";
import circleBlue from "../../../../assests/circleBlue.svg";
import lineWhite from "../../../../assests/lineWhite.svg";
import officeLocation from "../../../../assests/Medium.svg";
import Header from "../../../../components/webapp/header";
import AboutUsFooter from "../../../../components/webapp/footer/aboutUsFooter";
import GreenDotAndLine from "../../../../assests/lineAndDot.svg";
import { useEffect } from "react";
import ContactFormComponent from "./ContactFormComponent";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className={styles.contactUs}>
        <Container>
          <div className={styles.mainPage}>
            <Row>
              <Col className="d-flex align-items-center col-md-5" xs={12}>
                <div className={styles.leftPage}>
                  <div className={styles.linegrayssTop}>
                    <img src={lineBluelIght} alt="" />
                  </div>
                  <div className={styles.provide}>
                    <h6>Here to help, always.</h6>
                    <h1>Get in touch</h1>
                    <p>
                      Have a question? We’re here to help. Simply complete the
                      form below, or email us at{" "}
                      <a href="mailto:support@skytax.co.uk">
                        support@skytax.co.uk
                      </a>
                    </p>
                    <div className={styles.buttons}>
                      {/* <CustomBtn>Call to action</CustomBtn>
                      <CustomBtn className={styles.actionButton}>
                        Call to action
                      </CustomBtn> */}
                    </div>
                  </div>

                  <div className={styles.linegrayss}>
                    <img src={lineGrays} alt="" />
                  </div>
                </div>
                <div className={`${styles.circlelight} ${styles.grow}`}>
                  <img src={circleLight} alt="" />
                </div>
                <div
                  className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                >
                  <img src={whiteCircle} alt="" />
                </div>
              </Col>
              <Col className=" col-md-7" xs={12}>
                <div className={styles.rightPage}>
                  <img src={contactUs2} alt="" />
                </div>
                <div className={styles.greenDotAndLine}>
                  <img src={GreenDotAndLine} alt="" />
                </div>
                <div className={styles.circleblue}>
                  <img src={circleBlue} alt="" />
                </div>
              </Col>
            </Row>
            <div className={styles.lineWhite}>
              <img src={lineWhite} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.contactFormContainer}>
        <Container>
          <h2>We currently help over 13,000 businesses in the UK to thrive </h2>
          <p>See what our clients have to say about us.</p>
          <div className={styles.formContainer}>
            <h5>Get in touch</h5>

            <ContactFormComponent />
            {/* <span>
              We will never share your details with any third-party.
              <br /> Please see our Privacy Policy for more details.
            </span> */}
          </div>
        </Container>
      </div>

      <div className={styles.MapSection}>
        <Container>
          <Row>
            <Col className="col-md-12 mb-4 pb-3" xs={12}>
              <div className={styles.officeHeading}>
                <h2>Find Us Here</h2>
                <p>
                  Our accountants and specialists work all over the UK and
                  beyond but our head <br /> office team are located in London,
                  UK
                </p>
              </div>
            </Col>
            <Col className="col-md-6" xs={12}>
              <div className={styles.maps}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.199050040284!2d-0.019642000000004036!3d51.504933000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602cdae6dbaed%3A0x6ec7d6d13c8a5dbc!2sOne%20Canada%20Square!5e0!3m2!1sen!2suk!4v1697667597392!5m2!1sen!2suk"
                  width="100%"
                  height="450"
                  loading="lazy"
                  title="lazy"
                ></iframe>
              </div>
            </Col>
            <Col className="col-md-6" xs={12}>
              <div className={styles.officeText}>
                <img src={officeLocation} alt="" />
                <div className={styles.innerText}>
                  <h3>London Office</h3>
                  <p>37th Floor, One Canada Square</p>
                  <p>Canary Wharf</p>
                  <p>London, E14 5AA</p>
                </div>
              </div>
            </Col>
            <div className={styles.contactFooter}>
              <div className={styles.contactInfo}>
                <div className={styles.contact1}>
                  <span>Phone </span> &nbsp;{" "}
                  <a href="tel:+0333 5775 33"> 0333 5775 332</a>
                </div>
                <div className={styles.contact1}>
                  <span>Email</span> &nbsp;{" "}
                  <a href="mailto:support@skytax.co.uk">support@skytax.co.uk</a>
                </div>
                <div className={styles.contact1}>
                  <span>Live Chat </span>&nbsp; Monday to Friday 9-5
                </div>
                <div className={styles.contact1}>
                  <span>Whatsapp </span> &nbsp;{" "}
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=03335775332"
                  >
                    {" "}
                    0333 5775 332
                  </a>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <AboutUsFooter />
    </>
  );
};

export default ContactUs;
