import React, { useEffect } from "react";
import styles from "./contractors.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/homePage/contractsnew.png";
import companies from "../../../../assests/homePage/sole2.svg";
import companies1 from "../../../../assests/homePage/ship5.svg";
import companies2 from "../../../../assests/homePage/dollarImg.svg";
import box from "../../../../assests/homePage/user.svg";
import box1 from "../../../../assests/homePage/location.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import box2 from "../../../../assests/homePage/new.svg";
import box3 from "../../../../assests/homePage/newbox.svg";
import box4 from "../../../../assests/homePage/cis3.svg";
import box5 from "../../../../assests/homePage/cal.svg";
import ship from "../../../../assests/guidesIcon.svg";
import ship1 from "../../../../assests/homePage/ship2.svg";
import ship2 from "../../../../assests/homePage/book.svg";
import user from "../../../../assests/homePage/user.svg";
import blueLine from "../../../../assests/homePage/lineBlue.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import imageSrc from "../../../../assests/newlarge.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import trade from "../../../../assests/soletrade/one.png";
import trade2 from "../../../../assests/soletrade/two.png";
import trade3 from "../../../../assests/soletrade/three.png";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import IncludeSection from "../../../../components/webapp/includeSection";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import { Link, useNavigate } from "react-router-dom";

const data = [
  {
    title: "Full-service accounts",
    description:
      "Statutory accounts prepared and submitted. Management accounts and a full suite of reports for the business insight you need.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Free online bookkeeping software",
    description:
      "Free access to hibooks means you can ditch manual data entry for simple snap’n’go online bookkeeping - and there’s much more!",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Self-assessment",
    description:
      "No more penalties. No more last-minute filing stress. We’ll take care of your self-assessment tax return. Accurate and on-time. Always.",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "VAT Return",
    description:
      "Your VAT Returns submitted on time, always. We’re fully HMRC-approved and Making Tax Digital compliant.",
    logo: box3,
    lineBar: lineBar,
  },
  {
    title: "Dedicated accountant",
    description:
      "We’ll never give you the run around - just consistent, expert advice and support from your own Skytax accountant.",
    logo: box4,
    lineBar: lineBar,
  },
  {
    title: "Expert advice",
    description:
      "Your very own Skytax accountant is always just a call away, for expert advice and support when you need help and advice. It’s all part of the service.",
    logo: box5,
    lineBar: lineBar,
  },
  // Add more data objects as needed
];

const dataInclude = [
  {
    title: "The transition to Skytax is smooth and painless.",
    description:
      "Switching accountants doesn’t have to disrupt your business operations or impact on your time.",
    logo: trade,
    sub: "Take the leap.",
    subp: "You can confidently leave the handover process to us. so you can run your contracting business without the burden of administrative complexities",
    subpp:
      "As your new accountants we’ll take care of the entire changeover from your previous accountant or bookkeeper. We will start by contacting them to request all the information we need to correspond with HMRC and act on your behalf. Being a sole trader doesn’t mean you’re alone!",
  },
  {
    title: "Customised services to suit you.",
    description:
      "We’ll get you sorted with accounting essentials – then you can pick and choose extra services, like bookkeeping or tax planning, when or if you need them.",
    logo: trade2,
    sub: "Flexible contractor accounting. ",
    subp: "We’re set up to handle your entire partnership accounting needs. But you’ll get the same top quality service if you only need help with one specific area too.",
    subpp:
      "That’s why we offer a range of additional accounting services to sole traders, such as bookkeeping, VAT returns, payroll or pensions, ready for you to add or remove as and when you need them.",
  },
  {
    title: "Get hibooks for free",
    description: (
      <span>
        {" "}
        Imagine if you had more time for strategy. More headspace for marketing.
        More bandwidth for delighting your clients while still taking care of
        the day-to-day numbers. <br />
        <br />
        You can. Welcome to hibooks
      </span>
    ),
    logo: trade3,
    sub: "Welcome to hibooks.",
    subp: "hibooks is our hassle-free, online bookkeeping platform that’s built to eliminate manual data tasks and streamline your finances. It’s packed with the AI-driven features like smart invoicing, snap-n-go expenses, and automated real-time reporting that give you months’ of time and focus back.",
    subpp:
      "To protect you from the stress of an HMRC audit or inspection, we will act on your behalf and fight your corner with HMRC, at no additional cost.",
  },
  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Business Option Guide",
    description:
      "Not sure if setting up as a contractor is right for you? Learn the pros and cons of different business structures.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies,
  },
  {
    title: "IR35 Guide",
    description:
      "Familiarise yourself with IR35 rules to help you set up the right working arrangements with clients.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,

    logo: companies1,
  },
  {
    title: "Guide to Expenses",
    description:
      "Are you claiming for all allowable contractor expenses? Find out in this guide.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,

    logo: companies2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Reach out to us",
    description: "Simply pop your details into our online form here .",
    logo: ship,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Let’s talk",
    description:
      "We’ll call you for a no-obligation chat about the services you’d like.",
    logo: ship1,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "We do the rest",
    description:
      "Once confirmed, we’ll set everything up for you - you don’t need to lift a finger!",
    logo: ship2,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  {
    title: "Matthew ",
    subHead: "Saroia Staffing Services Ltd ",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  {
    title: "Gordon Whitings",
    subHead: "",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  // Add more data objects as needed
];

const Contractors = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="light" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Expert. Proactive. Affordable.</h6>
                      {/* <h1>
                        Comprehensive accountancy <br /> services for limited{" "}
                        <br />
                        companies
                      </h1> */}
                      <h1>
                        Professional accounting services especially designed for
                        contractors.
                      </h1>
                      <p>
                        You work hard. Your finances are probably way down on
                        your list. Contracting is complex, but with us on your
                        side, you can relax. It’s sorted.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        {/* <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                        >
                          Get started
                        </Button> */}
                        <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                          className={styles.actionButton}
                        >
                          Get started
                        </Button>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.WhoDoWe}>
          <Services
            data={data}
            heading="What you get"
            subHeading="A proactive and professional accounts and tax service, with peace of mind always fitted as standard."
            imageSrc={imageSrc}
            textHead="HMRC representation"
            text=" We can directly liaise with HMRC to handle your tax affairs on your behalf – saving you time and reducing stress."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} height={375} />
        </div>
        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="Getting started"
            subHeading="Get Skytax comprehensive accounting services specially for contractors  in just three simple steps."
          />
        </div>

        <div className={styles.HiBooks}>
          <IncludeSection dataInclude={dataInclude} />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Explore our resources for contractors"
            // subHeading="We have a comprehensive range of free resources available to help your business run as smoothly."
            subHeading=""
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            heading="Great support for your business, fast."
            subheading="Get a quote for our Contractors  service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="But you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="If you’re curious about how much it’ll cost, don’t wait, get a quote for our comprehensive contractors services now. Simply complete and submit the form."
            id={undefined}
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default Contractors;
