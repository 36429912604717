import { useEffect, useState } from "react";
import styles from "./blog.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import AddBlogsForm from "../../../components/admin/addBlogs/addBlogForm";
import { useLocation } from "react-router-dom";
function AddEditBlog() {
  const [addEmploySidebar, setAddEmploySidebar] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<any | null>(null);
  const [editView, setEditView] = useState(false);
  const [initinalValues, setInitinalValues] = useState({
    id: "",
    title: "",
    description: "",
    files: "",
    category_id: "",
  });


  const location = useLocation();

  const toggleEmploySidebar = () => {
    setAddEmploySidebar(!addEmploySidebar);
  };




  useEffect(() => {
    if (location.state) {
      setEditView(true)
      setEditableItem(location.state)
      // setInitinalValues({
      //   id: location?.state?.item?.id,
      //   title: location?.state?.item?.name,
      //   description: location?.state?.item?.descriptions,
      //   images: location?.state?.item?.images,
      // })
    }
  }, [location.state]);


  return (
    <DashboardLayout>
      <div className={styles.users_page}>
        <AddBlogsForm
          toggleSidebar={toggleEmploySidebar}
          editView={editView}
          editableItem={editableItem}
          initinalValues={initinalValues}
          setInitinalValues={setInitinalValues}
        />

      </div>
    </DashboardLayout>
  );
}
export default AddEditBlog;
