import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomBtn from "../../../../../common/theme/button";
import styles from "./caseStudiesFeature.module.scss";
import smallcircyle from "../../../../../assests/soletrade/smallcircyle.svg";
import lineWhitesBar from "../../../../../assests/soletrade/linesWhite.svg";
import bigcyrcle from "../../../../../assests/soletrade/bigcyrcle.svg";
import linerightBottom from "../../../../../assests/soletrade/linerightBottom.svg";
import linerighttop from "../../../../../assests/soletrade/linerighttop.svg";

interface CaseStudyFeatureItem {
  shortTitle: string;
  title: string;
  description1: string;
  description2: string;
  buttonText: string;
  logoImg: string; // Assuming logoImg is a URL
}
interface Feature {
  caseStudyData: CaseStudyFeatureItem[];
}

const CaseStudiesFeature: React.FC<Feature> = ({ caseStudyData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.caseStudyFeature}>
      <Container>
        <Row className="justify-content-center">
          {caseStudyData?.map((item, index) => (
            <React.Fragment key={index}>
              {index % 2 === 0 ? ( //check if index is even
                <>
                  <Row className="p-0">
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={`${styles.caseStudyFeature1} hoverBtn`}>
                        <span>{item.shortTitle}</span>
                        <h3>{item.title}</h3>
                        <p className={styles.desc1}>{item.description1}</p>
                        <p>{item.description2}</p>
                        <CustomBtn>View Case Study</CustomBtn>
                      </div>
                    </Col>
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={styles.caseStudyImg}>
                        <div className={styles.soleImg}>
                          <img src={smallcircyle} alt="" />
                        </div>
                        <div className={styles.soleLineImg}>
                          <img src={lineWhitesBar} alt="" />
                        </div>
                        <div className={styles.soleBigImg}>
                          <img src={bigcyrcle} alt="" />
                        </div>
                        <img src={item.logoImg} alt={item.title} />
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                //If the index is odd,revere the img and content
                <>
                  <Row className="mobileResponsive p-0">
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={styles.caseStudyImg}>
                        <div
                          className={`${styles.soleImg} ${styles.soleImgInculde}`}
                        >
                          <img src={smallcircyle} alt="" />
                        </div>
                        <div className={`${styles.soleLightImg}`}>
                          <img src={linerightBottom} alt="" />
                        </div>
                        <div className={`${styles.linerighttop}`}>
                          <img src={linerighttop} alt="" />
                        </div>
                        <img src={item.logoImg} alt={item.title} />
                      </div>
                    </Col>
                    <Col className="col-md-6 mb-4 pb-2" xs={6}>
                      <div className={`${styles.caseStudyFeature1} hoverBtn`}>
                        <span>{item.shortTitle}</span>
                        <h3>{item.title}</h3>
                        <p className={styles.desc1}>{item.description1}</p>
                        <p>{item.description2}</p>
                        <CustomBtn>View Case Study</CustomBtn>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CaseStudiesFeature;
