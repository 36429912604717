import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomBtn from "../../../../common/theme/button";
import styles from "./freeBookKeeping.module.scss";
import lineBluelIght from "../../../../assests/lineBluelIght.svg";
import lineGrays from "../../../../assests/lineGray.svg";
import circleLight from "../../../../assests/circleLight.svg";
import whiteCircle from "../../../../assests/whiteCrcle.svg";
import freeBookKeeping from "../../../../assests/freeBookKeeping.png";
import circleBlue from "../../../../assests/circleBlue.svg";
import lineWhite from "../../../../assests/lineWhite.svg";
import CaseStudy from "../../aboutUs/caseStudies/caseStudiesContainer";
import bookKeeping1 from "../../../../assests/bookKeeping1.svg";
import bookKeeping2 from "../../../../assests/bookKeeping2.svg";
import bookKeeping3 from "../../../../assests/bookKeeping3.svg";
import IncludeSection from "../../../../components/webapp/includeSection";
import freeBookKeeping1 from "../../../../assests/freebookKeeping1.svg";
import freeBookKeeping2 from "../../../../assests/freebookKeeping2.svg";
import freeBookKeeping3 from "../../../../assests/freebookKeeping3.svg";
import freehibook from "../../../../assests/freehibooks.svg";
import FeatureOfSoftware from "../../../../components/webapp/featureOfSoftware";
import lineBar from "../../../../assests/lineBar.svg";
import freeSoftware1 from "../../../../assests/freeSoftware1.svg";
import freeSoftware2 from "../../../../assests/freeSoftware2.svg";
import freeSoftware33 from "../../../../assests/freeSoftware33.svg";
import freeSoftware4 from "../../../../assests/freeSoftware4.svg";
import freeSoftware5 from "../../../../assests/freeSofware5.svg";
import freeSoftware66 from "../../../../assests/freeSoftware66.svg";
import freeSoftware7 from "../../../../assests/freeSoftware7.svg";
import freeSoftware8 from "../../../../assests/freeSoftware8.svg";
import freeSoftware9 from "../../../../assests/freeSoftware9.svg";
import CallToActionSoftware from "../../../../assests/CallToActionSoftware.svg";
import GreenDotAndLine from "../../../../assests/lineAndDot.svg";
import FreeBookFooter from "./freeBookFooter";
import Header from "../../../../components/webapp/header";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const BookKeepingSoftware = () => {
  const data = [
    {
      title: "Be fast",
      description:
        "Minimum input, maximum speed. hibooks’s automation and clever AI make your finances frictionless.",
      logo: bookKeeping1,
    },
    {
      title: "Be confident",
      description:
        "Error-free accounting and pinpoint precision means awesome accuracy you can count on.",
      logo: bookKeeping2,
    },
    {
      title: "Get the big picture",
      description:
        "Everything in one place gives you the financial oversight you’ve been looking for.",
      logo: bookKeeping3,
    },
  ];

  const dataInclude = [
    {
      title: "Finance made easy",
      description:
        "We designed hibooks to make your bookkeeping tasks easier, faster, more efficient and more accurate.",
      logo: freeBookKeeping1,
      sub: "Fast, accurate finances",
      subp: "It’s got it all - and then some. Automated bank feeds? Yep. Smart receipt scanning and easy expense management? Of course. Financial reports that practically create themselves? Absolutely. And there’s loads more - all designed to make your business life easier.",

      subpp: " ",
    },
    {
      title: "Always here to help",
      description:
        "hibooks is user-friendly and intuitive - but that doesn’t mean you’re on your own! Skytax clients can rely on us for help and support 24/7. ",
      logo: freeBookKeeping2,
      sub: "Fully secure and supported",
      subp: "We’ve designed hibooks to eliminate human error and banish the bottlenecks you get with manual accounts. But regular check-ins to make sure you’re getting the best out of hibooks are all part of our service.  ",
      subpp: "",
    },
    {
      title: "Fully MTD Compliant",
      description:
        "hibooks is fully functional, compatible and compliant with HMRC’s Making Tax Digital requirements, and makes it a doddle to file accurate VAT returns on time.",
      logo: freeBookKeeping3,
      sub: "No-tears tax or VAT with va-va-voom!",
      subp: "But are you paying more VAT than you need? Or in a bit of a jam with HMRC? Sign up to Skytax’s VAT service for regular VAT-busting reviews and effective dispute representation  ",
      subpp: "",
    },
    // Add more data objects as needed
  ];

  const dataFeatureSoftware = [
    {
      title: "Fuss-free Banking",
      description:
        "Secure bank connections powered up with automated bank feeds, auto-categorisation and matching, superfast reconciliation as well as a realtime, detailed overview of your business health.",
      logo: freeSoftware1,
      lineBar: lineBar,
    },
    {
      title: "Easy Expenses",
      description:
        "Comprehensive expense management packed with automation for fast, accurate (task): tracks expenses, autocompletes expense and mileage forms, calculates VAT and mileage costs and more.",
      logo: freeSoftware2,
      lineBar: lineBar,
    },
    {
      title: "Seamless Receipts",
      description:
        "Simply snap and upload those fiddly receipts and let hibooks do the rest.",
      logo: freeSoftware33,
      lineBar: lineBar,
    },
    {
      title: "Bills",
      description:
        "Get an overview of everything you owe, and when it's due. Stay on top of cash flow. Track, automate and pay your bills on time, every time.",
      logo: freeSoftware4,
      lineBar: lineBar,
    },
    {
      title: "Invoicing",
      description:
        "Professional invoicing, loads of payment options and no-tears automated invoice tracking eliminates chasing and gets you paid faster .",
      logo: freeSoftware5,
      lineBar: lineBar,
    },
    {
      title: "Making Tax Digital",
      description:
        "Oh-so-simple MTD-ready VAT management that takes the headaches out of your VAT Return. Simply click to create, track and send to HMRC.",
      logo: freeSoftware66,
      lineBar: lineBar,
    },
    {
      title: "Contacts",
      description:
        "Skyrocket sales using bespoke customer smart lists for more effective marketing Set up individualised credit control and discounts, and get an at-a-glance big picture on your best (and worst) customers.",
      logo: freeSoftware7,
      lineBar: lineBar,
    },
    {
      title: "Stay Mobile",
      description:
        "Manage it all on-the-go with the hibooks app for iOS and Android. Snap receipts, check your profitability, check your bank and tax position and more - you’ve everything you need, wherever you are.",
      logo: freeSoftware8,
      lineBar: lineBar,
    },
    {
      title: "Secure and supported",
      description:
        "hibooks is fully secure, armed with industry standard data protection. hibooks is designed to be simple to use but if you need support we’re always here for you.",
      logo: freeSoftware9,
      lineBar: lineBar,
    },

    // Add more data objects as needed
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className={styles.homePage}>
        <Container>
          <div className={styles.mainPage}>
            <Row>
              <Col className="d-flex align-items-center col-md-5" xs={12}>
                <div className={styles.leftPage}>
                  <div className={styles.linegrayssTop}>
                    <img src={lineBluelIght} alt="" />
                  </div>
                  <div className={styles.provide}>
                    <h6>Frictionless Finances for Free</h6>
                    <h1>hibooks Pro. Powerful online accounting software.</h1>
                    <p>
                      Secure, easy-to-use online bookkeeping tools designed for
                      how you do business
                    </p>
                    <div className={`${styles.buttons} hoverBtn`}>
                      <CustomBtn>Free for Skytax clients</CustomBtn>
                      <CustomBtn className={styles.actionButton}>
                        Fully MTD Compliant
                      </CustomBtn>
                    </div>
                  </div>
                  <div className={styles.linegrayss}>
                    <img src={lineGrays} alt="" />
                  </div>
                </div>

                <div className={`${styles.circlelight} ${styles.grow}`}>
                  <img src={circleLight} alt="" />
                </div>
                <div
                  className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                >
                  <img src={whiteCircle} alt="" />
                </div>
              </Col>
              <Col className="col-md-7" xs={12}>
                <div className={styles.rightPage}>
                  <img src={freeBookKeeping} alt="" />
                </div>
                <div className={styles.greenDotAndLine}>
                  <img src={GreenDotAndLine} alt="" />
                </div>
                <div className={styles.circleblue}>
                  <img src={circleBlue} alt="" />
                </div>
              </Col>
            </Row>
            <div className={styles.lineWhite}>
              <img src={lineWhite} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div>
        <CaseStudy
          data={data}
          heading="Get hibooks for Free"
          subHeading="When you join Skytax, you’ll have exclusive free access to hibooks, saving you £300 a year."
        />
      </div>
      <div>
        <IncludeSection dataInclude={dataInclude} />
      </div>
      <div className={styles.freeHiBook}>
        <Container>
          <h2>hibooks in action</h2>
          <p>
            Find out how hibooks makes your finances faster, easier and more
            accurate.
          </p>
          <div className={styles.centerImg}>
            <ReactPlayer
              url={"https://www.youtube.com/embed/wdK8Ofl0q1M"}
              controls={true}
              light={true}
              style={{
                // height: "640px",
                // width: "1140px",
                borderRadius: "48px",
              }}
              height="100%"
              width="100%"
            />
            {/* <iframe
              width="917"
              height="516"
              src="https://www.youtube.com/embed/r_sfan3mVPU?title=0&modestbranding=1"
              title="Home values are on the rise but so are landlords | The Business"
              // frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              // referrerpolicy="strict-origin-when-cross-origin"
              // allowfullscreen
            ></iframe> */}
            {/* <img src={freehibook} alt="" /> */}
          </div>
        </Container>
      </div>
      <div className={styles.featureSoftwareContainer}>
        <h2>Features of our software</h2>
        <p className={styles.head}>
          Easy to use, efficient and accurate. Here are just some of the
          features of our bookkeeping software.
        </p>
        <div className={styles.featureSoftwareWrapper}>
          <FeatureOfSoftware dataFeatureSoftware={dataFeatureSoftware} />
        </div>
      </div>
      <div className={styles.callToAction}>
        <Container>
          <div className={styles.row1}>
            <Row>
              <Col className="col-md-6" xs={12}>
                <div>
                  <span>Easily manage your books on-the-go</span>
                  <h2>
                    Use the hibooks mobile app to send invoices, upload expenses
                    and check your financial position, all on the fly.
                  </h2>
                </div>
              </Col>
              <Col className="col-md-6" xs={12}>
                <div className={styles.row1RightContent}>
                  <p>
                    hibooks for mobile will save you heaps of time and efforts
                    thanks to intelligent features like receipt scanning,
                    automatic expense categorisation and customised invoice
                    creation.
                  </p>
                  <p className={styles.small}>
                    Get a comprehensive view of business performance and
                    profitability via the hibooks user-friendly dashboard and
                    real-time reporting. And know your business finances are
                    always in safe hands with automated software backups.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="col-md-12">
              <img src={CallToActionSoftware} alt="" />
            </Col>
          </Row>
          <div className={styles.row3}>
            <Row>
              <Col className="col-md-6" xs={12}>
                <p className={styles.small}>
                  Manage customers, suppliers, invoices and expenses in just a
                  few taps, all within the hibooks mobile app.
                </p>
              </Col>
              <Col className="col-md-6" xs={12}>
                <div className={`${styles.buttons} hoverBtn`}>
                  <Link to="https://hibooks.com/" target="_blank">
                    <CustomBtn>Explore hibooks</CustomBtn>
                  </Link>
                  <Link to="/about-us/contact-us">
                    <CustomBtn
                      className={styles.actionButton}
                      variant="outline-primary"
                    >
                      Ask a question
                    </CustomBtn>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <FreeBookFooter />
    </>
  );
};

export default BookKeepingSoftware;
