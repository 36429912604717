/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef } from "react";
import styles from "./bookKeeping.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/homePage/soleTrade.png";
import companies from "../../../../assests/homePage/bags.svg";
import companiesnew from "../../../../assests/homePage/companies.svg";
import companies1 from "../../../../assests/homePage/companies1.svg";
import companies2 from "../../../../assests/homePage/companies2.svg";
import box from "../../../../assests/homePage/new3.svg";
import dollarImg from "../../../../assests/homePage/ship2.svg";
import box1 from "../../../../assests/homePage/slide4.svg";
import box2 from "../../../../assests/homePage/service5.svg";
import slide2 from "../../../../assests/homePage/service4.svg";
import book from "../../../../assests/homePage/book.svg";
import ship1 from "../../../../assests/homePage/ship5.svg";
import ship2 from "../../../../assests/homePage/dollarImg.svg";
import user from "../../../../assests/homePage/user.svg";
import whiteLineGray from "../../../../assests/services/whiteLineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import box4 from "../../../../assests/homePage/box4.svg";
import box5 from "../../../../assests/homePage/box5.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import WhoDoWe from "../../../../components/webapp/whoDoWe";
import HiBooks from "../../../../components/webapp/hiBooks";
import { Link } from "react-router-dom";

const data1 = [
  {
    title: "Dedicated bookkeeper",
    description:
      "Meet your new business bestie - your Skytax bookkeeper, working for you.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Free accounting software",
    description:
      "Use hibooks, our HMRC-compliant online accounting software, for free.",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Forget the paperwork ",
    description: "Upload receipts, invoices and expenses with a snap or click.",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "You’re in control, 24/7",
    description: "Check out your records online anytime, day or night.",
    logo: slide2,
    lineBar: lineBar,
  },

  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Accounts",
    description:
      "Our comprehensive and flexible range of professional accounts gives you the time and insight to manage your business even better.",
    buttonText: "Learn more",
    onclick: <Link to="/services/accounts" />,
    logo: ship1,
  },
  {
    title: "Tax Returns",
    description:
      "Take the trauma out of tax effortlessly. We won’t just prepare your tax return accurately and expertly, we’ll optimise your tax position to minimise what you owe. ",
    buttonText: "Learn more",
    onclick: <Link to="/services/tax-returns" />,

    logo: box5,
  },
  {
    title: "VAT Returns",
    description:
      "Stay ahead and penalty-free with fast, accurate and on-time VAT Returns.  Fully HMRC compliant and MTD-ready.",
    buttonText: "Learn more",
    onclick: <Link to="/services/vat-returns" />,

    logo: box4,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Send us your stuff",
    description:
      "Simply email or snail mail us your records. When? You decide! ",
    logo: book,
    blueLine: whiteLineGray,
    count: "1",
  },
  {
    title: "We prepare your records",
    description:
      "We’ll upload, categorise and prepare your records on hibooks or other software of your choice. ",
    logo: companies2,
    blueLine: whiteLineGray,
    count: "2",
  },
  {
    title: "At-a-glance information",
    description:
      "You’ll always know how your business is doing. Simply log on for real time updates. ",
    logo: companiesnew,
    blueLine: whiteLineGray,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Dr J Afridi ",
    subHead: "   ",
    description:
      "Skytax Accounting has been a game-changer for my medical practice. Their tailored approach and expertise in healthcare finances have simplified my workload. I highly recommend them to fellow doctors and healthcare professionals— their dedication and support have been invaluable.",
    logo: user,
  },
  {
    title: "Philips",
    subHead: "",
    description:
      "As an IT freelancer running my own limited company in the UK, finding an accounting practice that truly understands the complexities of my industry was crucial. I discovered Skytax and was immediately impressed by their tailored approach. Their expertise in handling the specific financial intricacies of IT contractors and managing expenses has been invaluable. They offer proactive advice, ensuring my company’s financial health remains robust while I focus on delivering top-notch services to my clients. Their dedication, industry knowledge, and personalized support make them an indispensable asset to any freelancer seeking reliable financial guidance.",
    logo: user,
  },
  {
    title: "Matthew",
    subHead: "Saroia Staffing Services Ltd",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  // Add more data objects as needed
];

const dataBooks = [
  {
    title: "Expertise",
    description:
      "We pride ourselves on our expertise and attention to detail. You can expect accurate, optimised records.",
    logo: companies1,
  },
  {
    title: "Get your time back",
    description:
      "Numbers - we love ‘em, and it’s one thing less for you to do. Get back time for more strategic stuff. ",
    logo: ship2,
  },
  {
    title: "Peace of mind",
    description:
      "Forget penalties. Forget fines. Our bookkeeping services have reliability, optimisation and accuracy built in.",
    logo: companies,
  },
  // Add more data objects as needed
];

const data = [
  {
    title: "Bookkeeping",
    price: "From £10 per month*",
    description: "Always expert. Always secure.",
    buttonText: "Get started",
    logo: dollarImg,
    onclick: <a href="#book" />,
  },

  // Add more data objects as needed
];

const BookKeeping = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const whoDoWeRef = useRef<HTMLDivElement>(null);

  const handleFindOutClick = () => {
    if (whoDoWeRef.current) {
      whoDoWeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>We crunch your numbers</h6>
                      <h1>Bookkeeping</h1>
                      <p>
                        Accurate, complete bookkeeping services to save you time
                        and headspace
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        <Button onClick={handleFindOutClick}>
                          Find out more
                        </Button>
                        <Link to="/about-us/contact-us">
                          {" "}
                          <Button className={styles.actionButton}>
                            Get started
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div ref={whoDoWeRef} className={styles.WhoDoWe}>
          <Services
            data={data1}
            heading="What you get"
            subHeading="Comprehensive, consistently excellent bookkeeping services."
            text={""}
            imageSrc={""}
            type="ServicesAccount" // imageSrc={imageSrc}
            // text=" We will never tie you in to a long-term contract. You are free
            // to leave whenever you want, we're just confident you
            // won't want to!"
          />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="How it works"
            subHeading="Expert, secure bookkeeping in just three steps."
            type="accounts"
          />
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            type="book"
            heading="What it costs"
            subHeading="Full-service bookkeeping at an affordable price."
            innerText=""
          />
        </div>

        <div className={styles.HiBooks}>
          <HiBooks
            dataBooks={dataBooks}
            heading="Why choose us?"
            subHeading="Your business relies on the details - like excellent bookkeeping. We take care of it. "
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} type="reviweBg" />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Need other accountancy and business services? No problem. We’re here to help. "
            subHeading="Don’t limit yourself. Choose from a tried, tested and trusted range of expert services that make life easier and business better. "
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            id="book"
            heading="Great support for your business, fast."
            subheading="Get a quote for our Bookkeeping Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our Bookkeeping services now. Simply complete and submit the form."
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default BookKeeping;
