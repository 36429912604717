import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import styles from "../editProfile/changeForm.module.scss";
import "react-quill/dist/quill.snow.css"; // Import styles
import { useCallback, useEffect, useState } from "react";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import useFetch from "../../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/common";
// import { setUserData } from "./redux/common";

function EditProfile() {
  const [userDetails, setUserDetails] = useState<any>();
  const [userId, setUserId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const schema = yup.object().shape({
    first_name: yup.string().required("First name is required "),
    last_name: yup.string().required("Last name is required "),
  });

  const { id } = useParams();

  const userDataId = localStorage.getItem("userData");

  useEffect(() => {
    if (userDataId !== null) {
      const userData = JSON.parse(userDataId);
      const userIdFromStorage = userData?.data?.user?.id;
      if (userIdFromStorage) {
        setUserId(userIdFromStorage);
      }
    }
  }, [userDataId]);

  const [userDetailsApi] = useFetch(`/v1/user/profile/${userId}`, {
    method: "post",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data } = await userDetailsApi();

        setUserDetails(data?.data);
        // let ss = localStorage.getItem('userData');
        // if (!!ss) {
        //   const loclaData = JSON.parse(ss);
        //   let newObj = {
        //     ...loclaData,
        //   }
        //   localStorage.setItem('userData',newObj)

        // }
        // localStorage.setItem()

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoading(false);
      }
    };
    if (userId) {
      fetchUserDetails();
    }
    // eslint-disable-next-line
  }, [userId]);

  const [editProfileApi] = useFetch(`/v1/auth/update-profile`, {
    method: "post",
  });

  const handleSubmit = useCallback(
    async (values) => {
      let confirmValues = {
        first_name: values.first_name,
        last_name: values.last_name,
      };
      try {
        setLoading(true);
        const response = await editProfileApi({ ...confirmValues });
        // Handle the API response here

        setLoading(false);
        if (response.data && response.data.status === 1) {
          // Redirect to the admin dashboard on success
          // Save the response data in local storage
          localStorage.setItem("userData", JSON.stringify(response.data));
          dispatch(setUserData(response.data));
          navigate("/admin/dashboard");
        } else {
          // Handle API response errors here
          console.error("API Request Error:", response?.data?.message);
        }
      } catch (error) {
        // Handle API request error
        console.error("API Request Error:", error);
        setLoading(false);
      }
      // eslint-disable-next-line
    },
    [editProfileApi]
  );

  return (
    <DashboardLayout>
      <div className={styles.users_page}>
        <div className={styles.blogBox}>
          <Formik
            validationSchema={schema}
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={{
              first_name: userDetails?.first_name || "",
              last_name: userDetails?.last_name || "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <>
                <Form noValidate onSubmit={handleSubmit}>
                  <div className={styles.formStep}>
                    <h4>Edit Profile</h4>

                    <div
                      className={`${styles.twoFieldsWrap} ${styles.twoFlexRemove}`}
                    >
                      <Row>
                        <Col md="6" lg="6">
                          <Form.Group className={styles.singleField}>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First name"
                              name="first_name"
                              value={values.first_name}
                              onChange={handleChange}
                              isInvalid={
                                !!errors.first_name && touched.first_name
                                  ? true
                                  : false
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {typeof errors?.first_name === "string"
                                ? errors?.first_name
                                : ""}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="6" lg="6">
                          <Form.Group className={styles.singleField}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last name"
                              name="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                              isInvalid={
                                !!errors.last_name && touched.last_name
                                  ? true
                                  : false
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {typeof errors?.last_name === "string"
                                ? errors?.last_name
                                : ""}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className={styles.singleField}>
                        <button
                          className={`mt-2 mb-0 btn btn-primary ${styles.subBtn}`}
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </DashboardLayout>
  );
}
export default EditProfile;
