import React, { useEffect } from "react";
import styles from "./soleTrade.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/homePage/soletradenew.png";
import sole from "../../../../assests/homePage/sole.svg";
import sole1 from "../../../../assests/homePage/sole1.svg";
import sole2 from "../../../../assests/homePage/sole2.svg";
import box from "../../../../assests/homePage/new1.svg";
import box1 from "../../../../assests/homePage/service4.svg";
import box2 from "../../../../assests/homePage/service5.svg";
import ship from "../../../../assests/homePage/dollarImg.svg";
import ship1 from "../../../../assests/homePage/bags.svg";
import ship2 from "../../../../assests/homePage/ship5.svg";
import service4 from "../../../../assests/homePage/slide4.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import service5 from "../../../../assests/homePage/slide3.svg";
import service6 from "../../../../assests/homePage/slide2.svg";
import user from "../../../../assests/homePage/user.svg";
import blueLine from "../../../../assests/homePage/lineBlue.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import imageSrc from "../../../../assests/homePage/longterm.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import trade from "../../../../assests/soletrade/trade.png";
import trade2 from "../../../../assests/soletrade/trade2.png";
import trade3 from "../../../../assests/soletrade/trade3.png";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import IncludeSection from "../../../../components/webapp/includeSection";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import { Link, useNavigate } from "react-router-dom";

const data = [
  {
    title: "Full-service accounts",
    description:
      "Statutory accounts prepared and submitted. Management accounts and a full suite of reports for the business insight you need.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Free online bookkeeping software",
    description:
      "Free access to hibooks means you can ditch manual data entry for simple snap’n’go online bookkeeping - and there’s much more!",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Self-assessment",
    description:
      "No more penalties. No more last-minute filing stress. We’ll take care of your self-assessment tax return. Accurate and on-time. Always.",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "VAT Return",
    description:
      "Your VAT Returns submitted at the right time, in the right way. We’re fully HMRC-approved and Making Tax Digital compliant.",
    logo: service4,
    lineBar: lineBar,
  },
  {
    title: "Expert advice",
    description:
      "Your very own Skytax accountant is always just a call away, for expert advice and support when you need help and advice. It’s all part of the service.",
    logo: service5,
    lineBar: lineBar,
  },
  {
    title: "HMRC representation",
    description:
      "We can directly liaise with HMRC to handle your tax affairs on your behalf – saving you time and reducing stress.",
    logo: service6,
    lineBar: lineBar,
  },
  // Add more data objects as needed
];

const dataInclude = [
  {
    title: "Switching accountants is easy",
    description:
      "We know the thought of moving accountants is daunting. But switching is straightforward and hassle-free.",
    logo: trade,
    sub: "Simple and quick transition.",
    subp: "We’ll handle the switch so you can get on with day-to-day business. All we need to get started is your previous accountant or bookkeeper’s details so we can request your information.",
    subpp:
      "As your new accountants we’ll take care of the entire changeover from your previous accountant or bookkeeper. We will start by contacting them to request all the information we need to correspond with HMRC and act on your behalf. Being a sole trader doesn’t mean you’re alone!",
  },
  {
    title: "Cherry pick the services you need",
    description:
      "Just need help with self-assessment or bookkeeping? That’s fine. And if you need the works, you can rely on us for that too.",
    logo: trade2,
    sub: "Tailored. Flexible. Cost-effective.",
    subp: "Our accounting services are designed to flex and grow with your sole trader business. If your business finances become more complex (or simpler), you can adjust our support as necessary.",
    subpp:
      "That’s why we offer a range of additional accounting services to sole traders, such as bookkeeping, VAT returns, payroll or pensions, ready for you to add or remove as and when you need them.",
  },
  {
    title: "Get hibooks for free",
    description:
      "Imagine if you had more time for strategy. More headspace for marketing. More bandwidth for delighting your clients while still taking care of the day-to-day numbers. You can. Welcome to hibooks",
    logo: trade3,
    sub: "Welcome to hibooks.",
    subp: "hibooks is our hassle-free, online bookkeeping platform that’s built to eliminate manual data tasks and streamline your finances. It’s packed with the AI-driven features like smart invoicing, snap-n-go expenses, and automated real-time reporting that give you months’ of time and focus back.",
    subpp:
      "To protect you from the stress of an HMRC audit or inspection, we will act on your behalf and fight your corner with HMRC, at no additional cost.",
  },
  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Business Option Guide",
    description:
      "Discover the benefits of setting up as a sole trader – and check it’s the right business structure for you.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: sole,
  },
  {
    title: "Limited vs. Sole Trader: Explained",
    description:
      "Learn the pros and cons of both business structures including how tax and National Insurance differs.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: sole1,
  },
  {
    title: "Guide to Expenses",
    description:
      "Know how expenses affect your tax bill and get our ultimate list of expenses you can claim for.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: sole2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Reach out to us",
    description: "Simply pop your details into our online form here .",
    logo: ship,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Let’s talk",
    description:
      "We’ll call you for a no-obligation chat about the services you’d like. ",
    logo: ship1,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "We do the rest",
    description:
      "Once confirmed, we’ll set everything up for you - you don’t need to lift a finger!",
    logo: ship2,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd",
    description:
      "Skytax Accounting Ltd. has been an absolute asset to my property management company. Their expertise and meticulous approach to handling our finances have streamlined our bookkeeping process significantly. Their team's knowledge of the property industry is unmatched, providing tailored solutions that have truly made a difference.",
    logo: user,
  },
  {
    title: "Gordon Whitings ",
    subHead: " ",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  {
    title: "Philips",
    subHead: " ",
    description:
      "As an IT freelancer running my own limited company in the UK, finding an accounting practice that truly understands the complexities of my industry was crucial. I discovered Skytax and was immediately impressed by their tailored approach. Their expertise in handling the specific financial intricacies of IT contractors and managing expenses has been invaluable. They offer proactive advice, ensuring my company’s financial health remains robust while I focus on delivering top-notch services to my clients. Their dedication, industry knowledge, and personalized support make them an indispensable asset to any freelancer seeking reliable financial guidance.",
    logo: user,
  },
  // Add more data objects as needed
];

const SoleTrade = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Professional. Affordable. Reliable.</h6>
                      {/* <h1>
                        Comprehensive accountancy <br /> services for limited{" "}
                        <br />
                        companies
                      </h1> */}

                      <h1>
                        Professional online accounts services, built for how
                        sole traders work.
                      </h1>

                      <p>
                        Save time. Save money. Be empowered. We take care of
                        your accounts, while you take care of business.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        {/* <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                        >
                          Get started
                        </Button> */}
                        <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                          className={styles.actionButton}
                        >
                          Get started
                        </Button>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.WhoDoWe}>
          <Services
            data={data}
            heading="What you get"
            subHeading="A proactive and professional accounts and self-assessment tax service, with peace of mind always fitted as standard."
            imageSrc={imageSrc}
            text=" We’re as flexible as our sole trader clients.
             That’s why there’s no lengthy contracts. Just transparent pricing, 
             a simple pay-per-month  setup and, of course, you can. cancel anytime."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} height={427} />
        </div>
        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="Getting started"
            subHeading="Get Skytax comprehensive accounting services specially created for sole traders in just three simple steps."
          />
        </div>

        <div className={styles.HiBooks}>
          <IncludeSection dataInclude={dataInclude} />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Explore our resources for sole traders and startups"
            subHeading="We have a comprehensive range of free resources available to help your business run as smoothly."
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            heading="Great support for your business, fast."
            subheading="Get a quote for our Sole Trader service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="But you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="If you’re curious about how much it’ll cost, don’t wait, get a quote for our comprehensive Sole Trader services now. Simply complete and submit the form"
            id={undefined}
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default SoleTrade;
