import { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import styles from "./videos.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../components/admin/ConfirmationModal/ConfirmationModal";
import { RiEdit2Line } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import PaginationCard from "../../../components/admin/paginations";

function Videos() {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<any>(null);
  const LIMIT: number = 10;

  const [videoListApi, { response: apiResponse }] = useFetch(
    `/v1/video/list?start=${page}&&limit=${LIMIT}`,
    {
      method: "post",
    }
  );

  const allCategories = apiResponse ? apiResponse.data : [];

  useEffect(() => {
    setTotalRecords(allCategories?.total_records);
    // eslint-disable-next-line
  }, [apiResponse]);

  useEffect(() => {
    setPage(0);
  }, []);

  useEffect(() => {
    if (page !== null) {
      videoListApi();
    }
    // eslint-disable-next-line
  }, [page]);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [deleteID, setDeleteID] = useState<String>("");
  const navigate = useNavigate();

  const handleConfirmationToggle = () => {
    setConfirmDelete(!confirmDelete);
  };

  const handleDeleteUser = (id: any) => {
    setDeleteID(id);
    handleConfirmationToggle();
  };

  const [videoDeleteApi] = useFetch(`/v1/video/delete/${deleteID}`, {
    method: "delete",
  });

  const handleConfirmDelete = async (id: any) => {
    setDeleteID(id);

    try {
      const deleteResponse = await videoDeleteApi();

      // Check if the HTTP status code indicates success (e.g., 200-299)
      if (deleteResponse.status >= 200 && deleteResponse.status < 300) {
        // Refresh the category list after successful deletion
        videoListApi();
        console.log("Video deleted successfully!");
      } else {
        console.error("Error deleting video:", deleteResponse.statusText);
      }
    } catch (error: any) {
      // Explicitly define the type of 'error' as 'any'
      console.error("Error deleting video:", error.message);
    } finally {
      setDeleteID("");
      handleConfirmationToggle();
    }
  };

  return (
    <DashboardLayout>
      <div className={`${styles.users_page} ${styles.blog_boxs}`}>
        <div className={styles.users_addBtn}>
          <h2>Videos List</h2>
          <Link to="/admin/videos/add">
            {" "}
            <button className="addBlogs btn btn-primary">+ Add Video</button>
          </Link>
        </div>
        <div className="theme_tabs">
          <div>
            <div className="theme_table">
              <Table>
                <thead>
                  <tr>
                    <th>SR.No</th>
                    <th>Title</th>
                    <th>Youtube Link</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allCategories?.list ? (
                    allCategories?.list.length ? (
                      allCategories?.list.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>{index + 1 + page * LIMIT}</td>
                          <td>{item?.title}</td>
                          <td>{item?.youtube_link}</td>
                          <td className={styles.action_column}>
                            <div className={styles.action_btn}>
                              <span
                                onClick={() => {
                                  navigate(`/admin/videos/edit/${item?.id}`, {
                                    state: { item },
                                  });
                                }}
                              >
                                <RiEdit2Line />
                              </span>
                              <span
                                onClick={() => {
                                  handleDeleteUser(item?.id);
                                }}
                              >
                                <MdDeleteOutline />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={100} className="text-center py-5">
                          <h4>Data not Found</h4>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={100} className="text-center py-5">
                        <h4>
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </>
                        </h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <ConfirmationModal
                show={confirmDelete}
                title="Are you sure you want to delete this video ?"
                handleToggle={handleConfirmationToggle}
                confirm={() => {
                  handleConfirmDelete(deleteID);
                }}
                size="md"
              />
            </div>

            {totalRecords > LIMIT && (
              <div className="d-flex justify-content-end mt-3">
                <PaginationCard
                  itemPerPage={LIMIT}
                  totalItems={totalRecords}
                  paginate={setPage}
                  currentPage={page ?? 0}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
export default Videos;
