import caseStudies1 from "../../../../../assests/caseStudies1.svg";
import caseStudies2 from "../../../../../assests/caseStudies2.svg";
import caseStudies3 from "../../../../../assests/caseStudies3.svg";
import CaseStudy from "../caseStudiesContainer";
import { Col, Container, Row } from "react-bootstrap";
import caseStudyHeader from "../../../../../assests/caseStudyHeader.png";
import styles from "./mainCaseStudy.module.scss";
import CustomBtn from "../../../../../common/theme/button";
import caseStudyFeature1 from "../../../../../assests/caseStudyFeature1.svg";
import caseStudyFeature2 from "../../../../../assests/caseStudyFeature2.svg";
import caseStudyFeature3 from "../../../../../assests/caseStudyFeature3.svg";
import CaseStudiesFeature from "../caseStudiesFeature";
import lineBluelIght from "../../../../../assests/lineBluelIght.svg";
import lineGrays from "../../../../../assests/lineGray.svg";
import circleLight from "../../../../../assests/circleLight.svg";
import whiteCircle from "../../../../../assests/whiteCrcle.svg";
import circleBlue from "../../../../../assests/circleBlue.svg";
import lineWhite from "../../../../../assests/lineWhite.svg";
import AboutUsFooter from "../../../../../components/webapp/footer/aboutUsFooter";
import Header from "../../../../../components/webapp/header";
import GreenDotAndLine from "../../../../../assests/lineAndDot.svg";
import { useEffect } from "react";

const MainCaseStudies = () => {
  const data = [
    {
      title: "Service",

      description:
        "Our business is built on our reputation for excellent service. We offer tailored support to each and every one of our clients.",
      buttonText: "",
      logo: caseStudies1,
    },
    {
      title: "Expertise",

      description:
        "We’re committed to using our expertise and developing new services and tools to make your business simpler and your growth faster.",
      buttonText: "",
      logo: caseStudies2,
    },
    {
      title: "Integrity",

      description:
        "We don’t ask for your trust. We earn it. Our clients’ trust is our most valuable asset and that’s why we work with honesty, reliability and transparency at all times.",
      buttonText: "",
      logo: caseStudies3,
    },
  ];

  const caseStudyData = [
    {
      shortTitle: "",
      title: "Our approach",
      description1: `We believe in an individual, proactive approach to your accounting needs. We’re hands-on with your numbers, so you don’t have to be.`,
      description2: "",
      buttonText: "View Case Study",
      logoImg: caseStudyFeature1,
    },
    {
      shortTitle: "",
      title: "What to expect",
      description1:
        "You’ll get hassle-free accounting and tax services  as standard – with helpful, time-saving add-ons if you need them. And our hibooks bookkeeping software included free of charge.",
      description2: "",
      buttonText: "View Case Study",
      logoImg: caseStudyFeature2,
      isReverse: true,
    },
    {
      shortTitle: "",
      title: "Our specialists",
      description1:
        "Reach out to your highly experienced Skytax accountant whenever you need them – they’re only a phone call or email away.",
      description2: "",
      buttonText: "View Case Study",
      logoImg: caseStudyFeature3,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className={styles.caseStudy}>
        <Container>
          <div className={styles.mainPage}>
            <Row className="align-items-center">
              <Col className="align-items-center col-md-5" xs={12}>
                <div className={styles.leftPage}>
                  <div className={styles.linegrayssTop}>
                    <img src={lineBluelIght} alt="" />
                  </div>
                  <div className={styles.provide}>
                    <h6>Our mission</h6>
                    <h1>
                      Our mission is to give you the accounting expertise and
                      proactive services that empower you to achieve your
                      business ambitions.
                    </h1>
                    <p>
                      We’re on your side. We’re part of your team. And that’s
                      why we work hard to give you the tools and service to make
                      sure you succeed.
                    </p>
                    {/* <div className={styles.buttons}>
                      <CustomBtn>Call to action</CustomBtn>
                      <CustomBtn className={styles.actionButton}>
                        Call to action
                      </CustomBtn>
                    </div> */}
                  </div>
                  <div className={styles.linegrayss}>
                    <img src={lineGrays} alt="" />
                  </div>
                </div>
                <div className={`${styles.circlelight} ${styles.grow}`}>
                  <img src={circleLight} alt="" />
                </div>
                <div
                  className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                >
                  <img src={whiteCircle} alt="" />
                </div>
              </Col>
              <Col className=" col-md-7" xs={12}>
                <div className={styles.rightPage}>
                  <img src={caseStudyHeader} alt="" />
                </div>
                <div className={styles.greenDotAndLine}>
                  <img src={GreenDotAndLine} alt="" />
                </div>
                <div className={styles.circleblue}>
                  <img src={circleBlue} alt="" />
                </div>
              </Col>
            </Row>
            <div className={styles.lineWhite}>
              <img src={lineWhite} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div>
        <CaseStudy
          data={data}
          heading="Our values"
          subHeading=""
          // subHeading="See what our clients have to say about us."
        />
      </div>
      <div className={styles.caseStudies}>
        <CaseStudiesFeature caseStudyData={caseStudyData} />
      </div>
      <AboutUsFooter />
    </>
  );
};

export default MainCaseStudies;
