import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
  },
  reducers: {
    setUserData(state: any, action: any) {
      state.data = action.payload;
    },
  },
});
export const { setUserData } = commonSlice.actions;
export default commonSlice.reducer;
