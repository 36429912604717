import loginImg from "../../../assests/admin/login1.png";
import { Col, Container } from "react-bootstrap";
import styles from "../../../styles/layouts/AuthLayout.module.scss";
interface IProps {
  children: React.ReactNode;
}
const AuthLayout = ({ children }: IProps) => {
  return (
    <div className={styles.layout}>
      <Container>
        <div className={styles.layout_wrap}>
          <Col lg={6}>
            <div className={styles.layout_left}>
              <img src={loginImg} alt="login img" />
            </div>
          </Col>
          <div
            className="d-lg-block d-none"
            style={{
              boxShadow: "inset 0px 0px 6px -2px rgb(0 0 0 / 30%)",
              minWidth: 10,
            }}
          ></div>
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className={styles.layout_right}>{children}</div>
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default AuthLayout;
