import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";
import CustomBtn from "../../../../common/theme/button";
import styles from "./guidesAndVideos.module.scss";
import placeholderIcon from "../../../../assests/placeholderIcon.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideosIcon from "../../../../assests/VideosIcon.svg";
import guidesIcon from "../../../../assests/guidesIcon.svg";
import whiteCircle from "../../../../assests/whiteCrcle.svg";
import greenLine from "../../../../assests/greenLine.svg";
import circleLight from "../../../../assests/circleLight.svg";
import circleBlue from "../../../../assests/circleBlue.svg";
import lightBlueLine from "../../../../assests/lightBlueLine.svg";
import lineAndDot from "../../../../assests/lineAndDot.svg";
import GrayLine from "../../../../assests/GrayLine.svg";
import Header from "../../../../components/webapp/header";
import AboutUsFooter from "../../../../components/webapp/footer/aboutUsFooter";
import useFetch from "../../../../hooks/useFetch";
import { BASE_IMAGE_URL } from "../../../../services/constants";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";

const GuidesAndVideos = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<any>(null);
  const [filterId, setFilterId] = useState<any>([]);
  const LIMIT: number = 10;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store search term
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // State to store debounced search term
  const [loadingVideoList, setLoadingVideoList] = useState(false);
  const [loadingGuideList, setLoadingGuideList] = useState(false);

  const [videoListApi, { loading: loadingVideo, response: apiVideoResponse }] =
    useFetch(`/v1/public-video/list`, {
      method: "post",
    });

  const allVideos = apiVideoResponse ? apiVideoResponse?.data?.list : [];

  useEffect(() => {
    setTotalRecords(apiVideoResponse?.data?.total_records);
    // eslint-disable-next-line
  }, [apiVideoResponse]);

  useEffect(() => {
    setPage(0);
  }, []);

  const [guideListApi, { loading: loadingGuide, response: apiResponse }] =
    useFetch(`/v1/public-guide/list`, {
      method: "post",
    });

  const allBlogs = apiResponse ? apiResponse?.data?.list : [];

  useEffect(() => {
    setTotalRecords(apiResponse?.data?.total_records);
    // eslint-disable-next-line
  }, [apiResponse]);

  useEffect(() => {
    setPage(0);
  }, []);

  useEffect(() => {
    setLoadingVideoList(loadingVideo);
  }, [loadingVideo]);

  useEffect(() => {
    setLoadingGuideList(loadingGuide);
  }, [loadingGuide]);

  // Function to handle debouncing
  const debounce = (fn, delay) => {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  // Debounced function for API call
  const debouncedVideoListApi = debounce(
    (searchTerm) => {
      videoListApi({
        start: page,
        limit: LIMIT,
        search: searchTerm,
      });
    },
    1300 // Adjust the delay as needed
  );

  const debouncedGuideListApi = debounce(
    (searchTerm) => {
      guideListApi({
        start: page,
        limit: LIMIT,
        search: searchTerm,
      });
    },
    1300 // Adjust the delay as needed
  );

  // Effect to handle debounced API calls when the search term changes
  useEffect(() => {
    if (activeTab === "All" || activeTab === "Video") {
      debouncedVideoListApi(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, activeTab]);

  useEffect(() => {
    if (activeTab === "All" || activeTab === "Guide") {
      debouncedGuideListApi(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, activeTab]);

  // Effect to update debounced search term when the search term changes
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1300); // Adjust the delay as needed
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openModal = (video) => {
    setSelectedVideo(video);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setModalIsOpen(false);
  };

  const settingsvideo = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: allVideos.length < 4 ? allVideos.length : 4, // Display only 3 slides at a time
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024, // Adjust for screens wider than 1024px
        settings: {
          slidesToShow: 3, // Display 3 slides at a time on screens wider than 1024px
          slidesToScroll: 2, // Scroll 2 slides at a time on screens wider than 1024px
          dots: true,
        },
      },
      {
        breakpoint: 768, // Adjust for screens wider than 768px
        settings: {
          slidesToShow: 1, // Display 2 slides at a time on screens wider than 768px
          slidesToScroll: 1, // Scroll 1 slide at a time on screens wider than 768px
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Adjust for screens wider than 480px
        settings: {
          slidesToShow: 1, // Display 1 slide at a time on screens wider than 480px
          slidesToScroll: 1, // Scroll 1 slide at a time on screens wider than 480px
          dots: true,
        },
      },
    ],
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: allBlogs.length < 4 ? allBlogs.length : 4, // Display only 3 slides at a time
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024, // Adjust for screens wider than 1024px
        settings: {
          slidesToShow: 3, // Display 3 slides at a time on screens wider than 1024px
          slidesToScroll: 2, // Scroll 2 slides at a time on screens wider than 1024px
          dots: true,
        },
      },
      {
        breakpoint: 768, // Adjust for screens wider than 768px
        settings: {
          slidesToShow: 1, // Display 2 slides at a time on screens wider than 768px
          slidesToScroll: 1, // Scroll 1 slide at a time on screens wider than 768px
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Adjust for screens wider than 480px
        settings: {
          slidesToShow: 1, // Display 1 slide at a time on screens wider than 480px
          slidesToScroll: 1, // Scroll 1 slide at a time on screens wider than 480px
          dots: true,
        },
      },
    ],
  };

  // const inputRef = useRef<any>(null);
  return (
    <>
      <Header />
      <Container>
        <div className={styles.guidesAndVideosPage}>
          <Row className="align-items-center">
            <Col xs={12}>
              <div className={styles.greenLine}>
                <img src={greenLine} alt="" />
              </div>
              <div className={styles.lineAndDot}>
                <img src={lineAndDot} alt="" />
              </div>
              <div className={styles.check}>
                <span>Your questions answered</span>
                <h1>Expert Guides</h1>
                <p>
                  Have a question? Dig into our toolbox of how-to guides and
                  informative videos.
                </p>

                <div className={styles.input_wrapper}>
                  <input
                    // ref={inputRef}
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const inputElement = e.target as HTMLInputElement;
                        inputElement.blur(); // This will remove focus from the input field only when the Enter key is pressed
                      }
                    }}
                  />
                  <img src={placeholderIcon} alt="" />
                </div>

                {/* <div>
                    <img src={lightBlueLine} alt="" />
                  </div> */}
                <div className={`${styles.buttons} hoverBtn`}>
                  <Link to="/about-us/contact-us">
                    {" "}
                    <CustomBtn>Get in touch</CustomBtn>
                  </Link>
                  <Link to="/about-us/contact-us">
                    {" "}
                    <CustomBtn variant="outline-primary">
                      Explore hibooks
                    </CustomBtn>
                  </Link>
                </div>
              </div>

              <div className={`${styles.circlelight} ${styles.grow}`}>
                <img src={circleLight} alt="" />
              </div>
              <div
                className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
              >
                <img src={whiteCircle} alt="" />
              </div>
              <div className={styles.circleblue}>
                <img src={circleBlue} alt="" />
              </div>
              <div className={styles.lightBlueLine}>
                <img src={lightBlueLine} alt="" />
              </div>
            </Col>
          </Row>
          <div className={styles.GrayLine}>
            <img src={GrayLine} alt="" />
          </div>
        </div>
      </Container>
      <div
        className={
          allVideos.length > 0 ? styles.whyChooseAccount : styles.newadd
        }
      >
        <h2>Why choose us to produce your accounts?</h2>
        <p>
          We'll complete comprehensive financial accounts for your business.
        </p>
        <Container>
          <div className={styles.accountButtons}>
            <Row>
              <Col xs={12}>
                <div className={`${styles.buttonGap} hoverBtn`}>
                  <CustomBtn
                    variant={
                      activeTab === "All" ? "bg-primary" : "outColor-primary"
                    }
                    onClick={() => setActiveTab("All")}
                  >
                    Show all resource types
                  </CustomBtn>
                  <CustomBtn
                    variant={
                      activeTab === "Video" ? "bg-primary" : "outColor-primary"
                    }
                    disabled
                    onClick={() => setActiveTab("Video")}
                  >
                    Videos
                  </CustomBtn>
                  <CustomBtn
                    variant={
                      activeTab === "Guide" ? "bg-primary" : "outColor-primary"
                    }
                    onClick={() => setActiveTab("Guide")}
                  >
                    Guides
                  </CustomBtn>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={12}>
                <div className={styles.buttonGap}>
                  <CustomBtn variant="bg-primary">
                    Show all business types
                  </CustomBtn>
                  <CustomBtn variant="outColor-primary">Contractor</CustomBtn>
                  <CustomBtn variant="outColor-primary">
                    Limited Company
                  </CustomBtn>
                  <CustomBtn variant="outColor-primary">LLP</CustomBtn>
                  <CustomBtn variant="outColor-primary">Patnership</CustomBtn>
                  <CustomBtn variant="outColor-primary">Sole Trader</CustomBtn>
                </div>
              </Col>
            </Row> */}
          </div>
        </Container>
      </div>

      {/* {activeTab === "All" || activeTab === "Video" ? (
        <>
          {allVideos.length > 0 && (
            <Container>
              <div className={styles.videoContainer}>
                <Row>
                  <div className={styles.slickSlider}>
                    <h2>Videos</h2>
                    <p>
                      Wondering how outsourced payroll works, or confused about
                      self-assessment? Whether you’re new to business or looking
                      to develop your accounting and finance skills, our curated
                      collection of short, jargon-free video tutorials will give
                      you everything you need to level up.
                    </p>
                  </div>
                  <div className={styles.cardAndIcon}>
                    <div
                      className={`sliderCustom videoSlider ${styles.slickSliderMAin}`}
                    >
                      {loadingVideoList && (
                        <p className={styles.centerLoader}>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </p>
                      )}
                      {!loadingVideoList && (
                        <Slider {...settingsvideo}>
                          {allVideos.map((item, index) => (
                            <div key={index} className={styles.chosseBox}>
                              <div className={styles.chosseBoxImg}>
                                <img src={VideosIcon} alt="" />
                              </div>
                              <div className={styles.chooseText}>
                                <h6>{item?.title}</h6>
                                {/* <p>{item.text.description}</p> */}
      {/* </div>
                              <div className={`${styles.buttoncheck} hoverBtn`}>
                                <button
                                  type="button"
                                  className={styles.playButton}
                                  onClick={() => {
                                    openModal(item);
                                  }}
                                >
                                  Play
                                </button>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      )}
                    </div>
                  </div>
                </Row>
              </div>

              <Modal
                show={modalIsOpen}
                onHide={closeModal}
                aria-label="Videos"
                size="lg"
                className="modalVideos"
              >
                <Modal.Body>
                  <button className={styles.closeIcon} onClick={closeModal}>
                    <MdClose />
                  </button>
                  {selectedVideo && (
                    <ReactPlayer
                      url={selectedVideo?.youtube_link} // Provide the video URL here
                      width="100%"
                      height="100%"
                      controls // Display video controls (play, pause, etc.)
                      playing={true}
                    />
                  )}
                </Modal.Body>
              </Modal>
            </Container>
          )}
        </>
      ) : null} */}

      {activeTab === "All" || activeTab === "Guide" ? (
        <>
          {allBlogs.length > 0 && (
            <Container>
              <div className={styles.videoContainer}>
                <Row>
                  <div className={styles.slickSlider}>
                    <h2>Business guides</h2>
                    <p>
                      It’s not always easy to navigate the nuances of business
                      finances, and that’s why we’ve created a suite of how-to
                      guides to get you up and running fast.
                    </p>
                  </div>
                  <div
                    className={`sliderCustom videoSlider ${styles.slickSliderMAin}`}
                  >
                    {loadingGuideList && (
                      <p className={styles.centerLoader}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </p>
                    )}
                    {!loadingGuideList && (
                      <Slider {...settings}>
                        {allBlogs.map((item, index) => (
                          <div key={index} className={styles.chosseBox}>
                            <div className={styles.chosseBoxImg}>
                              <img src={guidesIcon} alt="" />
                            </div>
                            <div className={styles.chooseText}>
                              <h6>{item.title}</h6>
                              {/* <p>{item.text.description}</p> */}
                            </div>
                            <div className={`${styles.buttoncheck} hoverBtn`}>
                              <a
                                href={`${BASE_IMAGE_URL}${item?.pdf}`}
                                target="_blank"
                              >
                                {" "}
                                <CustomBtn>Download</CustomBtn>
                              </a>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    )}
                  </div>
                </Row>
              </div>
            </Container>
          )}
        </>
      ) : null}
      {allVideos.length === 0 && allBlogs.length === 0 && (
        <div className={styles.emtytext}>
          Can’t find what you need? Get in touch. We’re here to help.
        </div>
      )}

      <AboutUsFooter />
    </>
  );
};

export default GuidesAndVideos;
