/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef } from "react";
import styles from "./taxReturn.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/services/taxReturn.svg";
import companies from "../../../../assests/homePage/box4.svg";
import companies1 from "../../../../assests/homePage/box3.svg";
import companies2 from "../../../../assests/homePage/box2.svg";
import get1 from "../../../../assests/homePage/service4.svg";
import get2 from "../../../../assests/homePage/newframe.png";
import get3 from "../../../../assests/homePage/frame.png";
import get4 from "../../../../assests/homePage/cis4.svg";

import box from "../../../../assests/homePage/ship2.svg";
import box1 from "../../../../assests/homePage/companies1.svg";
import box2 from "../../../../assests/homePage/dollarImg.svg";
import ship from "../../../../assests/homePage/book.svg";
import ship1 from "../../../../assests/homePage/ship.svg";
import ship2 from "../../../../assests/homePage/companies.svg";
import user from "../../../../assests/homePage/user.svg";
import whiteLineGray from "../../../../assests/services/whiteLineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import box3 from "../../../../assests/homePage/bags.svg";
import box4 from "../../../../assests/homePage/ship5.svg";
import box5 from "../../../../assests/homePage/sole.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import WhoDoWe from "../../../../components/webapp/whoDoWe";
import HiBooks from "../../../../components/webapp/hiBooks";
import Faqs from "../../../../components/webapp/faqs";
import { Link } from "react-router-dom";

const data1 = [
  {
    title: "Business tax planning",
    description: "Strategic tax planning to optimise your tax position.",
    logo: get1,
    lineBar: lineBar,
  },
  {
    title: "Stress-free filing",
    description: "Effortless tax filing. Always timely. Always accurate.",
    logo: get2,
    lineBar: lineBar,
  },
  {
    title: "We’re on your side ",
    description: "Expert support and representation to resolve HMRC queries.",
    logo: get3,
    lineBar: lineBar,
  },
  {
    title: "Tax advice and consultation",
    description: "We’ll help you make sense of complex business tax laws.",
    logo: get4,
    lineBar: lineBar,
  },

  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Accounts",
    description:
      "Our comprehensive and flexible range of professional accounts gives you the time and insight to manage your business even better.",
    buttonText: "View Details",
    onclick: <Link to="/services/accounts" />,
    logo: companies,
  },
  {
    title: "Bookkeeping",
    description:
      "We’ll handle the hard work of paperwork, leaving you free to work on your business",
    buttonText: "View Details",
    onclick: <Link to="/services/bookKeeping" />,
    logo: companies1,
  },
  {
    title: "Payroll",
    description: "Paying your people the right amount at the right time.",
    buttonText: "View Details",
    onclick: <Link to="/services/payroll" />,
    logo: companies2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Preparation",
    description:
      "We’ll give you a heads up when your tax return is due, and ask for your records.",
    logo: ship,
    blueLine: whiteLineGray,
    count: "1",
  },
  {
    title: "Completion",
    description:
      "Your dedicated Skytax  accountant will prepare your accounts punctually and professionally for your approval.",
    logo: ship1,
    blueLine: whiteLineGray,
    count: "2",
  },
  {
    title: "Submission",
    description:
      "Once approved, we’ll submit your accounts to HMRC your behalf",
    logo: ship2,
    blueLine: whiteLineGray,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd",
    description:
      "Skytax Accounting Ltd. has been an absolute asset to my property management company. Their expertise and meticulous approach to handling our finances have streamlined our bookkeeping process significantly. Their team's knowledge of the property industry is unmatched, providing tailored solutions that have truly made a difference.",
    logo: user,
  },
  {
    title: "Gordon Whitings ",
    subHead: " ",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  {
    title: "Philips",
    subHead: " ",
    description:
      "As an IT freelancer running my own limited company in the UK, finding an accounting practice that truly understands the complexities of my industry was crucial. I discovered Skytax and was immediately impressed by their tailored approach. Their expertise in handling the specific financial intricacies of IT contractors and managing expenses has been invaluable. They offer proactive advice, ensuring my company’s financial health remains robust while I focus on delivering top-notch services to my clients. Their dedication, industry knowledge, and personalized support make them an indispensable asset to any freelancer seeking reliable financial guidance.",
    logo: user,
  },
  // Add more data objects as needed
];
const dataBooks = [
  {
    title: "Peace of mind",
    description:
      "With us on your side, you don’t need to worry about deadlines, penalties or fines. Everything is done for you, at exactly the right time.",
    logo: box3,
  },
  {
    title: "Proactive, optimised tax",
    description:
      "We always use our expertise to unlock hidden savings and tax efficiencies for you. ",
    logo: box4,
  },
  {
    title: "Professionalism",
    description:
      "We pride ourselves on our total expertise and professionalism. We won’t ask for your trust. We’ll earn it. ",
    logo: box5,
  },
  // Add more data objects as needed
];

const data = [
  {
    title: "Sole Traders",
    price: "From £30 per month",
    description:
      "Budget-friendly, accurate and on-time tax returns for the ultimate peace of mind.",
    buttonText: "Get started",
    onclick: <a href="#tax" />,
    logo: box,
  },
  {
    title: "Limited Companies",
    price: "From £120 per month",
    description:
      "Pay what you owe and not a penny more. Optimised tax returns, every time.",
    buttonText: "Get started",
    onclick: <a href="#tax" />,
    logo: box1,
  },
  {
    title: "Partnerships",
    price: "From £50 per month",
    description:
      "Reliable, accurate and fast tax returns especially designed for partnerships.",
    buttonText: "Get started",
    onclick: <a href="#tax" />,
    logo: box2,
  },
  // Add more data objects as needed
];

const faqItems = [
  {
    header: "Will my Skytax accountant be qualified and experienced?",
    body: "Yes, of course! Your dedicated Skytax accountant will be fully qualified with an (x) or (y) accounting certification and have at least x years’ experience in tax services. Your accountant will always have a speciality in your particular type of business.",
    innerbody: "",
    lowbody: "",
  },
  {
    header: "My taxes are a mess. Can you help?",
    body: "Yes, of course. The messier, the better! We’ll get you ship-shape and regularise your tax position quickly. We’ll also communicate and work with HMRC on your behalf to straighten things out.",
    innerbody: "",
    lowbody: "",
  },
  {
    header: "HMRC stresses me out. Can you talk to them on my behalf?",
    body: "Yes. With your authority, we can act as your agent for HMRC, and work on your behalf to resolve issues.",
    innerbody: "",
    lowbody: "",
  },
  {
    header:
      "I’m fed up with the mad rush each year to submit the right tax at the right time. Can you help?",
    body: "That’s what we’re here for! Throughout the year we’ll make sure that your tax returns are on target, and we’ll prepare and submit them on time.  ",
    innerbody: "",
    lowbody: "",
  },
  {
    header: "Can I get hold of my Skytax accountant easily?",
    body: "Of course! We’re always at the end of a phone or email for you. And you never need to remind us to do anything. In fact, often it’s the other way round -you can rely on us to reach out to you regularly for information to help us prepare your returns.     ",
    innerbody: "We’re super-proactive, but we don’t nag! ",
    lowbody: "",
  },
  {
    header: "Is it safe to share my business data with you?",
    body: "Absolutely. We’re fully compliant with the GDPR and are registered with the ICO. We treat sensitive financial information with the utmost care. Find out more by checking out our Privacy Policy.",
    innerbody: "",
    lowbody: "",
  },
];

const TaxReturns = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const whoDoWeRef = useRef<HTMLDivElement>(null);

  const handleFindOutClick = () => {
    if (whoDoWeRef.current) {
      whoDoWeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Fast. Accurate. Professional.</h6>
                      <h1>Tax Returns</h1>
                      <p>
                        Say goodbye to tax headaches and hello to stress-free
                        tax returns, prepared for you by our reliable, qualified
                        tax experts.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        <Button onClick={handleFindOutClick}>
                          Find out more
                        </Button>
                        <Link to="/about-us/contact-us">
                          <Button className={styles.actionButton}>
                            Get started
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div ref={whoDoWeRef} className={styles.WhoDoWe}>
          <Services
            data={data1}
            heading="What you get"
            subHeading="Specialist tax services that take care of your returns and optimise your tax position."
            text={""}
            imageSrc={""}
            type="ServicesAccount" // imageSrc={imageSrc}
            // text=" We will never tie you in to a long-term contract. You are free
            // to leave whenever you want, we're just confident you
            // won't want to!"
          />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="How it works"
            subHeading="Forget fines. Forget penalties, Forget tax-induced headaches. We’ll manage your tax returns, from prep to submission and everything in between."
            type="accounts"
          />
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            type="account"
            heading="What it costs"
            subHeading="All our accounts packages include the preparation and submission of tax returns and formal accounts. Need more help? It’s easy to add on other services."
          />
        </div>

        <div className={styles.HiBooks}>
          <HiBooks
            dataBooks={dataBooks}
            heading="Why choose us?"
            subHeading="Financial expertise and reliability, with peace of mind fitted as standard."
          />
        </div>

        <div className={`${styles.HiBooks} ${styles.AccordionsSection}`}>
          <Faqs
            faqItems={faqItems}
            heading="Got a question about our tax return service?"
            subHeading=" We pride ourselves on our transparency and openness. If you can’t find an answer here, talk to us!"
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} type="reviweBg" />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Need other accountancy and business services? No problem. We’re here to help. "
            subHeading="Don’t limit yourself. Choose from a tried, tested and trusted range of expert services that make life easier and business better. "
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            id="tax"
            heading="Great support for your business, fast."
            subheading="Get a quote for our Tax Return Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our Tax Return  services now. Simply complete and submit the form."
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default TaxReturns;
