import React, { useCallback, useState } from 'react'
import styles from "./forgotpassword.module.scss"
import { Form, Formik } from 'formik';
import { Form as ReactForm } from "react-bootstrap";
import * as Yup from "yup";
import { RotatingLines } from "react-loader-spinner";
import AuthLayout from '../../../components/admin/layouts/AuthLayout';
import { Link, useNavigate } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { ToastContainer, toast } from 'react-toastify';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const ValidateSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Enter a valid email"),
  
  });



  const [forgotApi] = useFetch(
    `/v1/auth/forgot-password`,
    {
      method: "post",
    }
  );


  const handleSubmit = useCallback(async (values) => {
  
    try {
      setLoading(true);
      const response = await forgotApi({  ...values });
      // Handle the API response here
   
      setLoading(false);
      if (response.data && response.data.status === 1) {
        // Redirect to the admin dashboard on success
         // Save the response data in local storage
      navigate("/admin/login");
      }  else {
        // Handle API response errors here
        console.error("API Request Error:", response?.data?.message);
         // Check for specific error message for invalid password
         if (response.data && response.data.status === 0) {
          toast.error(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }

    } catch (error) {
      // Handle API request error
      console.error("API Request Error:", error);
      setLoading(false);
    }
     // eslint-disable-next-line
  }, [forgotApi]);


  return (
    <>
    <ToastContainer />
        <AuthLayout>
     <div className={styles.login}>
            <div className={styles.login_ByGoogle}>
              <div className={styles.formTitle}>
                {/* <img src={symbolImg} alt="logo" /> */}
                <h2> Forgot Password</h2>
                <p> Please enter email address linked with your account and we
                    will send a Reset password link to your email.</p>
              </div>
            </div>
            <div className={styles.formFields}>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={ValidateSchema}
                // onSubmit={(values) => {
                //   forgotCall
                // }}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form>
                    <ReactForm.Group
                      className={styles.formFieldSingle}
                      controlId="formBasicEmail"
                    >
                      <ReactForm.Label>Email</ReactForm.Label>
                      <ReactForm.Control
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <div className={styles.error}>{errors.email}</div>
                      )}
                    </ReactForm.Group>

                  
                 
              <button className="btn btn-primary" type="submit" disabled={loading}>

                
                      Send Email
                      {loading && (
                        <RotatingLines
                          strokeColor="white"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={true}
                        />
                      )}
                    </button>

                    <Link to="/admin/login"> <h6 className="text-center pt-3" >
                      Back To Login
                      
                    </h6>
                    </Link>
                  </Form>
                )}
              </Formik>
{/* 
              <div className={styles.createRegister}>
                <span>Not Registered Yet? Contact to:-</span>
                <span className="theme_txtLink"> +91 123,464,9128</span>
              </div> */}
            </div>
          </div>
          </AuthLayout>
    </>
  )
}

export default ForgotPassword