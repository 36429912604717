import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import styles from "../changePassword/changeForm.module.scss";
import "react-quill/dist/quill.snow.css"; // Import styles
import { useCallback, useState } from "react";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";

function ChangePassword() {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    old_password: yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Password is required"),
      new_password: yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Password is required"),
      confirm: yup.string()
    .required('Confirm New Password is required')
    .oneOf([yup.ref('new_password'), ''], 'Passwords must match'),
  });



  const [changeApi] = useFetch(
    `/v1/auth/change-password`,
    {
      method: "post",
    }
  );


  const handleSubmit = useCallback(async (values) => {
    
    let confirmValues = {
      old_password: values.old_password,
      new_password: values.new_password,
    };
    try {
      setLoading(true);
      const response = await changeApi({  ...confirmValues });
      // Handle the API response here
     
      setLoading(false);
      if (response.data && response.data.status === 1) {
        // Redirect to the admin dashboard on success
         // Save the response data in local storage
      navigate("/admin/dashboard");
      }  else {
        // Handle API response errors here
        console.error("API Request Error:", response?.data?.message);
      }

    } catch (error) {
      // Handle API request error
      console.error("API Request Error:", error);
      setLoading(false);
    }
     // eslint-disable-next-line
  }, [changeApi]);




  return (
    <DashboardLayout>
      <div className={styles.users_page}>
      <div className={styles.blogBox}>

      <Formik
        validationSchema={schema}
       
        onSubmit={handleSubmit}
        
        initialValues={{
          old_password: "",
          new_password: "",
          confirm:"",
        }}

    
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <>
         

            <Form noValidate onSubmit={handleSubmit}>
              <div className={styles.formStep}>
                <h4>Change Password</h4>

                <div className={styles.twoFieldsWrap}>
                  <Form.Group className={styles.singleField}>
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter name"
                      name="old_password"
                      value={values.old_password}
                      onChange={handleChange}
                      isInvalid={!!errors.old_password && touched.old_password ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors?.old_password === "string" ? errors?.old_password : ""}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={styles.singleField}>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter name"
                      name="new_password"
                      value={values.new_password}
                      onChange={handleChange}
                      isInvalid={!!errors.new_password && touched.new_password ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors?.new_password === "string" ? errors?.new_password : ""}
                    </Form.Control.Feedback>
                  </Form.Group>


                  <Form.Group className={styles.singleField}>
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter name"
                      name="confirm"
                      value={values.confirm}
                      onChange={handleChange}
                      isInvalid={!!errors.confirm && touched.confirm ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors?.confirm === "string" ? errors?.confirm : ""}
                    </Form.Control.Feedback>
                  </Form.Group>


                  <div className={styles.singleField}>
                  <button
                className={`mt-2 mb-0 btn btn-primary ${styles.subBtn}`}
                type="submit"
              >
                Save
              </button>
              </div>
                </div>
            
              </div>

           
            </Form>
          </>
        )}
      </Formik>
    </div>
      </div>
    </DashboardLayout>
  );
}
export default ChangePassword;
