import React from "react";
import styles from "./hotPress.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { BASE_IMAGE_URL } from "../../../services/constants";

interface DataItem {
  title: string;
  description: string;
  date: string;
  logo: string; // Assuming logo is a URL
}

interface PressProps {
  dataPress: DataItem[]; // Use the defined interface for the data prop
  heading?: string;
  subHeading?: string;
  categoryId?: any;
  setCategoryId?: (val: number) => void;
  // setTotalRecords?: any;
  // totalRecords?: any;
  allBlogs?: any;
}
const HotPress: React.FC<PressProps> = ({
  dataPress,
  heading,
  subHeading,
  categoryId,
  allBlogs,
  setCategoryId,
}) => {
  const sanitizeHTML = (html, maxLines = 2) => {
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Remove images
    doc.querySelectorAll("img").forEach((img) => img.remove());

    // Remove tables
    doc.querySelectorAll("table").forEach((table) => table.remove());

    // Get text content and limit to maxLines
    const textContent = doc.body.textContent || "";
    const lines = textContent.split("\n").slice(0, maxLines);
    const truncatedHTML = lines.join("<br>");

    return truncatedHTML;
  };

  return (
    <div className={`${styles.whoWe} whoWe`}>
      <Container>
        <Row className="align-items-center">
          {heading || subHeading ? (
            <Col className="col-md-12 mb-4" xs={12}>
              <div className={`text-center heading ${styles.heading}`}>
                {heading ? <h2>{heading}</h2> : <></>}
                {subHeading ? <p>{subHeading}</p> : <></>}
              </div>
            </Col>
          ) : (
            <></>
          )}

          {allBlogs?.map((item, index) => (
            <Col key={index} className="col-md-3" xs={12}>
              <Link
                to={`/resources/blog-article/${item.id}`}
                state={{ categoryId: categoryId }}
              >
                <div className={`text-center ${styles.box}`}>
                  <div className={styles.boxImg}>
                    <img
                      // src={item.image}
                      src={`${BASE_IMAGE_URL}${item.image}`}
                      alt={`Logo ${index}`}
                    />
                  </div>
                  <div className={styles.boxText}>
                    <h6>
                      {/* {item.createdAt} */}
                      {moment(item?.createdAt).format("MMMM D, YYYY")}
                    </h6>

                    <h2>{item.title}</h2>
                    {/* <p>{item.description}</p> */}
                    {/* <p
                      className="disblogs"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    /> */}
                    <p
                      className="disblogs"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(item?.description),
                      }}
                    />
                    <Button>
                      Read more <span>→</span>
                    </Button>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default HotPress;
