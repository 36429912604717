import React, { useEffect } from "react";
import styles from "./whowehelp.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import companieBanner from "../../../../assests/homePage/companiesbannernew.png";
import companies from "../../../../assests/homePage/companies.svg";
import companies1 from "../../../../assests/homePage/companies1.svg";
import companies2 from "../../../../assests/homePage/companies2.svg";
import box from "../../../../assests/homePage/new1.svg";
import box1 from "../../../../assests/homePage/new2.svg";
import box2 from "../../../../assests/homePage/new3.svg";
import service4 from "../../../../assests/homePage/service4.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import service5 from "../../../../assests/homePage/service5.svg";
import service6 from "../../../../assests/homePage/service6.svg";
import ship2 from "../../../../assests/homePage/ship2.svg";
import ship4 from "../../../../assests/homePage/ship4.svg";
import ship5 from "../../../../assests/homePage/ship5.svg";
import user from "../../../../assests/homePage/user.svg";
import blueLine from "../../../../assests/homePage/lineBlue.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import services1 from "../../../../assests/homePage/services1.png";
import services2 from "../../../../assests/homePage/services2.png";
import services3 from "../../../../assests/homePage/services3.png";
import imageSrc from "../../../../assests/homePage/dollar.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import IncludeSection from "../../../../components/webapp/includeSection";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import { Link, useNavigate } from "react-router-dom";

const data = [
  {
    title: "Full-service accounts",
    description:
      "Statutory accounts prepared and submitted. Management accounts and a full suite of reports for the business insight you need.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Free online bookkeeping software",
    description:
      "Free access to hibooks means you can ditch manual data entry for simple snap’n’go online bookkeeping - and much more!",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Expert tax planning",
    description:
      "Why pay more? Our tax experts will optimise your tax position to minimise your liabilities and maximise cash flow. Includes directors’ self-assessment return, too!",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "Corporation tax",
    description:
      "No more corporation tax crises. Just fast, reliable and accurate corporation tax prep and submission.",
    logo: service4,
    lineBar: lineBar,
  },
  {
    title: "VAT Return",
    description:
      "Your VAT Returns submitted on time, always. We’re fully HMRC-approved and Making Tax Digital compliant. ",
    logo: service5,
    lineBar: lineBar,
  },
  {
    title: "Dedicated accountant",
    description:
      "We’ll never give you the run around - just consistent, expert advice and support from your own Skytax accountant.",
    logo: service6,
    lineBar: lineBar,
  },
  // Add more data objects as needed
];

const dataInclude = [
  {
    title: "Why pay more?",
    description:
      "Whether you’re new to business or an old hand, you know it’s important not to throw money away. That’s why, unlike some accounting firms,  we never push services you don’t want or need.",
    logo: services1,
    sub: "Cost effective. Flexible. Agile.",
    subp: "We’re trusted to deliver support flexibly, just where you need it. Our costing is simple, and transparent, and you’re never tied into a contract.",
  },
  {
    title: "Wave bye-bye to penalties. Say hello to peace of mind.",
    description:
      "When you’re busy running a business, it’s too easy to forget an important detail - like a financial deadline. With us, you never will. ",
    logo: services2,
    sub: "Compliant. Proactive. Finances done right.",
    subp: "We’ll always make sure your returns and accounts are submitted accurately and on time. We’ll take care of your peace of mind, while you take care of business.",
  },
  {
    title: "Get hibooks for free",
    description:
      "Imagine if you had more time for strategy. More headspace for marketing. More bandwidth for delighting your clients while still taking care of the day-to-day numbers. You can. Welcome to hibooks.",
    logo: services3,
    sub: "Welcome to hibooks.",
    subp: "Hibooks Pro is our hassle-free online bookkeeping platform that’s built to eliminate manual data tasks and streamline your finances. It’s packed with AI-driven features like smart invoicing, snap-n-go expenses, and automated real-time reporting that give you months’ of time and focus back. ",
  },
  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Business Options: A Guide",
    description:
      "Not sure which business structure is right for you? Find out here.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies,
  },
  {
    title: "Limited Companies: A Guide",
    description:
      "Stay updated with all the limited company financials that you need to know.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies1,
  },
  {
    title: "Director’s Loans: A Guide",
    description:
      "Director’s loans can be costly but our guide will help you navigate the complexities easily.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Reach out to us",
    description: "Simply pop your details into our online form here.",
    logo: ship4,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Let’s talk",
    description:
      "We’ll call you for a no-obligation chat about the services you’d like. ",
    logo: ship2,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "We do the rest",
    description:
      "Once confirmed, we’ll set everything up for you - you don’t need to lift a finger!",
    logo: ship5,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Dr J Afridi ",
    subHead: "   ",
    description:
      "Skytax Accounting has been a game-changer for my medical practice. Their tailored approach and expertise in healthcare finances have simplified my workload. I highly recommend them to fellow doctors and healthcare professionals— their dedication and support have been invaluable.",
    logo: user,
  },
  {
    title: "Mr. Farid  ",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  {
    title: "Matthew",
    subHead: "Saroia Staffing Services Ltd",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  // Add more data objects as needed
];

const WhoWeHelp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Comprehensive. Expert. Proactive.</h6>
                      <h1>
                        Professional online accounting services especially
                        designed for limited companies
                      </h1>
                      <p>
                        Whether you’re just getting started or getting ahead,
                        our comprehensive and budget-friendly services will get
                        you where you need to go.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        {/* <Button>Call to action</Button> */}
                        <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                          className={styles.actionButton}
                        >
                          Get started
                        </Button>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={companieBanner} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.WhoDoWe}>
          <Services
            data={data}
            heading="What you get"
            subHeading="A proactive and professional accounts and tax service, with peace of mind always fitted as standard."
            imageSrc={imageSrc}
            textHead="HMRC representation"
            text=" We can directly liaise with HMRC to handle your tax affairs on your behalf – saving you time and reducing stress."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} height={375} />
        </div>
        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="Getting started"
            subHeading="Do your own accounts? Already have an accountant but want to make the jump? Relax - we make everything easy."
          />
        </div>

        <div className={styles.HiBooks}>
          <IncludeSection dataInclude={dataInclude} />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Free downloadable business guides"
            subHeading="Everything you need to get going and growing in business"
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            heading="Great support for your business, fast."
            subheading="Get a quote for our Limited Company service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="But you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree=" If you’re curious about how much it’ll cost, don’t wait, get a quote for our comprehensive limited company services now. Simply complete and submit the form."
            id={undefined}
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default WhoWeHelp;
