import React from "react";
import styles from "./faqs.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

interface faqItems {
  header: string;
  body: React.ReactNode;
  innerbody: string;
  lowbody: string;
}

interface PressProps {
  faqItems: faqItems[]; // Use the defined interface for the data prop
  heading: string;
  subHeading: string;
}
const Faqs: React.FC<PressProps> = ({ faqItems, heading, subHeading }) => {
  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-4" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
              <p>{subHeading}</p>
            </div>
          </Col>

          <div className={`${styles.accordions} `}>
            <Col md={12}>
              <Accordion
                defaultActiveKey="0"
                className={`${styles.acodion_main}  `}
              >
                {faqItems.map((item, index) => (
                  <Accordion.Item
                    eventKey={index.toString()}
                    key={index}
                    className={`${styles.acodion_items} hoverFaq `}
                  >
                    <Accordion.Header className={styles.customAccordionHeader}>
                      {item.header}
                      <span className={styles.plusMinusIcon}>+</span>
                    </Accordion.Header>
                    <Accordion.Body className={styles.acodion_body}>
                      <p>{item.body}</p>
                      <p>{item.innerbody}</p>
                      <p>{item.lowbody}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Faqs;
