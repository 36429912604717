import React, { useEffect } from "react";
import styles from "./patnerShip.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/homePage/partnership.png";
import companies from "../../../../assests/homePage/ship2.svg";
import companies1 from "../../../../assests/homePage/companies1.svg";
import companies2 from "../../../../assests/homePage/dollarImg.svg";
import box from "../../../../assests/homePage/Medium.svg";
import box1 from "../../../../assests/homePage/new.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import box2 from "../../../../assests/homePage/cis2.svg";
import box3 from "../../../../assests/homePage/cis4.svg";
import box4 from "../../../../assests/homePage/service4.svg";
import box5 from "../../../../assests/homePage/new3.svg";
import ship from "../../../../assests/homePage/book.svg";
import ship1 from "../../../../assests/homePage/ship.svg";
import ship2 from "../../../../assests/homePage/companies.svg";
import user from "../../../../assests/homePage/user.svg";
import blueLine from "../../../../assests/homePage/lineBlue.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import imageSrc from "../../../../assests/homePage/Large1.png";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import trade from "../../../../assests/homePage/soleTrade.png";
import trade2 from "../../../../assests/homePage/new1.png";
import trade3 from "../../../../assests/new.png";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import IncludeSection from "../../../../components/webapp/includeSection";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import { Link, useNavigate } from "react-router-dom";

const data = [
  {
    title: "Full-service accounts",
    description:
      "Statutory accounts prepared and submitted. Management accounts and a full suite of reports for the business insight you need.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Free online bookkeeping software",
    description:
      "Free access to hibooks means you can ditch manual data entry for simple snap’n’go online bookkeeping - and there’s much more!",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Expert tax planning and returns",
    description: "We’ll solve the complexities of your partnership tax returns",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "VAT Return",
    description:
      "Your VAT Returns submitted on time, always. We’re fully HMRC-approved and Making Tax Digital compliant",
    logo: box3,
    lineBar: lineBar,
  },
  {
    title: "Dedicated accountant",
    description:
      "We’ll never give you the run around - just consistent, expert advice and support from your own Skytax accountant",
    logo: box4,
    lineBar: lineBar,
  },
  {
    title: "HMRC representation",
    description:
      "We can directly liaise with HMRC to handle your tax affairs on your behalf – saving you time and reducing stress.",
    logo: box5,
    lineBar: lineBar,
  },
  // Add more data objects as needed
];

const dataInclude = [
  {
    title: "Switching accountants couldn’t be easier",
    description:
      "Moving your business accounts to Skytax can be done with minimal disruption and fuss.",
    logo: trade,
    sub: "Make the move.",
    subp: "We’ll ensure a hassle-free handover with your previous accountant or bookkeeper, leaving you to get on with running your partnership.",
    subpp:
      "As your new accountants we’ll take care of the entire changeover from your previous accountant or bookkeeper. We will start by contacting them to request all the information we need to correspond with HMRC and act on your behalf. Being a sole trader doesn’t mean you’re alone!",
  },
  {
    title: "Pick and choose the services you need",
    description:
      "Whether your focus is on tax planning and payroll, or you just need help preparing year end documents, we’ve got you covered.",
    logo: trade2,
    sub: "Bespoke partnership accounting.",
    subp: "We’re set up to handle your entire partnership accounting needs. But you’ll get the same top quality service if you only need help with one specific area too. ",
    subpp:
      "That’s why we offer a range of additional accounting services to sole traders, such as bookkeeping, VAT returns, payroll or pensions, ready for you to add or remove as and when you need them.",
  },
  {
    title: "Get hibooks for free",
    description:
      "Imagine if you had more time for strategy. More headspace for marketing. More bandwidth for delighting your clients while still taking care of the day-to-day numbers.  You can. Welcome to hibooks ",
    logo: trade3,
    sub: "Welcome to hibooks.",
    subp: "hibooks is our hassle-free, online bookkeeping platform that’s built to eliminate manual data tasks and streamline your finances. It’s packed with the AI-driven features like smart invoicing, snap-n-go expenses, and automated real-time reporting that give you months’ of time and focus back.",
    subpp:
      "To protect you from the stress of an HMRC audit or inspection, we will act on your behalf and fight your corner with HMRC, at no additional cost.",
  },
  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Business Option Guide",
    description:
      "Learn the pros and cons of setting up as a partnership or a limited liability partnership.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies,
  },
  {
    title: "Director’s Loan: Key Facts",
    description:
      "How do Director’s Loans work and what are the tax consequences. This guide explains.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies1,
  },
  {
    title: "Guide to Expenses",
    description:
      "Discover the allowable expenses for partnerships to minimise the amount of tax your partnership will pay.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Reach out to us",
    description: "Simply pop your details into our online form here .",
    logo: ship,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Let’s talk",
    description:
      "We’ll call you for a no-obligation chat about the services you’d like.",
    logo: ship1,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "We do the rest",
    description:
      "Once confirmed, we’ll set everything up for you - you don’t need to lift a finger!",
    logo: ship2,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Gordon Glenister",
    subHead: " ",
    description:
      "I have been working with Skytax for over 5 years and found it to be a very cost-effective solution to both my businesses. In addition being able to call up for specific queries has been super helpful. I am happy to recommend them to you.",
    logo: user,
  },
  {
    title: "Philips ",
    subHead: "",
    description:
      "As an IT freelancer running my own limited company in the UK, finding an accounting practice that truly understands the complexities of my industry was crucial. I discovered Skytax and was immediately impressed by their tailored approach. Their expertise in handling the specific financial intricacies of IT contractors and managing expenses has been invaluable. They offer proactive advice, ensuring my company’s financial health remains robust while I focus on delivering top-notch services to my clients. Their dedication, industry knowledge, and personalized support make them an indispensable asset to any freelancer seeking reliable financial guidance.",
    logo: user,
  },
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  // Add more data objects as needed
];

const PatnerShips = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Expert. Reliable. Proactive.</h6>
                      {/* <h1>
                        Comprehensive accountancy <br /> services for limited{" "}
                        <br />
                        companies
                      </h1> */}
                      <h1>
                        Simplify your finances with Skytax accounting services.
                        Built for partnerships.
                      </h1>
                      <p>
                        Partnerships are complex: tax, contributions, compliance
                        - and the rest. Relax - we’ll take care of it all.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        {/* <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                        >
                          Get started
                        </Button> */}
                        <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                          className={styles.actionButton}
                        >
                          Get started
                        </Button>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.WhoDoWe}>
          <Services
            data={data}
            heading="What you get"
            subHeading="A proactive and professional accounts and tax service, with peace of mind always fitted as standard."
            imageSrc={imageSrc}
            text=" We’re as flexible as our partnership clients. That’s why there’s no lengthy contracts. Just transparent pricing, a simple pay-per-month  setup and, of course, you can. cancel anytime."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} height={427} />
        </div>
        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="Getting started"
            subHeading="Get Skytax comprehensive accounting services specially created for partnerships in just three simple steps."
          />
        </div>

        <div className={styles.HiBooks}>
          <IncludeSection dataInclude={dataInclude} />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Explore our resources for partnerships"
            subHeading=""
            // subHeading="We have a comprehensive range of free resources available to help your business run as smoothly."
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            heading="Great support for your business, fast."
            subheading="Get a quote for our Partnerships  Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="But you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="If you’re curious about how much it’ll cost, don’t wait, get a quote for our comprehensive partnerships services now. Simply complete and submit the form. "
            id={undefined}
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default PatnerShips;
