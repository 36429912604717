import React from 'react';
import styles from './ConnectBank.module.scss';
import { Link } from 'react-router-dom';
import CustomBtn from '../../../common/theme/button';
import { Container } from 'react-bootstrap';
import connect1 from "../../../assests/homePage/connect1.png";
import connect2 from "../../../assests/homePage/connect2.svg";
import connect3 from "../../../assests/homePage/connect3.svg";
import connect4 from "../../../assests/homePage/connect4.svg";
import connect5 from "../../../assests/homePage/connect5.png";
import connect6 from "../../../assests/homePage/connect6.png";

interface ConnectBankProps {
  connectImages?: string[];
  containerColor?: string;
}

const ConnectBank: React.FC<ConnectBankProps> = ({ connectImages,containerColor }) => {
  return (
    <div className={styles.bankcontainer} style={{ backgroundColor: containerColor || 'rgba(9, 84, 126, 0.05)' }}>
      <Container>
        <div className={styles.connect} style={{padding:containerColor ? "20px 20px 60px 20px":"48px 15px"}}>
          <h3>Connect your bank account</h3>
          <div className={styles.connect_imgs}>
          <img src={connect1} alt="" height={29} width={164} />
                <img src={connect2} alt="" height={29} width={171} />
                <img src={connect3} alt="" height={40} width={118} />
                <img src={connect4} alt="" height={29} width={121} />
                <img src={connect5} alt="" height={43} width={138} />
                <img src={connect6} alt="" height={32} width={121} />
          </div>

          <div className={styles.line}>
            {/* Add optional line image or styling here */}
          </div>

          <p>+ Plus every bank you'll ever need.</p>
          <Link to="/about-us/contact-us">
            <CustomBtn
              style={{
                height: '54px',
                padding: '0px 30px',
                fontWeight: 800,
                fontSize: '14px',
              }}
            >
              Request a free consultation
            </CustomBtn>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ConnectBank;
