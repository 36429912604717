import React, { useEffect } from "react";
import styles from "./caseStudies.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";

interface DataItem {
  title: string;
  description: string;
  buttonText?: string;
  logo: string; // Assuming logo is a URL
}

interface caseStudiesProps {
  data: DataItem[];
  heading: string;
  subHeading: string; // Use the defined interface for the data prop
}
const CaseStudy: React.FC<caseStudiesProps> = ({
  heading,
  subHeading,
  data,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-5" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h2>{heading}</h2>
              <p>{subHeading}</p>
            </div>
          </Col>

          {data.map((item, index) => (
            <Col key={index} className="col-md-4" xs={12}>
              <div className={`text-center ${styles.box} hoverDivWhite`}>
                <div className={styles.boxImg}>
                  <img src={item.logo} alt={`Logo ${index}`} />
                </div>
                <div className={styles.boxText}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                  {item.buttonText ? <Button>{item.buttonText}</Button> : <></>}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CaseStudy;
