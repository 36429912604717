import { useEffect, useState } from "react";
import styles from "./blog.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import useFetch from "../../../hooks/useFetch";
import { Link, useParams } from "react-router-dom";
import moment from "moment"; // Import moment
import { Badge } from "react-bootstrap";
import { BASE_IMAGE_URL } from "../../../services/constants";


interface BlogDetailsType {
  blog_categories: any;
  title?: string;
  description?: any;
  createdAt?: any;
  image?: any;
  updatedAt?: any;
  // Add other properties as needed
}



function BlogDetails() {
  const [blogDetails, setBlogDetails] = useState<BlogDetailsType>();
  const [loading, setLoading] = useState(true);

  const { id } = useParams()

  const [blogDetailsApi] = useFetch(
    `/v1/blog/detail/${id}`,
    {
      method: "post",
    }
  );

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const { data } = await blogDetailsApi();

        setBlogDetails(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        setLoading(false);
      }
    };

    fetchBlogDetails();
     // eslint-disable-next-line
  }, []);





  return (
    <DashboardLayout>
      <div className={styles.users_page}>

        <div className={styles.prof_page}>
          <div className={styles.backBtn}>
            <Link to="/admin/blog">
              <button className="btn">
                <BsFillArrowLeftCircleFill />
                Back
              </button>
            </Link>
          </div>



          <div className={styles.prof_detail}>
            <div className="theme_tabs">

              <div className={styles.detail_wrap}>
                <div className={styles.detail_fields}>
                  <div className={styles.detail_col3} >
                    <div className={styles.detail_single}>
                      <h5>Blog Details</h5>
                      <p>  <img src={`${BASE_IMAGE_URL}${blogDetails?.image}`} alt="blog Image"  /> </p>
                      {/* <p> {blogDetails?.image}</p> */}
                    </div>
                  </div>

                  <div className={styles.detail_col9} >
                    <div className={styles.detail_fields}>
                      <div className={styles.detail_col12} >
                        <div className={styles.detail_single}>
                          <h5 className={styles.titlesClass}>{blogDetails?.title}</h5>
                          {/* <p>{blogDetails?.title}</p> */}
                        </div>
                      </div>

                      <div className={styles.detail_col12} >
                        <div className={styles.detail_single}>
                          <h5>Category</h5>
                          <p>
                            {blogDetails?.blog_categories.map(category => (
                              <Badge key={category.id} bg="info">{category.category_name}</Badge>
                            ))}
                          </p>
                        </div>
                      </div>

                      <div className={styles.detail_col12} >
                        <div className={styles.detail_single}>
                          <h5>created at</h5>
                          <p>{moment(blogDetails?.createdAt).format("MMMM D, YYYY")}</p>
                        </div>
                      </div>








                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>










          <div className={styles.prof_detail}>
            <div className="theme_tabs">
              <div className={styles.detail_wrap}>
                <div className={styles.detail_fields}>

                  <div className={styles.detail_col12} >
                    <div className={styles.detail_single}>
                      <h5>description</h5>
                      <p dangerouslySetInnerHTML={{ __html: blogDetails?.description }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </DashboardLayout>
  );
}
export default BlogDetails;
