import React, { useEffect } from "react";
import CustomBtn from "../../../../common/theme/button";
import styles from "./instantQuote.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import lineBluelIght from "../../../../assests/lineBluelIght.svg";
import lineGrays from "../../../../assests/lineGray.svg";
import circleLight from "../../../../assests/circleLight.svg";
import whiteCircle from "../../../../assests/whiteCrcle.svg";
import instantQuote from "../../../../assests/InstantQuote.png";
import circleBlue from "../../../../assests/circleBlue.svg";
import lineWhite from "../../../../assests/lineWhite.svg";
import Quote from "../../../../components/webapp/quote/Quote";
import ReviweSection from "../../../../components/webapp/reviweSection";
import user from "../../../../assests/homePage/user.svg";
import HiBooks from "../../../../components/webapp/hiBooks";
import instant1 from "../../../../assests/instant1.svg";
import instant2 from "../../../../assests/instant2.svg";
import instant3 from "../../../../assests/instant3.svg";
import Header from "../../../../components/webapp/header";
import AboutUsFooter from "../../../../components/webapp/footer/aboutUsFooter";
import GreenDotAndLine from "../../../../assests/lineAndDot.svg";
const InstantQuote = () => {
  const dataReview = [
    {
      title: "Edward Jones",
      subHead: "CEO hibooks ",
      description:
        "Easy to use, efficient and accurate. hibooks has helped over 75,000 small business owners to become brilliant bookkeepers.",
      logo: user,
    },
    {
      title: "Tax efficiency ",
      subHead: "CEO hibooks ",
      description:
        "hibooks removes the stress of bookkeeping by streamlining every process, leaving you more time to focus on growing your business.",
      logo: user,
    },
    {
      title: "Reduce",
      subHead: "CEO hibooks ",
      description:
        "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
      logo: user,
    },
    // Add more data objects as needed
  ];

  const dataBooks = [
    {
      title: "Your own dedicated accountant",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.",
      logo: instant1,
    },
    {
      title: "All accounts & returns submitted",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.",
      logo: instant2,
    },
    {
      title: "Simple bookkeeping Software",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.",
      logo: instant3,
    },
    // Add more data objects as needed
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className={styles.homePage}>
        <Container>
          <div className={styles.mainPage}>
            <Row className="align-items-center">
              <Col className="align-items-center col-md-5" xs={12}>
                <div className={styles.leftPage}>
                  <div className={styles.linegrayssTop}>
                    <img src={lineBluelIght} alt="" />
                  </div>
                  <div className={styles.provide}>
                    <h6>Sed ut perspiciatis unde</h6>
                    <h1>Instant Quote</h1>
                    <p>We charge a monthly fee based on your business type.</p>
                    <div className={styles.buttons}>
                      <CustomBtn>Call to action</CustomBtn>
                      <CustomBtn className={styles.actionButton}>
                        Call to action
                      </CustomBtn>
                    </div>
                  </div>
                  <div className={styles.linegrayss}>
                    <img src={lineGrays} alt="" />
                  </div>
                </div>
                <div className={styles.circlelight}>
                  <img src={circleLight} alt="" />
                </div>
                <div className={`${styles.circlelight} ${styles.topLight}`}>
                  <img src={whiteCircle} alt="" />
                </div>
              </Col>
              <Col className=" col-md-7" xs={12}>
                <div className={styles.rightPage}>
                  <img src={instantQuote} alt="" />
                </div>
                <div className={styles.greenDotAndLine}>
                  <img src={GreenDotAndLine} alt="" />
                </div>
                <div className={styles.circleblue}>
                  <img src={circleBlue} alt="" />
                </div>
              </Col>
            </Row>
            <div className={styles.lineWhite}>
              <img src={lineWhite} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.HiBooksQuete}>
        <Quote
          heading="Great support for your business, fast."
          subheading="Get a quote for our Tax Return Service"
          headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
          headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
          headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our Tax Return  services now. Simply complete and submit the form."
          id={undefined}
        />
      </div>
      <div className={styles.HiBooks}>
        <HiBooks
          dataBooks={dataBooks}
          heading="How does our tax return service work?"
          subHeading="Our tax accountants make preparing and submitting your return as easy as possible"
        />
      </div>
      <div className={styles.HiBooks}>
        <ReviweSection dataReview={dataReview} type="reviweBg" />
      </div>
      <AboutUsFooter />
    </>
  );
};

export default InstantQuote;
