import React from "react";
import Header from "../header";
import Footer from "../footer";

interface IProps {
  children: string | JSX.Element;
  headerStyle?: string;
}

const WebappLayout = ({ children, headerStyle }: IProps) => {
  return (
    <>
      <Header styleVariant={headerStyle} />
      {children}
      <Footer />
    </>
  );
};

export default WebappLayout;
