import React, { useEffect, useRef } from "react";
import styles from "./selftAssinments.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/homePage/companieBanner.png";
import companies1 from "../../../../assests/homePage/companies1.svg";
import cis from "../../../../assests/homePage/cal.svg";
import cis1 from "../../../../assests/homePage/new.svg";
import cis2 from "../../../../assests/homePage/location.svg";
import cis3 from "../../../../assests/homePage/user.svg";
import dollarImg from "../../../../assests/homePage/dollarImg.svg";
import user1 from "../../../../assests/homePage/box1.svg";
import mediums from "../../../../assests/homePage/box2.svg";
import ship from "../../../../assests/homePage/ship.svg";
import book from "../../../../assests/homePage/book.svg";
import ship1 from "../../../../assests/homePage/ship5.svg";
import ship5 from "../../../../assests/homePage/ship1.svg";
import instantcard from "../../../../assests/instantcard33.svg";
import user from "../../../../assests/homePage/user.svg";
import whiteLineGray from "../../../../assests/services/whiteLineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import box3 from "../../../../assests/homePage/box.svg";
import box5 from "../../../../assests/homePage/box5.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import WhoDoWe from "../../../../components/webapp/whoDoWe";
import HiBooks from "../../../../components/webapp/hiBooks";
import { Link } from "react-router-dom";
import Faqs from "../../../../components/webapp/faqs";

const data1 = [
  {
    title: "Tax planning & efficiency",
    description:
      "Our tax planning expertise will make the best of your tax position. Don’t pay a penny more than you have to.",
    logo: cis,
    lineBar: lineBar,
  },
  {
    title: "Self-assessment returns",
    description:
      "Returns prepared and submitted accurately, reliably and on time, every time.",
    logo: cis1,
    lineBar: lineBar,
  },
  {
    title: "HMRC representation",
    description:
      "HMRC have an enquiry? We’ll act on your behalf. We’re always on your side.",
    logo: cis2,
    lineBar: lineBar,
  },
  {
    title: "Pick our brains, all year long.",
    description:
      "Got a question? Your dedicated Skytax accountant is always ready to help.",
    logo: cis3,
    lineBar: lineBar,
  },

  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Accounts",
    description:
      "Our comprehensive and flexible range of professional accounts gives you the time and insight to manage your business even better.",
    buttonText: "Learn more",
    onclick: <Link to="/services/accounts" />,
    logo: user1,
  },
  {
    title: "Bookkeeping",
    description:
      "We’ll handle the hard work of paperwork, leaving you free to work on your business.",
    buttonText: "Learn more",
    onclick: <Link to="/services/bookKeeping" />,

    logo: mediums,
  },
  {
    title: "Payroll ",
    description: "Paying your people the right amount at the right time.",
    buttonText: "Learn more",
    onclick: <Link to="/services/payroll" />,

    logo: box3,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Send us your stuff",
    description:
      "We’ll remind you in good time, and gather your bookkeeping records.",
    logo: ship1,
    blueLine: whiteLineGray,
    count: "1",
  },
  {
    title: "We’ll crunch the numbers",
    description:
      "We’ll optimise, calculate, prepare and check your  Self Assessment return so it’s accurate and all ready to go.",
    logo: box5,
    blueLine: whiteLineGray,
    count: "2",
  },
  {
    title: "We’ll submit your return  to HMRC",
    description:
      "Once you’ve approved it, we’ll submit your return on your behalf to HMRC.",
    logo: dollarImg,
    blueLine: whiteLineGray,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd",
    description:
      "Skytax Accounting Ltd. has been an absolute asset to my property management company. Their expertise and meticulous approach to handling our finances have streamlined our bookkeeping process significantly. Their team's knowledge of the property industry is unmatched, providing tailored solutions that have truly made a difference.",
    logo: user,
  },
  {
    title: "Gordon Whitings ",
    subHead: " ",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  {
    title: "Philips",
    subHead: " ",
    description:
      "As an IT freelancer running my own limited company in the UK, finding an accounting practice that truly understands the complexities of my industry was crucial. I discovered Skytax and was immediately impressed by their tailored approach. Their expertise in handling the specific financial intricacies of IT contractors and managing expenses has been invaluable. They offer proactive advice, ensuring my company’s financial health remains robust while I focus on delivering top-notch services to my clients. Their dedication, industry knowledge, and personalized support make them an indispensable asset to any freelancer seeking reliable financial guidance.",
    logo: user,
  },
  // Add more data objects as needed
];

const dataBooks = [
  {
    title: "Enjoy January!",
    description:
      "No more stressing about last-minute returns - we’ll take care of everything for you.",
    logo: ship1,
  },
  {
    title: "Maximise your finances",
    description:
      "Worrying that you’re not claiming everything you’re entitled to? Don’t. We’ll make sure that your return is fully optimised to keep your bank balance happy.",
    logo: instantcard,
  },
  {
    title: "24/7 Support",
    description:
      "Need help? Your dedicated Skytax accountant is only a call or an email away.",
    logo: dollarImg,
  },
  // Add more data objects as needed
];

const data = [
  {
    title: "Sole Traders and Freelancers",
    price: "From £30 per month",
    description: "Stress-free Self Assessment that’s reliably affordable.",
    buttonText: "Get started",
    onclick: <a href="#self" />,
    logo: ship5,
  },

  {
    title: "Limited Companies",
    price: "From £120 per month",
    description:
      "Straightforward, on-time filing of your limited company director personal tax return.",
    onclick: <a href="#self" />,

    buttonText: "Get started",
    logo: ship,
  },

  {
    title: "Partnerships",
    price: "From £50 per month",
    onclick: <a href="#self" />,

    description:
      "We’ll take care of you and your partner’s individual Self Assessment forms along with your Partnership Tax Return.",
    buttonText: "Get started",
    logo: book,
  },

  // Add more data objects as needed
];

const faqItems: any = [
  {
    header: "My tax is a mess. Can you help?",
    body: "Yes, of course. The messier, the better! We’ll get you ship-shape and regularise your self-assessment tax position quickly. We’ll also work with HMRC on your behalf to straighten things out.",
    innerbody: "",
    lowbody: "",
  },
  {
    header: "HMRC stresses me out. Can you talk to them on my behalf?",
    body: "Yes. With your authority, we can act as your agent for HMRC, and work on your behalf to resolve issues.",
    innerbody: "",
    lowbody: "",
  },
  {
    header:
      "I’m fed up with the mad rush each year to submit the right tax at the right time. Can you help?",
    body: "That’s what we’re here for! Throughout the year we’ll make sure that your self-assessment returns are on target, and we’ll prepare and submit them on time.",
    innerbody: "",
    lowbody: "",
  },
  {
    header: "Can I get hold of my Skytax accountant easily?",
    body: (
      <span>
        Of course! We’re always at the end of a phone or email for you. And you
        never need to remind us to do anything. In fact, often it’s the other
        way round -you can rely on us to reach out to you regularly for
        information to help us prepare your self-assessment returns.
        <br />
        We’re super-proactive, but we don’t nag!
      </span>
    ),

    lowbody: "",
  },
  {
    header: "Is it safe to share my business data with you?",
    body: "Absolutely. We’re fully compliant with the GDPR and are registered with the ICO. We treat sensitive financial information with the utmost care. Find out more by checking out our Privacy Policy.",
    innerbody: "",
    lowbody: "",
  },
  {
    header:
      "I really don’t understand how payments on account work. Can you help me understand it?",
    body: "Yes, of course. We’ll always explain things clearly and show you how things work, and we’re always at the end of a phone or email for anything else.",
    innerbody: "",
    lowbody: "",
  },
];

const SelftAssinments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const whoDoWeRef = useRef<HTMLDivElement>(null);
  const handleFindOutClick = () => {
    if (whoDoWeRef.current) {
      whoDoWeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Making it easy</h6>
                      <h1>Self Assessment</h1>
                      <p>
                        Stress-free Self Assessment for directors and
                        solopreneurs.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        <a href="#service">
                          <Button onClick={handleFindOutClick}>
                            Find out more
                          </Button>
                        </a>
                        <Link to="/about-us/contact-us">
                          <Button className={styles.actionButton}>
                            Get started
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div id="service" className={styles.WhoDoWe}>
          <Services
            data={data1}
            heading="What you get"
            subHeading="Optimised tax. Professionally-prepared Self Assessment returns. Submitted to HMRC on time, always."
            text={""}
            imageSrc={""}
            type="ServicesAccount" // imageSrc={imageSrc}
          />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="How it works"
            subHeading="Self Assessment in just three easy steps."
            type="accounts"
          />
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            type="account"
            heading="What it costs"
            subHeading="All our packages include Your own Dedicated Accountant, Online Accounting Software and Year End Accounts"
            innerText="*CIS returns an add-on service to our accountancy packages. The quote you receive will also include the cost of your accounts and tax returns.
            There is a £30.00 minimum charge for our CIS returns. So for example, if you have 6 sub-contractors our fee would be just £36 per month."
          />
        </div>

        <div className={styles.HiBooks}>
          <HiBooks
            dataBooks={dataBooks}
            heading="Why choose us?"
            subHeading="Accurate, on-time self-assessment services, with peace of mind fitted as standard."
            // type="payroll"
            innerText="We also offer a complete Workplace Pensions Management service to our payroll clients."
            imgLarge={ship1}
            lineWhite={lineWhite}
          />
        </div>

        <div className={`${styles.HiBooks} ${styles.AccordionsSection}`}>
          <Faqs
            faqItems={faqItems}
            heading="FAQs"
            subHeading=" Can we help you? Absolutely. Find out more about our self-assessment services."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} type="reviweBg" />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Need other accountancy and business services? No problem. We’re here to help."
            subHeading="Don’t limit yourself. Choose from a tried, tested and trusted range of expert services that make life easier and business better."
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            id="self"
            heading="Great support for your business, fast."
            subheading="Get a quote for our Self-Assessment Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our Self Assessment services now. Simply complete and submit the form."
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default SelftAssinments;
