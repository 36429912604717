import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import styles from "../../../../components/admin/addBlogs/AddBlogForm.module.scss";
import "react-quill/dist/quill.snow.css"; // Import styles
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";

interface IProps {
  toggleSidebar?: any;
  editableItem?: any;
  editView?: boolean;
  setInitinalValues?: any;
  initinalValues?: any;
}

const VideosForm = ({
  editableItem,
  initinalValues,
  setInitinalValues,
  editView,
}: IProps) => {
  // const [blogContent, setBlogContent] = useState(
  //   editableItem ? editableItem.content : ""
  // );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    title: yup.string().required("Title is required "),
    // youtube_link: yup.string().required("Youtube link is required "),
    youtube_link: yup
      .string()
      .url("Invalid link format")
      .required("Link is required"),
  });

  const { id } = useParams();

  const [videoDetailsApi] = useFetch(`/v1/video/detail/${id}`, {
    method: "post",
  });

  useEffect(() => {
    if (editView) {
      videoDetailsApi();
    }
    // eslint-disable-next-line
  }, [editView]);

  const [videoApi] = useFetch(
    editView && editableItem?.item?.id
      ? `/v1/video/edit/${editableItem.item.id}`
      : `/v1/video/add`,

    {
      method: editView ? "put" : "post",
    }
  );

  useEffect(() => {
    if (editableItem) {
      setInitinalValues({
        title: editableItem?.item?.title,
        youtube_link: editableItem?.item?.youtube_link,
      });
    }
    // eslint-disable-next-line
  }, [editableItem]);

  const handleSubmit = async (values) => {
    let formdata = new FormData();
    formdata.append("title", values.title);
    formdata.append("youtube_link", values.youtube_link);

    try {
      setLoading(true);
      const response = await videoApi(formdata);
      setLoading(false);
      if (response.data && response.data.status === 1) {
        navigate("/admin/videos");
      } else {
        console.error("API Request Error:", response?.data?.message);
        if (response.data && response.data.status === 0) {
          toast.error(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className={styles.blogBox}>
      <ToastContainer />
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        // initialValues={initinalValues}
        initialValues={initinalValues} // Change this to initialValues
        enableReinitialize={editView}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <div className={styles.formStep}>
                <h4>{editView ? "Edit Video" : "Add Video"}</h4>

                <div className={styles.twoFieldsWrap}>
                  <div className={styles.halfDivs}>
                    <Form.Group className={styles.singleField}>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        // isInvalid={!!errors.title && touched.title ? true : false}
                      />

                      <Form.Control.Feedback type="invalid">
                        {typeof errors?.title === "string"
                          ? touched.title && errors?.title
                          : ""}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className={styles.singleField}>
                      <Form.Label>Youtube Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Youtube Link"
                        name="youtube_link"
                        value={values.youtube_link}
                        onChange={handleChange}
                        // isInvalid={!!errors.category_name && touched.category_name ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {typeof errors?.youtube_link === "string"
                          ? touched.title && errors?.youtube_link
                          : ""}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className={styles.singleField}>
                    <button
                      className={`mt-2 mb-3 btn btn-primary ${styles.subBtn}`}
                      type="submit"
                      disabled={loading} // Disable the button when loading
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only ms-1">
                            {editView ? "Update" : "Save"}
                          </span>
                        </>
                      ) : (
                        `${editView ? "Update" : "Save"}`
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default VideosForm;
