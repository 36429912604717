import React, { useCallback, useEffect, useState } from "react";
import styles from "./login.module.scss";
import { Form, Formik } from "formik";
import { Form as ReactForm } from "react-bootstrap";
import * as Yup from "yup";
import { RotatingLines } from "react-loader-spinner";
import AuthLayout from "../../../components/admin/layouts/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ToastContainer, toast } from "react-toastify";
import { setUserData } from "../../../redux/common";
import { useDispatch } from "react-redux";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ValidateSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Enter a valid email"),
    password: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Password is required"),
  });

  const [loginApi] = useFetch(`/v1/auth/login`, {
    method: "post",
  });
  const handleSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const response = await loginApi({ ...values });
        setLoading(false);

        if (response.data && response.data.status === 1) {
          // Redirect to the admin dashboard on success
          // Save the response data in local storage
          localStorage.setItem("userData", JSON.stringify(response.data));
          dispatch(setUserData(response.data));
          localStorage.setItem(
            "authToken",
            response?.data?.data?.access_token || ""
          );
          navigate("/admin/dashboard");
        } else {
          // Handle API response errors here
          console.error("API Request Error:", response?.data?.message);

          // Check for specific error message for invalid password
          if (response.data && response.data.status === 0) {
            toast.error(response.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            // Show a generic error message for other errors
            toast.error("An error occurred. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      } catch (error) {
        // Handle API request error
        console.error("API Request Error:", error);
        setLoading(false);
      }
    },
    [loginApi, navigate]
  );

  useEffect(() => {
    // const authToken = localStorage.getItem('authToken');
    // const isAdminPath = window.location.pathname.startsWith('/admin');
    // if (isAdminPath && authToken) {
    //   navigate('/admin/dashboard');
    // } else if (isAdminPath && !authToken) {
    //   navigate('/admin/login');
    // }

    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      navigate("/admin/dashboard");
    } else if (!authToken) {
      navigate("/admin/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ToastContainer />
      <AuthLayout>
        <div className={styles.login}>
          <div className={styles.login_ByGoogle}>
            <div className={styles.formTitle}>
              {/* <img src={symbolImg} alt="logo" /> */}
              <h2>Login to your Account</h2>
              <p>See what is going on with your business</p>
            </div>
          </div>
          <div className={styles.formFields}>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={ValidateSchema}
              // onSubmit={(values) => {
              //   // login(values);
              //   login
              // }}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleChange, handleBlur, values }) => (
                <Form>
                  <ReactForm.Group
                    className={styles.formFieldSingle}
                    controlId="formBasicEmail"
                  >
                    <ReactForm.Label>Email</ReactForm.Label>
                    <ReactForm.Control
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <div className={styles.error}>{errors.email}</div>
                    )}
                  </ReactForm.Group>

                  <ReactForm.Group
                    className="mb-2"
                    controlId="formBasicPassword"
                  >
                    <ReactForm.Label>Password</ReactForm.Label>
                    <ReactForm.Control
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <div className={styles.error}>{errors.password}</div>
                    )}
                  </ReactForm.Group>
                  <div className={styles.formOptions_wrap}>
                    <ReactForm.Group controlId="formBasicCheckbox">
                      <ReactForm.Check type="checkbox" label="Keep me login" />
                    </ReactForm.Group>
                    <Link to="/admin/forgotPassword">
                      {" "}
                      <span className="theme_txtLink">Forgot Password?</span>
                    </Link>
                  </div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    Login
                    {loading && (
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                        visible={true}
                      />
                    )}
                  </button>
                </Form>
              )}
            </Formik>

            {/* <div className={styles.createRegister}>
                <span>Not Registered Yet? Contact to:-</span>
                <span className="theme_txtLink"> +91 123,464,9128</span>
              </div> */}
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default Login;
