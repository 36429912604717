import { useEffect, useState } from "react";
import styles from "./users.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import { useLocation } from "react-router-dom";
import AddUsersForm from "../../../components/admin/addUsers/addUsersForm";
function AddEditUsers() {
  const [addEmploySidebar, setAddEmploySidebar] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<any | null>(null);
  const [editView, setEditView] = useState(false);
  const [initinalValues, setInitinalValues] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });


  const location = useLocation();

  const toggleEmploySidebar = () => {
    setAddEmploySidebar(!addEmploySidebar);
  };




  useEffect(() => {
    if (location.state) {
      setEditView(true)
      setEditableItem(location.state)
      // setInitinalValues({
      //   id: location?.state?.item?.id,
      //   title: location?.state?.item?.name,
      //   description: location?.state?.item?.descriptions,
      //   images: location?.state?.item?.images,
      // })
    }
  }, [location.state]);


  return (
    <DashboardLayout>
      <div className={styles.users_page}>
        <AddUsersForm
          toggleSidebar={toggleEmploySidebar}
          editView={editView}
          editableItem={editableItem}
          initinalValues={initinalValues}
          setInitinalValues={setInitinalValues}
        />

      </div>
    </DashboardLayout>
  );
}
export default AddEditUsers;
