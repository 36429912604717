import React from "react";
import styles from "./whychooseus.module.scss";
import { Col, Container, Row } from "react-bootstrap";

import { useLocation, useParams } from "react-router-dom";

interface WhyChooseUs {
  heading: string;
  gradientHeading?: string;
  img: string;
  data: any[];
  buttonText: string;
  type?: string;
}
const WhyChooseUS: React.FC<WhyChooseUs> = ({
  heading,
  gradientHeading,
  data,
  img,
  buttonText,
  type,
}) => {
  const firstData = data.slice(0, 7);
  const secondData = data.slice(7, 13);
  return (
    <div className={styles.whychooseus}>
      <Container>
        <Row className="">
          {/* <div className={`text-center ${styles.heading}`}>
              <h2>What people say</h2>
              <p>Discover why our clients love our services.</p>
            </div> */}
          <Col className="col-md-6 col-12 mb-5">
            <div className={styles.imageContainer}>
              <img src={img} alt="" />
            </div>
          </Col>
          <Col className="col-md-6 col-12 mb-5">
            <div className={styles.textContainer}>
              <div>
                <h1>
                  {heading}
                  <span className={styles.gradientText}>{gradientHeading}</span>
                </h1>
                <div
                  className={type === 'career' ? "d-flex" : "block"}
                  style={{ gap: "50px" }}
                >
                  <ul className="">
                    {firstData.map((item, index) => (
                      <li key={index}>{item.description}</li>
                    ))}
                  </ul>
                  <ul className="">
                    {secondData.map((item, index) => (
                      <li key={index}>{item.description}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <button className="mt-3 mt-md-0">{buttonText}</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyChooseUS;
