import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./featureOfSoftware.module.scss";

interface DataItem {
  title: string;
  // price: string;
  description: string;
  // buttonText: string;
  logo: string; // Assuming logo is a URL
  lineBar: string;
}

interface WhoDoWeProps {
  dataFeatureSoftware: DataItem[]; // Use the defined interface for the data prop
}
const FeatureOfSoftware: React.FC<WhoDoWeProps> = ({ dataFeatureSoftware }) => {
  return (
    <div>
      <Container>
        <Row>
          {dataFeatureSoftware?.map((item, index) => (
            <Col key={index} className="col-md-4" xs={12}>
              <div className={`${styles.box} hoverDivWhite`}>
                <div className={styles.boxImg}>
                  <img src={item.logo} alt={`Logo ${index}`} />
                </div>
                <div className={styles.boxText}>
                  <h6>{item.title}</h6>

                  <p>{item.description}</p>
                  <img src={item.lineBar} alt="" />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default FeatureOfSoftware;
