import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import styles from "../../../../components/admin/addBlogs/AddBlogForm.module.scss";
import "react-quill/dist/quill.snow.css"; // Import styles
import { useCallback, useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';

interface IProps {
  toggleSidebar?: any;
  editableItem?: any;
  editView?: boolean;
  setInitinalValues?: any;
  initinalValues?: any;

}

const CategoryForm = ({
  editableItem,
  initinalValues,
  setInitinalValues,
  editView,
  
}: IProps) => {
  // const [blogContent, setBlogContent] = useState(
  //   editableItem ? editableItem.content : ""
  // );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const schema = yup.object().shape({
    category_name: yup.string().required("Category is required "),
  });



  const { id } = useParams()

 
  const [categoryDetailsApi] = useFetch(
    `/v1/category/detail/${id}`,
    {
      method: "post",
    }
  );

  useEffect(() => {
    if (editView) {
      categoryDetailsApi();
    }
     // eslint-disable-next-line
  }, [editView]);
 

  const [categoryApi] = useFetch(
    editView && editableItem?.item?.id
    ? `/v1/category/edit/${editableItem.item.id}`
    : `/v1/category/add`,

    {
      method: editView ? "put" : "post",
    }
  );

 


  

  useEffect(() => {
   
    if(editableItem){
      setInitinalValues({
        category_name: editableItem?.item?.category_name,
      })
    }
     // eslint-disable-next-line
  }, [editableItem]);



  const handleSubmit = useCallback(async (values) => {
      let confirmValues = {
      category_name: values.category_name,
    };
    try {
      setLoading(true);
      const response = await categoryApi({...confirmValues});
      setLoading(false);

      if (response.data && response.data.status === 1) {
        navigate("/admin/category");
      } else {
        console.error("API Request Error:", response?.data?.message);
        if (response.data && response.data.status === 0) {
          toast.error(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false);
    }
     // eslint-disable-next-line
  }, [categoryApi, navigate, editableItem]);

  

  return (
    <div className={styles.blogBox}>
       <ToastContainer />
      <Formik
        validationSchema={schema}
       

        onSubmit={handleSubmit}

        
        // initialValues={initinalValues}
        initialValues={initinalValues} // Change this to initialValues
        enableReinitialize={editView}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <>
         

            <Form noValidate onSubmit={handleSubmit}>
              <div className={styles.formStep}>
                <h4>{editView ? "Edit Category" : "Add Category"}</h4>

                <div className={styles.twoFieldsWrap}>
                  <Form.Group className={styles.singleField}>
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="category_name"
                      value={values.category_name}
                      onChange={handleChange}
                      isInvalid={!!errors.category_name && touched.category_name ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {typeof errors?.category_name === "string" ? errors?.category_name : ""}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className={styles.singleField}>
                  <button
                    className={`mt-2 mb-3 btn btn-primary ${styles.subBtn}`}
                    type="submit"
                    disabled={loading} // Disable the button when loading
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only ms-1">{editView ? "Update" : "Save"}</span>
                      </>
                    ) : (
                      `${editView ? "Update" : "Save"}`
                    )}
                  </button>
              </div>
                </div>
            
              </div>

           
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CategoryForm;
