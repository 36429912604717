import profileImg from "../../../assests/admin/profile.png";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import styles from "./topBar.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { setUserData } from "../../../redux/common";

interface IProps {
  toggleSidebar: () => void;
  sidebarOpen: boolean;
}
const TopBar = ({ toggleSidebar, sidebarOpen }: IProps) => {
  const [userId, setUserId] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>();
  const [name, setName] = useState("");

  const userDataId = localStorage.getItem("userData");
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDataId !== null) {
      const userData = JSON.parse(userDataId);
      const userIdFromStorage = userData?.data?.user?.id;
      if (userIdFromStorage) {
        setUserId(userIdFromStorage);
      }
    }
  }, [userDataId]);

  const [userDetailsApi] = useFetch(`/v1/user/profile/${userId}`, {
    method: "post",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data } = await userDetailsApi();

        dispatch(setUserData(data));
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    if (userId) {
      fetchUserDetails();
    }
    // eslint-disable-next-line
  }, [userId]);

  const userData = useSelector((state: any) => state.userData.data);

  useEffect(() => {
    setName(
      (userData?.data?.first_name ?? "") +
        " " +
        (userData?.data?.last_name ?? "")
    );
  }, [userData]);

  return (
    <div className={styles.topBar}>
      <div className={styles.topBar_search}>
        {/* <input
          type="text"
          className="form-control"
          placeholder="Make your search here"
        /> */}
        <button onClick={toggleSidebar}>
          {sidebarOpen ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
        </button>
      </div>

      <div className={styles.login_user}>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div>
              <img src={profileImg} alt="profile img" />
              {/* <span>{authUser.email}</span> */}
              <span>{name}</span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/admin/changePassword">
              Change Password
            </Dropdown.Item>
            <Dropdown.Item href="/admin/editProfile">
              Edit Profile
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default TopBar;
