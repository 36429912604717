import {  useEffect, useState } from "react";
import styles from "./category.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";

import { useLocation } from "react-router-dom";
import CategoryForm from "./categoryModal/CategoryForm";

function AddEditCategory() {

  const [addEmploySidebar, setAddEmploySidebar] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<any | null>(null);
  const [editView, setEditView] = useState(false);
  const [initinalValues, setInitinalValues] = useState({
    id: "",
    category_name: "",
  });

  const location = useLocation();

  const toggleEmploySidebar = () => {
    setAddEmploySidebar(!addEmploySidebar);
  };





  useEffect(() => {
    if (location.state) {
      setEditView(true);
      setEditableItem(location.state);
      // setInitinalValues({
      //   id: location?.state?.item?.id,
      //   category_name: location?.state?.item?.name,
      // });
    }
  }, [location.state]);

  return (
    <DashboardLayout>
      <div className={styles.users_page}>
        <CategoryForm
          toggleSidebar={toggleEmploySidebar}
          editView={editView}
          editableItem={editableItem}
          initinalValues={initinalValues}
          // initialValues={initinalValues} 
          setInitinalValues={setInitinalValues}
     
        />
      </div>
    </DashboardLayout>
  );
}
export default AddEditCategory;
