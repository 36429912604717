/* eslint-disable no-undef */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import styles from "./newQuote.module.scss";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import whiteLine from "../../../assests/homePage/lineWhite.svg";
import newContact from "../../../assests/homePage/newcontact.svg";
import { string } from "yup";
import { useLocation } from "react-router-dom";
import phone from "../../../assests/homePage/phone.png";
import mail from "../../../assests/homePage/mail.png";
import address from "../../../assests/homePage/location.png";
let visitorGlobalObjectAlias;
const NewQuote = ({
  heading,
  subheading,
  headingOne,
  headingTwo,
  headingthree,
  id,
}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  let tooltips = [];
  let submitted = false;
  let form_to_submit;
  let thank_you;
  let form;
  let allInputs;
  // let isRecaptchaChecked = false;

  useEffect(() => {
    // Your code here
    form_to_submit = document.getElementById("_form_26_");
    thank_you = form_to_submit.querySelector("._form-thank-you");
    allInputs = form_to_submit?.querySelectorAll("input, select, textarea");
    // form = document.getElementById("_form_" + 22+ "_");
  });

  const cfields = {
    6: "business_type",
    7: "turnover",
    8: "need_vat_returns_completing",
    9: "need_payroll_managing",
    10: "need_your_bookkeeping_completed",
  };

  // running condtion
  const _show_thank_you = (id, message, trackcmp_url, email) => {
    form_to_submit.querySelector("._form-content").style.display = "none";
    thank_you.innerHTML = message;
    thank_you.style.display = "block";
    const vgoAlias =
      typeof visitorGlobalObjectAlias === "undefined"
        ? "vgo"
        : visitorGlobalObjectAlias;
    let visitorObject = window[vgoAlias];
    if (email && typeof visitorObject !== "undefined") {
      visitorObject("setEmail", email);
      visitorObject("update");
    } else if (typeof trackcmp_url != "undefined" && trackcmp_url) {
      // Site tracking URL to use after inline form submission.
      loadScript(trackcmp_url);
    }
    if (typeof window._form_callback !== "undefined") window._form_callback(id);
  };

  const showError = (id, message, html) => {
    let form = document.getElementById("_form_" + id + "_"),
      err = document.createElement("div"),
      button = form.querySelector("button"),
      old_error = form.querySelector("._form_error");
    if (old_error) old_error.parentNode.removeChild(old_error);
    err.innerHTML = message;
    err.className = "_error-inner _form_error _no_arrow";
    let wrapper = document.createElement("div");
    wrapper.className = "_form-inner";
    wrapper.appendChild(err);
    button.parentNode.insertBefore(wrapper, button);
    let submitButton = form.querySelector('[id^="_form"][id$="_submit"]');
    submitButton.disabled = false;
    submitButton.classList.remove("processing");
    if (html) {
      let div = document.createElement("div");
      div.className = "_error-html";
      div.innerHTML = html;
      err.appendChild(div);
    }
  };

  //running condtion
  const loadScript = function (url, callback, isSubmit) {
    let head = document.querySelector("head"),
      script = document.createElement("script"),
      r = false;
    let submitButton = document.querySelector("#_form_26_submit");
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.src = url;
    if (callback) {
      script.onload = script.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState == "complete")) {
          r = true;
          callback();
        }
      };
    }
    script.onerror = function () {
      if (isSubmit) {
        if (script.src.length > 10000) {
          showError(
            "22",
            "Sorry, your submission failed. Please shorten your responses and try again."
          );
        } else {
          showError("22", "Sorry, your submission failed. Please try again.");
        }
        submitButton.disabled = false;
        submitButton.classList.remove("processing");
      }
    };

    head.appendChild(script);
  };

  // let form_to_submit = document.getElementById("_form_26_");
  if (window.location.search.search("excludeform") !== -1) return <></>;
  let getCookie = function (name) {
    let match = document.cookie.match(new RegExp("(^|; )" + name + "=([^;]+)"));
    return match ? match[2] : null;
  };
  let setCookie = function (name, value) {
    let now = new Date();
    let time = now.getTime();
    let expireTime = time + 1000 * 60 * 60 * 24 * 365;
    now.setTime(expireTime);
    document.cookie =
      name +
      "=" +
      value +
      "; expires=" +
      now +
      ";path=/; Secure; SameSite=Lax;";
  };

  window.onload = function () {
    // Call initializePhoneInput here
    getCookie();
    setCookie();
  };

  let addEvent = function (element, event, func) {
    if (element.addEventListener) {
      element.addEventListener(event, func);
    } else {
      let oldFunc = element["on" + event];
      element["on" + event] = function () {
        oldFunc.apply(this, arguments);
        func.apply(this, arguments);
      };
    }
  };

  let _removed = false;

  tooltips = [];
  submitted = false;

  let getUrlParam = function (name) {
    let params = new URLSearchParams(window.location.search);
    return params.get(name) || false;
  };
  let acctDateFormat = "%m/%d/%Y";
  let getNormalizedDate = function (date, acctFormat) {
    let decodedDate = decodeURIComponent(date);
    if (acctFormat && acctFormat.match(/(%d|%e).*%m/gi) !== null) {
      return decodedDate.replace(/(\d{2}).*(\d{2}).*(\d{4})/g, "$3-$2-$1");
    } else if (Date.parse(decodedDate)) {
      let dateObj = new Date(decodedDate);
      let year = dateObj.getFullYear();
      let month = dateObj.getMonth() + 1;
      let day = dateObj.getDate();
      return `${year}-${month < 10 ? `0${month}` : month}-${
        day < 10 ? `0${day}` : day
      }`;
    }
    return false;
  };
  let getNormalizedTime = function (time) {
    let hour, minutes;
    let decodedTime = decodeURIComponent(time);
    let timeParts = Array.from(
      decodedTime.matchAll(/(\d{1,2}):(\d{1,2})\W*([AaPp][Mm])?/gm)
    )[0];
    if (timeParts[3]) {
      // 12 hour format
      let isPM = timeParts[3].toLowerCase() === "pm";
      if (isPM) {
        hour =
          parseInt(timeParts[1]) === 12
            ? "12"
            : `${parseInt(timeParts[1]) + 12}`;
      } else {
        hour = parseInt(timeParts[1]) === 12 ? "0" : timeParts[1];
      }
    } else {
      // 24 hour format
      hour = timeParts[1];
    }
    let normalizedHour = parseInt(hour) < 10 ? `0${parseInt(hour)}` : hour;
    let minutesNew = timeParts[2];
    return `${normalizedHour}:${minutesNew}`;
  };
  for (let i = 0; i < allInputs?.length; i++) {
    let regexStr = "field\\[(\\d+)\\]";
    let results = new RegExp(regexStr).exec(allInputs[i].name);
    if (results != undefined) {
      allInputs[i].dataset.name = allInputs[i].name.match(/\[time\]$/)
        ? `${cfields[results[1]]}_time`
        : cfields[results[1]];
    } else {
      allInputs[i].dataset.name = allInputs[i].name;
    }
    let fieldVal = getUrlParam(allInputs[i].dataset.name);

    if (fieldVal) {
      if (allInputs[i].dataset.autofill === "false") {
        continue;
      }
      if (allInputs[i].type == "radio" || allInputs[i].type == "checkbox") {
        if (allInputs[i].value == fieldVal) {
          allInputs[i].checked = true;
        }
      } else if (allInputs[i].type == "date") {
        allInputs[i].value = getNormalizedDate(fieldVal, acctDateFormat);
      } else if (allInputs[i].type == "time") {
        allInputs[i].value = getNormalizedTime(fieldVal);
      } else {
        allInputs[i].value = fieldVal;
      }
    }
  }

  let remove_tooltips = function () {
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
    }
    tooltips = [];
  };
  let remove_tooltip = function (elem) {
    for (let i = 0; i < tooltips.length; i++) {
      if (tooltips[i].elem === elem) {
        tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
        tooltips.splice(i, 1);
        return;
      }
    }
  };
  let create_tooltip = function (elem, text) {
    let tooltip = document.createElement("div"),
      arrow = document.createElement("div"),
      inner = document.createElement("div"),
      new_tooltip = {};
    if (elem.type != "radio" && elem.type != "checkbox") {
      tooltip.className = "_error";
      arrow.className = "_error-arrow";
      inner.className = "_error-inner";
      inner.innerHTML = text;
      tooltip.appendChild(arrow);
      tooltip.appendChild(inner);
      elem.parentNode.appendChild(tooltip);
    } else {
      tooltip.className = "_error-inner _no_arrow";
      tooltip.innerHTML = text;
      elem.parentNode.insertBefore(tooltip, elem);
      new_tooltip.no_arrow = true;
    }
    new_tooltip.tip = tooltip;
    new_tooltip.elem = elem;
    tooltips.push(new_tooltip);
    return new_tooltip;
  };
  let resize_tooltip = function (tooltip) {
    let rect = tooltip.elem.getBoundingClientRect();
    let doc = document.documentElement,
      scrollPosition =
        rect.top -
        ((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0));
    if (scrollPosition < 40) {
      tooltip.tip.className =
        tooltip.tip.className.replace(/ ?(_above|_below) ?/g, "") + " _below";
    } else {
      tooltip.tip.className =
        tooltip.tip.className.replace(/ ?(_above|_below) ?/g, "") + " _above";
    }
  };
  let resize_tooltips = function () {
    if (_removed) return;
    for (let i = 0; i < tooltips.length; i++) {
      if (!tooltips[i].no_arrow) resize_tooltip(tooltips[i]);
    }
  };

  let validate_field = function (elem, remove) {
    let tooltip = null,
      value = elem.value,
      no_error = true;
    // remove ? remove_tooltip(elem) : false;
    if (remove) {
      remove_tooltip(elem);
    }
    if (elem.type != "checkbox")
      elem.className = elem.className.replace(/ ?_has_error ?/g, "");

    if (elem.getAttribute("required") !== null) {
      if (
        elem.type == "radio" ||
        (elem.type == "checkbox" && /any/.test(elem.className))
      ) {
        let elems = form_to_submit.elements[elem.name];
        if (
          !(elems instanceof NodeList || elems instanceof HTMLCollection) ||
          elems.length <= 1
        ) {
          no_error = elem.checked;
        } else {
          no_error = false;
          for (let i = 0; i < elems.length; i++) {
            if (elems[i].checked) no_error = true;
          }
        }
        if (!no_error) {
          tooltip = create_tooltip(elem, "Please select an option.");
        }
      } else if (elem.type == "checkbox") {
        let elems = form_to_submit.elements[elem.name],
          found = false,
          err = [];
        no_error = true;
        for (let i = 0; i < elems.length; i++) {
          if (elems[i].getAttribute("required") === null) continue;
          if (!found && elems[i] !== elem) return true;
          found = true;
          elems[i].className = elems[i].className.replace(
            / ?_has_error ?/g,
            ""
          );
          if (!elems[i].checked) {
            no_error = false;
            elems[i].className = elems[i].className + " _has_error";
            err.push("Checking %s is required".replace("%s", elems[i].value));
          }
        }
        if (!no_error) {
          tooltip = create_tooltip(elem, err.join("<br/>"));
        }
      } else if (elem.tagName == "SELECT") {
        let selected = true;
        if (elem.multiple) {
          selected = false;
          for (let i = 0; i < elem.options.length; i++) {
            if (elem.options[i].selected) {
              selected = true;
              break;
            }
          }
        } else {
          for (let i = 0; i < elem.options.length; i++) {
            if (
              elem.options[i].selected &&
              (!elem.options[i].value || elem.options[i].value.match(/\n/g))
            ) {
              selected = false;
            }
          }
        }
        if (!selected) {
          elem.className = elem.className + " _has_error";
          no_error = false;
          tooltip = create_tooltip(elem, "Please select an option.");
        }
      } else if (value === undefined || value === null || value === "") {
        elem.className = elem.className + " _has_error";
        no_error = false;
        tooltip = create_tooltip(elem, "This field is required.");
      }
    }
    if (no_error && (elem.id == "field[]" || elem.id == "ca[11][v]")) {
      if (elem.className.includes("phone-input-error")) {
        elem.className = elem.className + " _has_error";
        no_error = false;
      }
    }
    if (no_error && elem.name == "email") {
      if (
        !value.match(
          /^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i
        )
      ) {
        elem.className = elem.className + " _has_error";
        no_error = false;
        tooltip = create_tooltip(elem, "Enter a valid email address.");
      }
    }
    if (no_error && /date_field/.test(elem.className)) {
      if (!value.match(/^\d\d\d\d-\d\d-\d\d$/)) {
        elem.className = elem.className + " _has_error";
        no_error = false;
        tooltip = create_tooltip(elem, "Enter a valid date.");
      }
    }
    // tooltip ? resize_tooltip(tooltip) : false;
    if (tooltip) {
      resize_tooltip(tooltip);
    }
    return no_error;
  };
  let needs_validate = function (el) {
    if (el.getAttribute("required") !== null) {
      return true;
    }
    if (el.name === "email" && el.value !== "") {
      return true;
    }

    if (
      (el.id == "field[]" || el.id == "ca[11][v]") &&
      el.className.includes("phone-input-error")
    ) {
      return true;
    }

    return false;
  };

  let no_error = true;
  let validate_form = function (e) {
    let err = form_to_submit.querySelector("._form_error"),
      no_error = true;

    if (!submitted) {
      submitted = true;

      for (let i = 0, len = allInputs.length; i < len; i++) {
        let input = allInputs[i];
        if (needs_validate(input)) {
          if (input.type == "tel") {
            addEvent(input, "blur", function () {
              this.value = this.value.trim();
              validate_field(this, true);
            });
          }
          if (
            input.type == "text" ||
            input.type == "number" ||
            input.type == "time"
          ) {
            addEvent(input, "blur", function () {
              this.value = this.value.trim();
              validate_field(this, true);
            });
            addEvent(input, "input", function () {
              validate_field(this, true);
            });
          } else if (input.type == "radio" || input.type == "checkbox") {
            (function (el) {
              let radios = form_to_submit.elements[el.name];
              for (let i = 0; i < radios.length; i++) {
                addEvent(radios[i], "click", function () {
                  validate_field(el, true);
                });
              }
            })(input);
          } else if (input.tagName == "SELECT") {
            addEvent(input, "change", function () {
              validate_field(this, true);
            });
          } else if (input.type == "textarea") {
            addEvent(input, "input", function () {
              validate_field(this, true);
            });
          }
        }
      }
    }
    remove_tooltips();
    for (let i = 0, len = allInputs.length; i < len; i++) {
      let elem = allInputs[i];
      if (needs_validate(elem)) {
        if (elem.tagName.toLowerCase() !== "select") {
          elem.value = elem.value.trim();
        }
        // validate_field(elem) ? true : (no_error = false);
        no_error = validate_field(elem) && no_error;
      }
    }
    if (!no_error && e) {
      e.preventDefault();
    }
    resize_tooltips();
    return no_error;
  };
  addEvent(window, "resize", resize_tooltips);
  addEvent(window, "scroll", resize_tooltips);

  let hidePhoneInputError = function (inputId) {
    let errorMessage = document.getElementById("error-msg-" + inputId);
    let input = document.getElementById(inputId);
    errorMessage.classList.remove("phone-error");
    errorMessage.classList.add("phone-error-hidden");
    input.classList.remove("phone-input-error");
  };

  let initializePhoneInput = function (input, defaultCountry) {
    return window.intlTelInput(input, {
      utilsScript: "https://unpkg.com/intl-tel-input@17.0.18/build/js/utils.js",
      autoHideDialCode: false,
      separateDialCode: true,
      initialCountry: defaultCountry,
      preferredCountries: [],
    });
  };

  let setPhoneInputEventListeners = function (inputId, input, iti) {
    input.addEventListener("blur", function () {
      let errorMessage = document.getElementById("error-msg-" + inputId);
      if (input.value.trim()) {
        if (iti.isValidNumber()) {
          iti.setNumber(iti.getNumber());
          if (errorMessage.classList.contains("phone-error")) {
            hidePhoneInputError(inputId);
          }
        } else {
          showPhoneInputError(inputId);
        }
      } else {
        if (errorMessage.classList.contains("phone-error")) {
          hidePhoneInputError(inputId);
        }
      }
    });

    input.addEventListener("countrychange", function () {
      iti.setNumber("");
    });

    input.addEventListener("keydown", function (e) {
      let charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 8) {
        e.preventDefault();
      }
    });
  };

  let showPhoneInputError = function (inputId) {
    let errorMessage = document.getElementById("error-msg-" + inputId);
    let input = document.getElementById(inputId);
    errorMessage.classList.add("phone-error");
    errorMessage.classList.remove("phone-error-hidden");
    input.classList.add("phone-input-error");
  };

  // runnning condtion
  // window["recaptcha_callback"] = function () {
  //   // Get all recaptchas in the DOM (there may be more than one form on the page).
  //   let recaptchas = document.getElementsByClassName("g-recaptcha");
  //   for (let i = 0; i < recaptchas.length; i++) {
  //     // Set the recaptcha element ID, so the recaptcha can be applied to each element.
  //     let recaptcha_id = "recaptcha_" + i;
  //     recaptchas[i].id = recaptcha_id;
  //     let el = document.getElementById(recaptcha_id);
  //     if (el != null && !el.hasAttribute("data-rendered")) {
  //       let sitekey = el.getAttribute("data-sitekey");
  //       let stoken = el.getAttribute("data-stoken");
  //       window.grecaptcha?.render(recaptcha_id, {
  //         sitekey: sitekey,
  //         stoken: stoken,
  //         // callback: function () {
  //         //   // Update the variable when reCAPTCHA is checked
  //         //   isRecaptchaChecked = true;
  //         //   // Render images here
  //         //   renderImages();
  //         // },
  //       });
  //       // Set an attribute to mark that reCAPTCHA has been rendered on this element.
  //       el.setAttribute("data-rendered", "true");
  //     }
  //   }
  // };

  function renderImages() {
    // Code to render images goes here
    // For example:
    let images = document.querySelectorAll(".your-image-selector");
    images.forEach((image) => {
      // Render each image as needed
      // For example:
      image.style.display = "block"; // or any other rendering logic
    });
  }

  // loadScript(
  //   "https://www.google.com/recaptcha/api.js?onload=recaptcha_callback&render=explicit"
  // );
  let _form_serialize = function (form) {
    if (!form || form.nodeName !== "FORM") {
      return;
    }
    let i,
      j,
      q = [];
    for (i = 0; i < form.elements.length; i++) {
      if (form.elements[i].name === "") {
        continue;
      }
      switch (form.elements[i].nodeName) {
        case "INPUT":
          switch (form.elements[i].type) {
            case "tel":
              q.push(
                form.elements[i].name +
                  "=" +
                  encodeURIComponent(
                    form.elements[i].previousSibling.querySelector(
                      "div.iti__selected-dial-code"
                    ).innerText
                  ) +
                  encodeURIComponent(" ") +
                  encodeURIComponent(form.elements[i].value)
              );
              break;
            case "text":
            case "number":
            case "date":
            case "time":
            case "hidden":
            case "password":
            case "button":
            case "reset":
            case "submit":
              q.push(
                form.elements[i].name +
                  "=" +
                  encodeURIComponent(form.elements[i].value)
              );
              break;
            case "checkbox":
            case "radio":
              if (form.elements[i].checked) {
                q.push(
                  form.elements[i].name +
                    "=" +
                    encodeURIComponent(form.elements[i].value)
                );
              }
              break;
            case "file":
              break;
          }
          break;
        case "TEXTAREA":
          q.push(
            form.elements[i].name +
              "=" +
              encodeURIComponent(form.elements[i].value)
          );
          break;
        case "SELECT":
          switch (form.elements[i].type) {
            case "select-one":
              q.push(
                form.elements[i].name +
                  "=" +
                  encodeURIComponent(form.elements[i].value)
              );
              break;
            case "select-multiple":
              for (j = 0; j < form.elements[i].options.length; j++) {
                if (form.elements[i].options[j].selected) {
                  q.push(
                    form.elements[i].name +
                      "=" +
                      encodeURIComponent(form.elements[i].options[j].value)
                  );
                }
              }
              break;
          }
          break;
        case "BUTTON":
          switch (form.elements[i].type) {
            case "reset":
            case "submit":
            case "button":
              q.push(
                form.elements[i].name +
                  "=" +
                  encodeURIComponent(form.elements[i].value)
              );
              break;
          }
          break;
      }
    }
    return q.join("&");
  };

  const onSub = (e) => {
    const formSupportsPost = true;
    // let contactUsForm = document.getElementById("_form_26_");
    if (validate_form()) {
      // if (!isRecaptchaChecked) {
      //   return (document.getElementById("error-captcha").innerHTML =
      //     "Captcha required");
      // } else {
      // }
      let submitButton = e.target.querySelector("#_form_26_submit");

      submitButton.disabled = true;
      submitButton.classList.add("processing");

      let serialized = _form_serialize(
        document.getElementById("_form_26_")
      ).replace(/%0A/g, "\\n");

      let err = form_to_submit.querySelector("._form_error");
      // err ? err.parentNode.removeChild(err) : false;
      err && err.parentNode.removeChild(err);

      async function submitForm() {
        let formData = new FormData();
        const searchParams = new URLSearchParams(serialized);

        searchParams.forEach((value, key) => {
          formData.append(key, value);
        });

        const response = await fetch(
          "https://account-direct.activehosted.com/proc.php?jsonp=true",
          {
            headers: {
              Accept: "application/json",
            },
            body: formData,
            method: "POST",
          }
        );
        const responseData = await response.json();
        _show_thank_you(
          responseData.data.uniqueId,
          responseData.data.thankYou,
          responseData.data.prismUrl,
          responseData.data.email
        );

        return responseData;
      }
      if (formSupportsPost) {
        setLoading(true);
        submitForm().then((data) => {
          eval(data.js);
        });
      } else {
        loadScript(
          "https://account-direct.activehosted.com/proc.php?" +
            serialized +
            "&jsonp=true",
          null,
          true
        );
      }

      return false;
      // use this trick to get the submit button & disable it using plain javascript
    }
  };
  const contactInfo = [
    {
      icon: mail, // Use an appropriate icon or image for email
      label: "support@skytax.co.uk",
      type: "email",
      iconHeight: "19px",
      iconWidth: "26px",
    },
    {
      icon: phone, // Use an appropriate icon or image for phone
      label: "Support:",
      numbers: ["0333 5775 332", "0207 1831550"],
      type: "phone",
      iconHeight: "28px",
      iconWidth: "28px",
    },
    {
      icon: address, // Use an appropriate icon or image for location
      label: "37th Floor, One Canada Square Canary Wharf, London E14 5AA",
      type: "address",
      iconHeight: "35px",
      iconWidth: "26px",
    },
  ];

  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className={styles.contactCont}>
          <Col className="col-md-6" xs={12}>
            <div id={id} className={` ${styles.leftSide}`}>
              <div className={` ${styles.content}`}>
                <h6>{heading}</h6>
                <h2>{subheading}</h2>
                <p>{headingOne}</p>
                <p>{headingTwo}</p>
                <p>{headingthree}</p>
                <div>
                  {contactInfo.map((item, index) => (
                    <div key={index} className={styles.contactItem}>
                      <img
                        src={item.icon}
                        style={{
                          height: item.iconHeight,
                          width: item.iconWidth,
                        }}
                      />
                      {/* <span>{item.label}</span> */}
                      {item.type === "email" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            (window.location.href = `mailto:${item.label}`)
                          }
                        >
                          {item.label}
                        </span>
                      )}
                      {item.type === "phone" && (
                        <span>
                          {item.numbers.map((number, idx) => (
                            <React.Fragment key={idx}>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  (window.location.href = `tel:${number}`)
                                }
                              >
                                {number}
                              </span>
                              {idx < item.numbers.length - 1 && " & "}
                            </React.Fragment>
                          ))}
                        </span>
                      )}
                      {item.type === "address" && <span>{item.label}</span>}
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.imgWhite}>
                <img src={whiteLine} alt="Line White" />
              </div>
            </div>
          </Col>
          <Col className="col-md-6" xs={12}>
            <div className={styles.formBG}>
              <div className={` ${styles.rightSide}`}>
                <div className={styles.form}>
                  <form
                    method="POST"
                    action="https://account-direct.activehosted.com/proc.php"
                    id="_form_26_"
                    onSubmit={(event) => {
                      event.preventDefault();
                      onSub(event);
                    }}
                    // className="_form _form_26 _inline-form  _dark"
                    className={`_form _form_26 _inline-form  _dark ${styles.formWrapper}`}
                    noValidate
                    data-styles-version="4"
                  >
                    <input type="hidden" name="u" value="26" />
                    <input type="hidden" name="f" value="26" />
                    <input type="hidden" name="s" />
                    <input type="hidden" name="c" value="0" />
                    <input type="hidden" name="m" value="0" />
                    <input type="hidden" name="act" value="sub" />
                    <input type="hidden" name="v" value="2" />
                    <input
                      type="hidden"
                      name="or"
                      value="daaaf91e26d271d4bde5d8962d689e14"
                    />
                    <div className="_form-content row">
                      {/* <div className="_form_element _x37871302 _full_width _clear" >
<div className="_form-title">
Request a free consultation
</div>
</div> */}
                      <div className="_form_element _x04502106 _full_width _clear">
                        <div className="_html-code ">
                          <div className={styles.contactHeadingsContainer}> 
                          <h2 className={styles.formHeading}>
                            We’d love to hear you from you!
                            {/* Form boxes (title/in-box words): */}
                          </h2>
                          <h3 className={styles.formHeading}>
                            <b>Lets get in touch.</b>
                          </h3>
                          <div  className={styles.headImage}>
                          <img
                            src={newContact}
                 
                          />
                          </div>
                        </div>
                        </div>
                      </div>
                      <Row className="mt-3">
                        <Col className="col-md-6 mt-2" xs={12}>
                          <div
                            className={`form-group ${styles.formGroup} ${styles.inputs}`}
                          >
                            <div className="_form_element _x27682510 _full_width ">
                              <label
                                htmlFor="firstname"
                                className={styles.formLabels}
                              >
                                First name
                              </label>
                              <div className="_field-wrapper">
                                <input
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  className={styles.inputField}
                                  placeholder="First name"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-md-6 mt-2" xs={12}>
                          <div
                            className={`form-group ${styles.formGroup} ${styles.inputs}`}
                          >
                            <div className="_form_element _x27682510 _full_width ">
                              <label
                                htmlFor="lastname"
                                className={styles.formLabels}
                              >
                                Last name
                              </label>
                              <div className="_field-wrapper">
                                <input
                                  className={styles.inputField}
                                  type="text"
                                  id="lastname"
                                  name="lastname"
                                  placeholder="Last name"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-md-6" xs={12}>
                          <div
                            className={`form-group ${styles.formGroup} ${styles.inputs}`}
                          >
                            <div className="_form_element _x96447398 _full_width ">
                              <label
                                htmlFor="email"
                                className={styles.formLabels}
                              >
                                Email address*
                              </label>
                              <div className="_field-wrapper">
                                <input
                                  className={styles.inputField}
                                  type="text"
                                  id="email"
                                  name="email"
                                  placeholder="Email address"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-md-6" xs={12}>
                          <div
                            className={`form-group ${styles.formGroup} ${styles.inputs}`}
                          >
                            <div className="_form_element _x45741269 _full_width ">
                              <label
                                htmlFor="phone"
                                className={styles.formLabels}
                              >
                                Phone number
                              </label>
                              <div className="_field-wrapper">
                                <input
                                  className={styles.inputField}
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  placeholder="Phone number"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>

                        {/* <Col className="col-md-6" xs={12}>
                        <div
                          className={`form-group ${styles.formGroup} ${styles.inputs}`}
                        >
                          <div className="_form_element _x34682840 _full_width ">
                            <label htmlFor="field[6]" className="_form-label">
                              Business type
                            </label>
                            <div className="_field-wrapper">
                              <input
                                type="text"
                                id="field[6]"
                                name="field[6]"
                                placeholder="Business type"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col className="col-md-6" xs={12}>
                        <div
                          className={`form-group ${styles.formGroup} ${styles.inputs}`}
                        >
                          <div className="_form_element _x91953366 _full_width ">
                            <label htmlFor="field[7]" className="_form-label">
                              Turnover
                            </label>
                            <div className="_field-wrapper">
                              <input
                                type="text"
                                id="field[7]"
                                name="field[7]"
                                placeholder="Turnover"
                              />
                            </div>
                          </div>
                        </div>
                      </Col> */}

                        <Col className="col-md-6" xs={12}>
                          <div className="_form_element _x84298601 _full_width ">
                            <label
                              for="field[11]"
                              className={styles.formLabels}
                            >
                              Business type
                            </label>
                            <div className="_field-wrapper">
                              <select
                                className={styles.inputField}
                                name="field[11]"
                                id="field[11]"
                              >
                                <option selected>Business type</option>
                                <option value="Sole Prop">Sole Prop</option>
                                <option value="Limited Company">
                                  Limited Company
                                </option>
                                <option value="Limited Liability Partnership">
                                  Limited Liability Partnership
                                </option>
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col className="col-md-6" xs={12}>
                          <div className="_form_element _x69331198 _full_width ">
                            <label
                              for="field[12]"
                              className={styles.formLabels}
                            >
                              Turnover
                            </label>
                            <div className="_field-wrapper">
                              <select
                                className={styles.inputField}
                                name="field[12]"
                                id="field[12]"
                              >
                                <option selected> Turnover</option>
                                <option value="£0 to £20,000">
                                  £0 to £20,000
                                </option>
                                <option value="£20,001 to £85,000">
                                  £20,001 to £85,000
                                </option>
                                <option value="£85,001 to £200,000">
                                  £85,001 to £200,000
                                </option>
                                <option value="£200,001 to £300,000">
                                  £200,001 to £300,000
                                </option>
                                <option value="£300,001 to £400,000">
                                  £300,001 to £400,000
                                </option>
                                <option value="£400,001 to £500,000">
                                  £400,001 to £500,000
                                </option>
                                <option value="£500,001 to £600,000">
                                  £500,001 to £600,000
                                </option>
                                <option value="£600,001 to £700,000">
                                  £600,001 to £700,000
                                </option>
                                <option value="£700,001 to £800,000">
                                  £700,001 to £800,000
                                </option>
                                <option value="£800,001 to £900,000">
                                  £800,001 to £900,000
                                </option>
                                <option value="£900,001 to £1,000,000">
                                  £900,001 to £1,000,000
                                </option>
                                <option value="£1,000,000+">£1,000,000+</option>
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col className="col-md-12" xs={12}>
                          <div
                            className={`form-group ${styles.formGroup} ${styles.inputsRadio}`}
                          >
                            <h4>
                              Would you like to add in the following services?{" "}
                            </h4>
                            <div className="_form_element _x45875277 _full_width ">
                              <fieldset className="_form-fieldset">
                                <label
                                  className={`_form-label ${styles.color_label}`}
                                >
                                  VAT Returns
                                </label>
                                <div
                                  className={`_row _checkbox-radio ${styles.FormChecks}`}
                                >
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_8Yes"
                                      type="radio"
                                      name="field[8]"
                                      value="Yes"
                                    />
                                    <span>
                                      <label htmlFor="field_8Yes">
                                        Yes please
                                      </label>
                                    </span>
                                  </div>
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_8No"
                                      type="radio"
                                      name="field[8]"
                                      value="No"
                                    />
                                    <span>
                                      <label htmlFor="field_8No">
                                        No thanks
                                      </label>
                                    </span>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </Col>

                        <Col className="col-md-12" xs={12}>
                          <div
                            className={`form-group ${styles.formGroup} ${styles.inputsRadio}`}
                          >
                            <div className="_form_element _x68492707 _full_width ">
                              <fieldset className="_form-fieldset">
                                <label
                                  className={`_form-label ${styles.color_label}`}
                                >
                                  Payroll
                                </label>
                                <div
                                  className={`_row _checkbox-radio ${styles.FormChecks}`}
                                >
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_9Weekly"
                                      type="radio"
                                      name="field[9]"
                                      value="Weekly"
                                    />
                                    <span>
                                      <label htmlFor="field_9Weekly">
                                        Weekly
                                      </label>
                                    </span>
                                  </div>
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_9Fortnightly"
                                      type="radio"
                                      name="field[9]"
                                      value="Fortnightly"
                                    />
                                    <span>
                                      <label htmlFor="field_9Fortnightly">
                                        Fortnightly
                                      </label>
                                    </span>
                                  </div>
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_9Monthly"
                                      type="radio"
                                      name="field[9]"
                                      value="Monthly"
                                    />
                                    <span>
                                      <label htmlFor="field_9Monthly">
                                        Monthly
                                      </label>
                                    </span>
                                  </div>
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_9No"
                                      type="radio"
                                      name="field[9]"
                                      value="No"
                                    />
                                    <span>
                                      <label htmlFor="field_9No">
                                        No thanks
                                      </label>
                                    </span>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </Col>

                        <Col className="col-md-12" xs={12}>
                          <div
                            className={`form-group ${styles.formGroup} ${styles.inputsRadio}`}
                          >
                            <div className="_form_element _x50216171 _full_width ">
                              <fieldset className="_form-fieldset">
                                <label
                                  className={`_form-label ${styles.color_label}`}
                                >
                                  Bookkeeping
                                </label>
                                <div
                                  className={`_row _checkbox-radio ${styles.FormChecks}`}
                                >
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_10Yes"
                                      type="radio"
                                      name="field[10]"
                                      value="Yes"
                                    />
                                    <span>
                                      <label htmlFor="field_10Yes">
                                        Yes please
                                      </label>
                                    </span>
                                  </div>
                                  <div className="d-flex comanbox">
                                    <input
                                      id="field_10No"
                                      type="radio"
                                      name="field[10]"
                                      value="No"
                                    />
                                    <span>
                                      <label htmlFor="field_10No">
                                        No thanks
                                      </label>
                                    </span>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {/* <div className="_form_element _x88428943 _full_width "> */}
                      {/* <label for="ls" className="_form-label">
                        Please verify your request*
                      </label> */}
                      {/* <div
                        className="g-recaptcha"
                        data-sitekey="6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go"
                      ></div>
                      {!isRecaptchaChecked && (
                        <span
                          style={{ color: "red" }}
                          id="error-captcha"
                        ></span>
                      )}
                    </div> */}
                      <div className="_button-wrapper _full_width hoverBtn">
                        <button
                          id="_form_26_submit"
                          className="_submit mt-4"
                          type="submit"
                          disabled={loading}
                        >
                          {/* Call to action */}
                          {/* {loading ? 'Loading...' : 'Call to action'} */}
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Call to action
                            </>
                          ) : (
                            "   Call to action"
                          )}
                        </button>
                      </div>

                      <Col className="text-center">
                        <p className={styles.privacy}>
                          We will never, ever share your details.
                          <br />
                        </p>
                        <p className={styles.policy}>
                          Please see our Privacy Policy
                          {location?.pathname === "/" ||
                          location?.pathname === "/services/accounts"
                            ? "for more information."
                            : "."}
                        </p>
                        
                      </Col>

                      <div className="_clear-element"></div>
                    </div>
                    <div
                      className="_form-thank-you"
                      style={{ display: "none" }}
                    ></div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewQuote;
