import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import styles from "./Dashboard.module.scss";
import { BsPencilSquare } from "react-icons/bs";
import { TbCategory2 } from "react-icons/tb";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { ToastContainer, toast } from 'react-toastify';

interface ApiResponse {
  total_blogs: string;
  total_categories: string;
  // Add any other properties you expect in the API response
}

function Dashboard() {

  const [allCounts, setAllCounts] = useState<ApiResponse | null>(null);
  const [dashboardCountApi, {  response: apiResponse, error: apiError }] = useFetch(
    `/v1/dashboard/count`,
    {
      method: "post",
    }
  );
  useEffect(() => {
    dashboardCountApi();
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (apiResponse) {
      setAllCounts(apiResponse.data);
    }else if (apiError) {
      // Show toast with error message
      toast.error("Failed to fetch data from the API");
    }

  }, [apiResponse, apiError]);



  const Cards = [
    {
      total: allCounts?.total_blogs || "",
      heading: "Total Blogs",
      icon: <BsPencilSquare />,
      link: "/admin/blog",
    },
    {
      total: allCounts?.total_categories || "",
      heading: "Total Categories",
      icon: <TbCategory2 />,
      link: "/admin/category",
    },
  ];


  return (
    <DashboardLayout>
         <ToastContainer />
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            {Cards?.map((items, index) => (
              <Col xxl={3} xl={6} key={index}>
                <Link to={items?.link}>
                  <div className={styles.dash_card}>
                    <div className={styles.dash_cardImg}>{items.icon}</div>
                    <div className={styles.dash_cardContent}>
                      <h3>{items.total}</h3>
                      <h5>{items.heading}</h5>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
}
export default Dashboard;
