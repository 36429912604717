import React from "react";
import styles from "./whoDoWe.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LinkProps, useNavigate } from "react-router-dom";
import { JsxElement } from "typescript";
import { string } from "yup";

export interface DataItem {
  title: string;
  price: JSX.Element | string;
  description: string;
  buttonText: string;
  onclick?: React.ReactElement;
  logo: string; // Assuming logo is a URL
}

interface WhoDoWeProps {
  data: DataItem[]; // Use the defined interface for the data prop
  heading: string;
  subHeading: string;
  type?: string;
  innerText?: string;
}
const WhoDoWe: React.FC<WhoDoWeProps> = ({
  heading,
  subHeading,
  data,
  type,

  innerText,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-5" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
              <p>{subHeading}</p>
            </div>
          </Col>

          {data.map((item, index) => (
            <Col
              key={index}
              //  className="col-md-4"
              className={data.length === 1 ? "col-md-12" : "col-md-4"}
              xs={12}
            >
              <div
                className={
                  type === "vatreturn" ||
                  type === "book" ||
                  type === "payroll" ||
                  type === "cis"
                    ? `text-center ${styles.box}  `
                    : `text-center ${styles.box}  hoverDivWhite`
                }
              >
                <div className={styles.boxImg}>
                  <img
                    src={item.logo}
                    alt={`Logo ${index}`}
                    width={140}
                    height={127}
                  />
                </div>
                <div className={`${styles.boxText} hoverBtn`}>
                  <h2>{item.title}</h2>
                  <h3>{item.price}</h3>
                  <p>{item.description}</p>
                  {item?.onclick?.props?.href ? (
                    <a href={item?.onclick?.props?.href}>
                      <Button>{item.buttonText}</Button>
                    </a>
                  ) : (
                    <Button
                      onClick={() => {
                        navigate(item?.onclick?.props?.to);
                      }}
                    >
                      {item.buttonText}
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          ))}

          {type === "account" ? (
            <></>
          ) : (
            <>
              {" "}
              <Col className="col-md-12">
                <h5 className={styles.weAlso}>{innerText}</h5>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default WhoDoWe;
