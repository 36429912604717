import React, { useEffect, useRef } from "react";
import styles from "./vatReturns.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/services/vatbanner.svg";
import companies from "../../../../assests/homePage/companies.svg";
import companies1 from "../../../../assests/homePage/companies1.svg";
import companiesnew from "../../../../assests/homePage/book.svg";
import companies2 from "../../../../assests/homePage/companies2.svg";
import box from "../../../../assests/homePage/location.svg";
import dollarImg from "../../../../assests/homePage/dollarImg.svg";
import box1 from "../../../../assests/homePage/user.svg";
import box2 from "../../../../assests/homePage/Medium.svg";
import slide2 from "../../../../assests/homePage/slide2.svg";
import ship from "../../../../assests/homePage/sole.svg";
import ship1 from "../../../../assests/homePage/ship5.svg";
import ship2 from "../../../../assests/homePage/ship2.svg";
import ship4 from "../../../../assests/homePage/bags.svg";
import user from "../../../../assests/homePage/user.svg";
import whiteLineGray from "../../../../assests/services/whiteLineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import box3 from "../../../../assests/homePage/box.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import WhoDoWe from "../../../../components/webapp/whoDoWe";
import HiBooks from "../../../../components/webapp/hiBooks";
import { Link } from "react-router-dom";

const data1 = [
  {
    title: "Fully MTD compliant",
    description: "Stay safe: Always fully compliant with MTD and VAT law.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Don’t pay more than you need",
    description:
      "We’ll review and optimise your VAT situation to minimise your payments.",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "We’re on your side ",
    description:
      "We’ll liaise with HMRC on your behalf for any queries or problems.",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "Accurate, timely VAT Returns",
    description: "Quarterly, monthly, any VAT Scheme  - we handle it all.",
    logo: slide2,
    lineBar: lineBar,
  },

  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Bookkeeping",
    description:
      "We’ll handle the hard work of paperwork, leaving you free to work on your business",
    buttonText: "View Details",
    onclick: <Link to="/services/bookKeeping" />,
    logo: companies2,
  },
  {
    title: "Payroll",
    description: "Paying your people the right amount at the right time.",
    buttonText: "View Details",
    onclick: <Link to="/services/payroll" />,
    logo: companiesnew,
  },
  {
    title: "Accounts",
    description:
      "Our comprehensive and flexible range of professional accounts gives you the time and insight to manage your business even better.",
    buttonText: "View Details",
    onclick: <Link to="/services/accounts" />,
    logo: box3,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Preparation",
    description:
      "We’ll give you a heads up when your VAT return is due, and work with you to get your records ready.",
    logo: ship,
    blueLine: whiteLineGray,
    count: "1",
  },
  {
    title: "Completion",
    description:
      "Your dedicated Skytax  accountant will prepare your accounts punctually and professionally for your approval. ",
    logo: ship1,
    blueLine: whiteLineGray,
    count: "2",
  },
  {
    title: "Submission",
    description:
      "Once approved, we’ll submit your accounts to HMRC on your behalf.",
    logo: ship4,
    blueLine: whiteLineGray,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  {
    title: "Matthew ",
    subHead: "Saroia Staffing Services Ltd ",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  {
    title: "Gordon Whitings",
    subHead: "",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  // Add more data objects as needed
];

const dataBooks = [
  {
    title: "Making VAT Easy",
    description:
      "Prefer peace of mind to penalties? So do we! Expect accurate and timely and VAT Returns, fully MTD compliant, always.",
    logo: companies1,
  },
  {
    title: "Making VAT Better",
    description:
      "Don’t pay a penny more than you need. We’ll optimise your business for VAT by assessing that you’re on the right VAT scheme for your business, and are recovering all your allowable costs.  ",
    logo: ship2,
  },
  {
    title: "Solving VAT Problems",
    description:
      "HMRC asking questions? No need to stress out, because we’ve got this. We’ll act on your behalf, representing you to HMRC and work to resolve any issue quickly and expertly.",
    logo: companies,
  },
  // Add more data objects as needed
];

const data = [
  {
    title: "VAT Returns",
    price: "From £15 per month",
    description:
      " Quarterly or monthly. Any VAT scheme. Every industry. We’ve got you covered. ",
    buttonText: "Get started",
    onclick: <a href="#vat" />,
    logo: dollarImg,
  },

  // Add more data objects as needed
];

const VatReturns = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const whoDoWeRef = useRef<HTMLDivElement>(null);

  const handleFindOutClick = () => {
    if (whoDoWeRef.current) {
      whoDoWeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>VAT from A to Z</h6>
                      <h1>VAT Returns</h1>
                      <p>
                        Struggling with your VAT Return? We’ve got you covered
                        with our expert no-stress VAT Return service.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        <Button onClick={handleFindOutClick}>
                          Find out more
                        </Button>
                        <Link to="/about-us/contact-us">
                          <Button className={styles.actionButton}>
                            Get started
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div ref={whoDoWeRef} className={styles.WhoDoWe}>
          <Services
            data={data1}
            heading="What you get"
            subHeading="Save time. Get peace of mind. Error-free VAT Returns prepared, completed and submitted on time, every time."
            text={""}
            imageSrc={""}
            type="ServicesAccount" // imageSrc={imageSrc}
            // text=" We will never tie you in to a long-term contract. You are free
            // to leave whenever you want, we're just confident you
            // won't want to!"
          />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="How it works"
            subHeading="Forget fines. Forget penalties, Forget VAT-induced neuralgia. We’ll manage your VAT returns, from prep to submission and everything in between."
            type="accounts"
          />
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            type="vatreturn"
            heading="What it costs"
            subHeading="Our comprehensive VAT Return service includes optimising, preparing and submitting your VAT Return. "
            innerText="We keep costs low for every client, but if your VAT situation is super-complex, it may cost you a tad more - but we’ll always agree up front. No-one likes surprises!"
          />
        </div>

        <div className={styles.HiBooks}>
          <HiBooks
            dataBooks={dataBooks}
            heading="Why choose us?"
            subHeading="We’re professional. We’re proactive. We’re on your side. "
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} type="reviweBg" />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Need other accountancy and business services? No problem. We’re here to help. "
            subHeading="Don’t limit yourself. Choose from a tried, tested and trusted range of expert services that make life easier and business better. "
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            id="vat"
            heading="Great support for your business, fast."
            subheading="Get a quote for our VAT Return Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our VAT Return  services now. Simply complete and submit the form."
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default VatReturns;
