import React, { useEffect } from "react";
import styles from "./whowehelp.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import companieBanner from "../../../../assests/homePage/companiesbannernew.png";
import companies from "../../../../assests/homePage/companies.svg";
import companies1 from "../../../../assests/homePage/companies1.svg";
import companies2 from "../../../../assests/homePage/companies2.svg";
import box from "../../../../assests/homePage/new1.svg";
import box1 from "../../../../assests/homePage/new2.svg";
import WhyChooseImage from "../../../../assests/homePage/whychooseus.jpg";
import WhoWeHelp from "../../../../assests/homePage/whoWeHelp.jpg";
import servicesBox1 from "../../../../assests/homePage/newService1.png";
import servicesBox2 from "../../../../assests/homePage/newService2.png";
import servicesBox3 from "../../../../assests/homePage/newService3.png";
import service4 from "../../../../assests/homePage/service4.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import service5 from "../../../../assests/homePage/service5.svg";
import service6 from "../../../../assests/homePage/service6.svg";
import ship2 from "../../../../assests/homePage/ship2.svg";
import phoneDollar from "../../../../assests/homePage/phoneDollar.svg";
import piggyBank from "../../../../assests/homePage/piggyBank.svg";
import personThinking from "../../../../assests/homePage/personThinking.png";
import userPhone from "../../../../assests/homePage/userPhone.png";
import userMeditation from "../../../../assests/homePage/userMeditation.png";
import ship4 from "../../../../assests/homePage/ship4.svg";
import ship5 from "../../../../assests/homePage/ship5.svg";
import user from "../../../../assests/homePage/userLogo.png";
import blueLine from "../../../../assests/homePage/lineBlue.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import whiteSmallCircle from "../../../../assests/homePage/circleWhoteSmall.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import services1 from "../../../../assests/homePage/services1.png";
import services2 from "../../../../assests/homePage/services2.png";
import services3 from "../../../../assests/homePage/services3.png";
import book from "../../../../assests/homePage/newbook.png";
import download from "../../../../assests/homePage/newdownload.png";
import imageSrc from "../../../../assests/homePage/dollar.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import IncludeSection from "../../../../components/webapp/includeSection";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import { Link, useNavigate } from "react-router-dom";
import NewReviewSection from "../../../../components/webapp/newReviewSection";
import WhyChooseUS from "../../../../components/webapp/whychooseus";
import HiBooksFeature from "../../../../components/webapp/hibooksServiceFeature";
import hibooks1 from "../../../../assests/homePage/hibooks1.svg";
import hibooks2 from "../../../../assests/homePage/hibooks2.svg";
import hibooks3 from "../../../../assests/homePage/hibooks3.svg";
import hibooks4 from "../../../../assests/homePage/hibooks4.svg";
import hibooks5 from "../../../../assests/homePage/hibooks5.svg";
import hibooks6 from "../../../../assests/homePage/hibooks6.svg";
import serviceFeature1 from "../../../assests/homePage/serviceFeature1.svg";
import serviceFeature2 from "../../../assests/homePage/serviceFeature2.svg";
import serviceFeature3 from "../../../assests/homePage/serviceFeature3.svg";
import serviceFeature4 from "../../../assests/homePage/serviceFeature4.svg";
import serviceFeature5 from "../../../assests/homePage/serviceFeature5.svg";
import serviceFeature6 from "../../../assests/homePage/serviceFeature6.svg";
import Pricing from "../../../../components/webapp/pricing";
import NewFAQS from "../../../../components/newfaq";
import InfoSection from "../../../../components/infoSection";
import NewServices from "../../../../components/webapp/newServices";
import NewLimitedCompanies from "../../../../components/webapp/newLimitedCompany";
import HotPress from "../../../../components/webapp/hotPress";
import useFetch from "../../../../hooks/useFetch";
import NewBlogCard from "../../../../components/webapp/newblogcard";
import ConnectBank from "../../../../components/webapp/connectBanks";
import NewQuote from "../../../../components/webapp/newQuote/NewQuote";
const data = [
  {
    title: "Full-service accounts",
    description:
      "Statutory accounts prepared and submitted. Management accounts and a full suite of reports for the business insight you need.",
    logo: servicesBox1,
    lineBar: lineBar,
  },
  {
    title: "Free online bookkeeping software",
    description:
      "Free access to hibooks means you can ditch manual data entry for simple snap’n’go online bookkeeping - and much more!",
    logo: servicesBox2,
    lineBar: lineBar,
  },
  {
    title: "Expert tax planning",
    description:
      "Why pay more? Our tax experts will optimise your tax position to minimise your liabilities and maximise cash flow. Includes directors’ self-assessment return, too!",
    logo: servicesBox3,
    lineBar: lineBar,
  },
  {
    title: "Corporation tax",
    description:
      "No more corporation tax crises. Just fast, reliable and accurate corporation tax prep and submission.",
    logo: servicesBox2,
    lineBar: lineBar,
  },
  {
    title: "VAT Return",
    description:
      "Your VAT Returns submitted on time, always. We’re fully HMRC-approved and Making Tax Digital compliant. ",
    logo: servicesBox3,
    lineBar: lineBar,
  },
  {
    title: "Dedicated accountant",
    description:
      "We’ll never give you the run around - just consistent, expert advice and support from your own Skytax accountant.",
    logo: servicesBox1,
    lineBar: lineBar,
  },
  // Add more data objects as needed
];

const dataInclude = [
  {
    title: "Why pay more?",
    description:
      "Whether you’re new to business or an old hand, you know it’s important not to throw money away. That’s why, unlike some accounting firms,  we never push services you don’t want or need.",
    logo: services1,
    sub: "Cost effective. Flexible. Agile.",
    subp: "We’re trusted to deliver support flexibly, just where you need it. Our costing is simple, and transparent, and you’re never tied into a contract.",
  },
  {
    title: "Wave bye-bye to penalties. Say hello to peace of mind.",
    description:
      "When you’re busy running a business, it’s too easy to forget an important detail - like a financial deadline. With us, you never will. ",
    logo: services2,
    sub: "Compliant. Proactive. Finances done right.",
    subp: "We’ll always make sure your returns and accounts are submitted accurately and on time. We’ll take care of your peace of mind, while you take care of business.",
  },
  {
    title: "Get hibooks for free",
    description:
      "Imagine if you had more time for strategy. More headspace for marketing. More bandwidth for delighting your clients while still taking care of the day-to-day numbers. You can. Welcome to hibooks.",
    logo: services3,
    sub: "Welcome to hibooks.",
    subp: "Hibooks Pro is our hassle-free online bookkeeping platform that’s built to eliminate manual data tasks and streamline your finances. It’s packed with AI-driven features like smart invoicing, snap-n-go expenses, and automated real-time reporting that give you months’ of time and focus back. ",
  },
  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Business Options: A Guide",
    description:
      "Not sure which business structure is right for you? Find out here.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies,
  },
  {
    title: "Limited Companies: A Guide",
    description:
      "Stay updated with all the limited company financials that you need to know.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies1,
  },
  {
    title: "Director’s Loans: A Guide",
    description:
      "Director’s loans can be costly but our guide will help you navigate the complexities easily.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies2,
  },
  // Add more data objects as needed
];
const CompanyDataNew = [
  {
    title: "Pitch Deck Template",
    description:
      "Impress your investors with our handy pitch deck template that you can download and personalize.",
    buttonText: "Free Download",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: book,
  },
  {
    title: "Pitch Deck Template",
    description:
      "Impress your investors with our handy pitch deck template that you can download and personalize.",
    buttonText: "Free Download",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: book,
  },
  {
    title: "Pitch Deck Template",
    description:
      "Impress your investors with our handy pitch deck template that you can download and personalize.",
    buttonText: "Free Download",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: download,
  },
  // Add more data objects as needed
];
const PatnerShipdata = [
  {
    title: "Reach out to us",
    description: "Simply pop your details into our online form here.",
    logo: ship4,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Let’s talk",
    description:
      "We’ll call you for a no-obligation chat about the services you’d like. ",
    logo: ship2,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "We do the rest",
    description:
      "Once confirmed, we’ll set everything up for you - you don’t need to lift a finger!",
    logo: ship5,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];
const PartnerShipData = [
  {
    title: "Get a quote",
    description: "Get your instant online quote to find out how ",
    logo: ship4,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Register your business",
    description: "Fill out our simple online registration form. ",
    logo: phoneDollar,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "Receive a warm welcome",
    description: "We’ll get you set up, settled in and introduce you",
    logo: piggyBank,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Dr J Afridi ",
    subHead: "   --",
    description:
      "Skytax Accounting has been a game-changer for my medical practice. Their tailored approach and expertise in healthcare finances have simplified my workload. I highly recommend them to fellow doctors and healthcare professionals— their dedication and support have been invaluable.",
    logo: user,
  },
  {
    title: "Mr. Farid  ",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  {
    title: "Matthew",
    subHead: "Saroia Staffing Services Ltd",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  // Add more data objects as needed
];
const softwareServiceData = {
  softwareFeature: [
    {
      title: "Ask an Accountant",
      description:
        "Create invoices and send on the go. Send custom and unlimited invoices from your laptop, smartphone or tablet in just a few clicks.",
      logo: hibooks1, // Replace with the appropriate image/logo reference
    },
    {
      title: "VAT Submission",
      description:
        "Create invoices and send on the go. Send custom and unlimited invoices from your laptop, smartphone or tablet in just a few clicks.",
      logo: hibooks2, // Replace with the appropriate image/logo reference
    },
    {
      title: "Year End Accounts",
      description:
        "Create invoices and send on the go. Send custom and unlimited invoices from your laptop, smartphone or tablet in just a few clicks.",
      logo: hibooks3, // Replace with the appropriate image/logo reference
    },
    {
      title: "Payroll",
      description:
        "Create invoices and send on the go. Send custom and unlimited invoices from your laptop, smartphone or tablet in just a few clicks.",
      logo: hibooks4, // Replace with the appropriate image/logo reference
    },
    {
      title: "Bookkeeping",
      description:
        "Create invoices and send on the go. Send custom and unlimited invoices from your laptop, smartphone or tablet in just a few clicks.",
      logo: hibooks5, // Replace with the appropriate image/logo reference
    },
    {
      title: "Payroll",
      description:
        "Create invoices and send on the go. Send custom and unlimited invoices from your laptop, smartphone or tablet in just a few clicks.",
      logo: hibooks6, // Replace with the appropriate image/logo reference
    },
  ],
  serviceFeature: [
    // No service features are visible in the image, but you can add them similarly if needed
  ],
};
const plans = [
  {
    title: "Limited Company",
    plan: "Pro",
    originalPrice: 150,
    discountedPrice: 120,
    features: [
      "Year-end accounts included",
      "Perfect for over £40k earners",
      "Directors payroll",
      "Unlimited tax advice",
    ],
  },
  {
    title: "Limited Company",
    plan: "Premium",
    originalPrice: 150,
    discountedPrice: 120,
    features: [
      "Year-end accounts included",
      "Perfect for over £40k earners",
      "Directors payroll",
      "Unlimited tax advice",
    ],
  },
  {
    title: "Limited Company",
    plan: "Enterprise",
    originalPrice: 150,
    discountedPrice: 120,
    features: [
      "Year-end accounts included",
      "Perfect for over £40k earners",
      "Directors payroll",
      "Unlimited tax advice",
    ],
  },
];
const dataPress = [
  {
    title: "Simple",
    date: "18th July 2023",
    description:
      "Easy to use, efficient and accurate. hibooks has helped over 75,000 small business owners to become brilliant bookkeepers.",
    logo: hibooks6,
  },
  {
    title: "Tax efficiency reviews",
    date: "18th July 2023",
    description:
      "hibooks removes the stress of bookkeeping by streamlining every process, leaving you more time to focus on growing your business.",
    logo: hibooks6,
  },
  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: hibooks6,
  },

  {
    title: "Reduce the chance of penalties",
    date: "18th July 2023",
    description:
      "Live chat and email support is provided by our very own accountants and bookkeepers, so we will be guiding you every step of the way.",
    logo: hibooks6,
  },
];
const WhyChooseUsFeatures = [
  {
    description:
      "Access reliable and unlimited advice from expert accountants, whenever you need it.",
  },
  {
    description:
      "File all statutory paperwork on time and stress-free, with no penalties.",
  },
  {
    description:
      "Enjoy free access to hibooks, our intuitive accounting software built to streamline your bookkeeping.",
  },
  {
    description:
      "Lower your accounting costs compared to traditional accountants, thanks to our tech-driven solutions.",
  },
  {
    description:
      "Benefit from flexible, transparent pricing with no long-term contracts—pay only for what you need.",
  },
];
const WhoWeHelpFeatures = [
  {
    description: "Architects",
  },
  {
    description: "Contractors",
  },
  {
    description: "Creative freelancers",
  },
  {
    description: "Dentists",
  },
  {
    description: "Freelancers",
  },
  {
    description: "IT consultants",
  },
  {
    description: "Interior designers",
  },
  {
    description: "Online retailers",
  },
  {
    description: "Plumbers",
  },
  {
    description: "Small businesses",
  },
  {
    description: "Startups",
  },
  {
    description: "Switching accountants",
  },
  {
    description: "Whatever your passion is...",
  },
];
const WhoWeHelpNew = () => {
  const [blogListApi, { response: apiResponse }] = useFetch(
    `/v1/public-blog/list`,
    {
      method: "post",
    }
  );

  const allBlogs = apiResponse ? apiResponse?.data?.list : [];

  useEffect(() => {
    blogListApi();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <WebappLayout headerStyle="special">
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Smart Accounting for Limited Companies</h6>
                      <h1>You grow your business.</h1>
                      <h2>We handle the accounting.</h2>
                      <p>
                        Automate bookkeeping, stay compliant, and keep more of
                        your hard-earned profits with Skytax. Our smart software
                        and expert team make managing your company’s finances
                        hassle-free.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        {/* <Button>Call to action</Button> */}
                        <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                          className={styles.actionButton}
                        >
                          Request free Consultation
                        </Button>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={whiteSmallCircle} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={companieBanner} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>
        {/* Review Section */}
        <div className={styles.HiBooks}>
          <NewReviewSection dataReview={dataReview} height={357} />
        </div>
        {/* Why choose us section */}
        <div className={styles.HiBooks}>
          <WhyChooseUS
            buttonText="Get Price"
            img={WhyChooseImage}
            data={WhyChooseUsFeatures}
            gradientHeading="Skytax?"
            heading="Why choose"
          />
        </div>
        {/*Hii Books Services Section */}
        <div className={styles.HiBooks}>
          <HiBooksFeature
            softwareFeature={softwareServiceData.softwareFeature}
            serviceFeature={softwareServiceData.serviceFeature}
            softwareHeading="hibooks always Included"
            softwareSubHeading="You’ll get access to hibooks,our smart, powerful online accounting platform."
            serviceHeading="hibooks always included"
            serviceSubHeading="You'll get free access to hibooks, our smart, powerful online accounting platform."
          />
        </div>
        {/* Pricing */}
        <div className={styles.HiBooks}>
          <Pricing
            data={plans}
            heading="Smart Pricing, Smarter Accounting"
            subHeading="Our subscriptions make your accounting simple with our easy-to-use software and the full support of our
            experts and accountants.
            "
            innerText="We also help Contractors and LLPs."
          />
        </div>
        {/* FAQ Section */}
        <div className={styles.HiBooks}>
          <NewFAQS
            heading="Frequently asked questions"
            subHeading="Explore our FAQ's for quick answers to your questions"
          />
        </div>
        {/* InfoSection */}
        <InfoSection
          heading="Why pay more?"
          subheading="Cost effective. Flexible. Agile."
          description1="Enquire today
          Whether you’re new to business or an old hand, you know it’s important not to throw
          money away. That’s why, unlike some accounting firms, we never push services you
          don’t want or need."
          description2="We’re trusted to deliver support flexibly, just where you need it. Our costing is simple,
          and transparent, and you’re never tied into a contract."
          buttonText="Enquire today"
          imageUrl={personThinking}
          imageAlt="Person thinking with icons around them"
        />
        {/* InfoSection */}
        <InfoSection
          heading="Wave bye-bye to penalties. Say
          hello to peace of mind."
          subheading="Compliant. Proactive. Finances done right."
          description1="When you’re busy running a business, it’s too easy to forget an important detail - like a
          financial deadline. With us, you never will."
          description2="We’ll always make sure your returns and accounts are submitted accurately and on
          time. We’ll take care of your peace of mind, while you take care of business."
          buttonText="Enquire today"
          imageUrl={userMeditation}
          reverseLayout={true}
          imageAlt="Person thinking with icons around them"
        />
        {/* InfoSection */}
        <InfoSection
          heading="Get hibooks for free"
          subheading="Welcome to hibooks."
          description1="Imagine if you had more time for strategy. More headspace for marketing. More
          bandwidth for delighting your clients while still taking care of the day-to-day numbers.
          You can. Welcome to hibooks."
          description2="Hibooks Pro is our hassle-free online bookkeeping platform that’s built to eliminate
          manual data tasks and streamline your finances. It’s packed with AI-driven features like
          smart invoicing, snap-n-go expenses, and automated real-time reporting that give
          you months’ of time and focus back."
          buttonText="Enquire today"
          imageUrl={userPhone}
          imageAlt="Person thinking with icons around them"
        />
        {/* Services */}
        <div className={styles.WhoDoWe}>
          <NewServices
            data={data}
            heading="What you get?"
            imageSrc={imageSrc}
            textHead="HMRC representation"
            text=" We can directly liaise with HMRC to handle your tax affairs on your behalf – saving you time and reducing stress."
          />
        </div>
        {/* Limited Companies */}
        <div className={styles.HiBooks}>
          <NewLimitedCompanies
            CompanyData={CompanyDataNew}
            heading="Looking for more information on limited companies?"
            subHeading="We have a comprehensive range of free resources available to help your business run as smoothly."
          />
        </div>
        {/* Blogs */}
        <NewBlogCard
          allBlogs={allBlogs.slice(6)}
          dataPress={dataPress}
          heading="Explore our blog"
          subHeading="Practical solutions, expert advice and more."
        />
        {/* Who we help section */}
        <div className={styles.HiBooks}>
          <WhyChooseUS
            type="career"
            buttonText="Enquire today"
            img={WhoWeHelp}
            data={WhoWeHelpFeatures}
            heading="Who We Help"
          />
        </div>
        {/* Connect Banks */}
        <ConnectBank containerColor="#fafafa" />
        {/* PartnerShip */}
        <div className={styles.HiBooks}>
          <PatnerShip
            title1="HMRC approved & trusted"
            title2="chartered certified accounts"
            subtitle="We’ll handle your business finances competently and efficiently. Our accountants and support teams hold a diverse
            range of qualifications and accreditations from professional bodies like the AAT and ACCA."
            PatnerShipdata={PartnerShipData}
            heading="Getting started"
            subHeading="Do your own accounts? Already have an accountant but want to make the jump? Relax - we make everything easy."
          />
        </div>

        {/* <div className={styles.HiBooks}>
          <IncludeSection dataInclude={dataInclude} />
        </div> */}

        {/* <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Free downloadable business guides"
            subHeading="Everything you need to get going and growing in business"
          />
        </div> */}

        <div className={styles.HiBooksQuete}>
          <NewQuote
            heading=""
            subheading="Instant Quote"
            headingOne="Our monthly fee is carefully determined based on your specific
            business type, allowing us to provide solutions that are both tailored
            to your unique needs and cost-effective. We are committed to
            transparency and flexibility in our pricing, ensuring that you have a
            clear understanding of what you’re paying for and the ability to adjust
            services as your business evolves."
            headingTwo=""
            headingthree=" "
            id={undefined}
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default WhoWeHelpNew;
