import { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import styles from "./users.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../components/admin/ConfirmationModal/ConfirmationModal";
import { RiEdit2Line } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import useFetch from "../../../hooks/useFetch";
import { BsEye } from "react-icons/bs";
import PaginationCard from "../../../components/admin/paginations";
import { BASE_IMAGE_URL } from "../../../services/constants";

function Users() {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<any>(null);
  const LIMIT: number = 10;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteID, setDeleteID] = useState<String>("");
  const navigate = useNavigate();

  const [userListApi, {  response: apiResponse }] = useFetch(
    `/v1/user/list?start=${page}&&limit=${LIMIT}`,
    {
      method: "post",
    }
  );

  const allUsers = apiResponse ? apiResponse.data : [];

  useEffect(() => {
    setTotalRecords(allUsers?.total_records);
 // eslint-disable-next-line
  }, [apiResponse]);



  useEffect(() => {
    setPage(0);
  }, []);

  useEffect(() => {
    if (page !== null) {
      userListApi();
    }
 // eslint-disable-next-line
  }, [page]);


  const handleConfirmationToggle = () => {
    setConfirmDelete(!confirmDelete);
  };

  /**
   * handle Edit user
   */

  const handleDeleteUser = (id: any) => {
    setDeleteID(id);
    handleConfirmationToggle();
  };



  const [userDeleteApi] = useFetch(
    `/v1/user/delete/${deleteID}`,
    {
      method: "delete",

    }
  );

  const handleConfirmDelete = async (id: any) => {
    setDeleteID(id);

    try {
      const deleteResponse = await userDeleteApi();

      // Check if the HTTP status code indicates success (e.g., 200-299)
      if (deleteResponse.status >= 200 && deleteResponse.status < 300) {
        // Refresh the category list after successful deletion
        userListApi();
        console.log("User deleted successfully!");
      } else {
        console.error("Error deleting user:", deleteResponse.statusText);
      }
    } catch (error: any) {
      // Explicitly define the type of 'error' as 'any'
      console.error("Error deleting user:", error.message);
    } finally {
      setDeleteID("");
      handleConfirmationToggle();
    }
  };


  return (
    <DashboardLayout>
      <div className={` ${styles.users_page} ${styles.blog_boxs}`} >
        <div className={styles.users_addBtn}>
          <h2> User List</h2>
          <Link to="/admin/users/add">
            {" "}
            <button className="addBlogs btn btn-primary">

              + Add User
            </button>
          </Link>
        </div>
        <div className="theme_tabs">
          <div>
            <div className="theme_table">
            <Table>
                <thead>
                  <tr>
                    <th>SR.No</th>
                    {/* <th>Image</th> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers?.list ? (
                    allUsers?.list.length ? (
                      allUsers?.list.map((item: any, index: number) => {
                        const srNo = index + 1 + page * LIMIT;

                        return (
                          <tr key={index}>
                            <td>{srNo}</td>
                            <td>{item?.first_name} {item?.last_name}     </td>
                            <td>{item?.email}</td>
                            <td className={styles.action_column}>
                              <div className={styles.action_btn}>
                                <span
                                  onClick={() => {
                                    navigate(`/admin/users/details/${item.id}`, {
                                      state: { item },
                                    });
                                  }}
                                >
                                  <BsEye />
                                </span>
                                <span
                                  onClick={() => {
                                    navigate(`/admin/users/edit/${item.id}`, {
                                      state: { item },
                                    });
                                  }}
                                >
                                  <RiEdit2Line />
                                </span>
                                <span
                                  onClick={() => {
                                    handleDeleteUser(item.id);
                                  }}
                                >
                                  <MdDeleteOutline />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={100} className="text-center py-5">
                          <h4> Data not Found </h4>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan={100} className="text-center py-5">
                        <h4>
                          
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        </h4>
                      </td>
                    </tr>
                  )}






                </tbody>
              </Table>
              <ConfirmationModal
                show={confirmDelete}
                title="Are you sure you want to delete this user ?"
                handleToggle={handleConfirmationToggle}
                confirm={() => {
                  handleConfirmDelete(deleteID);
                }}
                size="md"
              />
            </div>
              {totalRecords > LIMIT && (
                <div className="d-flex justify-content-end mt-3">
                  <PaginationCard
                    itemPerPage={LIMIT}
                    totalItems={totalRecords}
                    paginate={setPage}
                    currentPage={page ?? 0}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
export default Users;
