import React, { useEffect } from "react";
import styles from "./startups.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/homePage/startupsnew.png";
import companies from "../../../../assests/homePage/box3.svg";
import companies1 from "../../../../assests/homePage/box2.svg";
import companies2 from "../../../../assests/homePage/box1.svg";
import box from "../../../../assests/homePage/cis3.svg";
import box1 from "../../../../assests/homePage/cis2.svg";
import box2 from "../../../../assests/homePage/cis1.svg";
import box3 from "../../../../assests/homePage/cis4.svg";
import box4 from "../../../../assests/homePage/cis5.svg";
import box5 from "../../../../assests/homePage/cis6.svg";
import ship from "../../../../assests/homePage/box4.svg";
import ship1 from "../../../../assests/homePage/sole.svg";
import ship2 from "../../../../assests/homePage/book.svg";
import user from "../../../../assests/homePage/user.svg";
import blueLine from "../../../../assests/homePage/lineBlue.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import imageSrc from "../../../../assests/homePage/Large.png";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import trade from "../../../../assests/blognew1.png";
import trade2 from "../../../../assests/blognew2.png";
import trade3 from "../../../../assests/blognew.png";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import IncludeSection from "../../../../components/webapp/includeSection";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import { Link, useNavigate } from "react-router-dom";

const data = [
  {
    title: "Full-service accounts",
    description:
      "Statutory accounts prepared and submitted. Management accounts and a full suite of reports for the business insight you need.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Free online bookkeeping software",
    description:
      "Free access to hibooks means you can ditch manual data entry for simple snap’n’go online bookkeeping - and there’s much more!",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Expert tax planning",
    description:
      "Why pay more? Our tax experts will optimise your tax position to minimise your liabilities and maximise cash flow.",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "VAT Return",
    description:
      "Your VAT Returns submitted on time, always. We’re fully HMRC-approved and Making Tax Digital compliant.",
    logo: box3,
    lineBar: lineBar,
  },
  {
    title: "Dedicated accountant",
    description:
      "We’ll never give you the run around - just consistent, expert advice and support from your own Skytax accountant.",
    logo: box4,
    lineBar: lineBar,
  },
  {
    title: "HMRC representation",
    description:
      "We can directly liaise with HMRC to handle your tax affairs on your behalf – saving you time and reducing stress.",
    logo: box5,
    lineBar: lineBar,
  },
  // Add more data objects as needed
];

const dataInclude = [
  {
    title: "Straightforward accounting – right from the start",
    description:
      "It’s important to get your business accounts running smoothly from the moment you start trading. ",
    logo: trade,
    sub: "Accounting solutions for startups",
    subp: "Our friendly team can help you with company formation, getting your books up and running and advice on crucial topics like cashflow management, tax planning and allowable expenses.",
    subpp:
      "As your new accountants we’ll take care of the entire changeover from your previous accountant or bookkeeper. We will start by contacting them to request all the information we need to correspond with HMRC and act on your behalf. Being a sole trader doesn’t mean you’re alone!",
  },
  {
    title: "Create your own package of services.",
    description:
      "Whether you need business setup advice, help with implementing financial systems or just want support with bookkeeping, you’re in good hands.",
    logo: trade2,
    sub: "Tailored, cost-effective support ",
    subp: "Our accounting solutions can adapt and scale in line with your startup business. Modify our support to suit your needs as they evolve.",
    subpp:
      "That’s why we offer a range of additional accounting services to sole traders, such as bookkeeping, VAT returns, payroll or pensions, ready for you to add or remove as and when you need them.",
  },
  {
    title: "Get hibooks for free",
    description:
      "Imagine if you had more time for strategy. More headspace for marketing. More bandwidth for delighting your clients while still taking care of the day-to-day numbers.  You can. Welcome to hibooks",
    logo: trade3,
    sub: "Welcome to hibooks",
    subp: "hibooks is our hassle-free, online bookkeeping platform that’s built to eliminate manual data tasks and streamline your finances. It’s packed with the AI-driven features like smart invoicing, snap-n-go expenses, and automated real-time reporting that give you months’ of time and focus back.",
    subpp:
      "To protect you from the stress of an HMRC audit or inspection, we will act on your behalf and fight your corner with HMRC, at no additional cost.",
  },
  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Business Option Guide",
    description:
      "Learn about your financial responsibilities as a business owner and how to pick the right business structure when setting up.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies,
  },
  {
    title: "Limited vs. Sole Trader Guide",
    description:
      "Understand how limited company and sole trader businesses differ in terms of structure, tax obligations and more.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies1,
  },
  {
    title: "Limited vs. Umbrella Guide",
    description:
      "Learn the pros and cons of limited and umbrella companies – important to know if you’re a contractor.",
    buttonText: "Download now",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Reach out to us",
    description: "Simply pop your details into our online form here .",
    logo: ship,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Let’s talk",
    description:
      "We’ll call you for a no-obligation chat about the services you’d like.",
    logo: ship1,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "We do the rest",
    description:
      "Once confirmed, we’ll set everything up for you - you don’t need to lift a finger!",
    logo: ship2,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd",
    description:
      "Skytax Accounting Ltd. has been an absolute asset to my property management company. Their expertise and meticulous approach to handling our finances have streamlined our bookkeeping process significantly. Their team's knowledge of the property industry is unmatched, providing tailored solutions that have truly made a difference.",
    logo: user,
  },
  {
    title: "Gordon Whitings ",
    subHead: " ",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  {
    title: "Matthew",
    subHead: "Saroia Staffing Services Ltd",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  // Add more data objects as needed
];

const Startups = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Flexible. Scaleable. Proactive.</h6>
                      {/* <h1>
                        Comprehensive accountancy <br /> services for limited{" "}
                        <br />
                        companies
                      </h1> */}
                      <h1>
                        Agile, responsive accounting services, built to serve
                        the needs of startups.{" "}
                      </h1>
                      <p>
                        Startups need to be fast, proactive and scalable. Our
                        flexible accounting services optimise your finances
                        while you grow.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        {/* <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                        >
                          Get started
                        </Button> */}
                        <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                          className={styles.actionButton}
                        >
                          Get started
                        </Button>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={styles.circlelight}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div className={`${styles.circlelight} ${styles.topLight}`}>
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.WhoDoWe}>
          <Services
            data={data}
            heading="What you get"
            subHeading="A proactive and professional accounts and tax service, with peace of mind always fitted as standard."
            imageSrc={imageSrc}
            text=" We’re as flexible as our startup clients. That’s why there’s no lengthy contracts. Just transparent pricing, a simple pay-per-month  setup and, of course, you can. cancel anytime."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} height={375} />
        </div>
        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="Getting started"
            subHeading="Get Skytax comprehensive accounting services specially created for startups in just three simple steps."
          />
        </div>

        <div className={styles.HiBooks}>
          <IncludeSection dataInclude={dataInclude} />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Explore our resources for startups"
            subHeading=""
            // subHeading="We have a comprehensive range of free resources available to help your business run as smoothly."
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            heading="Great support for your business, fast."
            subheading="Get a quote for our Startups service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="But you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="If you’re curious about how much it’ll cost, don’t wait, get a quote for our comprehensive startups services now. Simply complete and submit the form."
            id={undefined}
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default Startups;
