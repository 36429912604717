import React, { useCallback, useEffect, useState } from 'react'
import styles from "./resetPassword.module.scss"
import { Form, Formik } from 'formik';
import { Form as ReactForm } from "react-bootstrap";
import * as Yup from "yup";
import { RotatingLines } from "react-loader-spinner";
import AuthLayout from '../../../components/admin/layouts/AuthLayout';
import {  useNavigate,  useSearchParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import * as yup from "yup";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [newToken, setNewToken] = useState<string | null>(null); // Specify the type as string | null
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('token')) {
      const token = searchParams.get('token');
      setNewToken(token); // Set the newToken state
    }
  
  }, [searchParams]);


  
  const ValidateSchema = Yup.object().shape({

    new_password: yup.string()
    .min(6, "Too Short!")
    .max(50, "Too Long!")
    .required("Password is required"),
    confirm_password: yup.string()
  .required('Confirm New Password is required')
  .oneOf([yup.ref('new_password'), ''], 'Passwords must match'),
  });


  const [resetPasswordApi] = useFetch(
    `/v1/auth/reset-password`,
    {
      method: "post",
    }
  );


  const handleSubmit = useCallback(async (values) => {
  
    try {
      setLoading(true);
      const response = await resetPasswordApi({
        token: newToken, // Include the token in the payload
        password: values.new_password, // Use the new_password for the password field
      });

      setLoading(false);
      if (response.data && response.data.status === 1) {
        navigate("/admin/login");
      } else {
        console.error("API Request Error:", response?.data?.message);
      }

    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false);
    }
  }, [resetPasswordApi, navigate, newToken]);


  return (
    <>
        <AuthLayout>
     <div className={styles.login}>
            <div className={styles.login_ByGoogle}>
              <div className={styles.formTitle}>
                {/* <img src={symbolImg} alt="logo" /> */}
                <h2> Reset Password</h2>
                <p> Please enter email address linked with your account and we
                    will send a Reset password link to your email.</p>
              </div>
            </div>
            <div className={styles.formFields}>
              <Formik
                initialValues={{
                  new_password: "",
                  confirm_password: "",
                }}
                validationSchema={ValidateSchema}
                // onSubmit={(values) => {
                //   forgotCall
                // }}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form>
                    <ReactForm.Group
                      className={styles.formFieldSingle}
                      controlId="formBasicEmail"
                    >
                      <ReactForm.Label>New Password</ReactForm.Label>
                      <ReactForm.Control
                        type="password"
                        name="new_password"
                        placeholder="Enter New Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.new_password}
                      />
                      {errors.new_password && touched.new_password && (
                        <div className={styles.error}>{errors.new_password}</div>
                      )}
                    </ReactForm.Group>

                    <ReactForm.Group
                      className={styles.formFieldSingle}
                      controlId="formBasicEmail"
                    >
                      <ReactForm.Label>Confirm Password</ReactForm.Label>
                      <ReactForm.Control
                        type="password"
                        name="confirm_password"
                        placeholder="Enter Confirm Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                      />
                      {errors.confirm_password && touched.confirm_password && (
                        <div className={styles.error}>{errors.confirm_password}</div>
                      )}
                    </ReactForm.Group>
                  
                 
              <button className="btn btn-primary" type="submit">
                     Submit
                      {loading && (
                        <RotatingLines
                          strokeColor="white"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={true}
                        />
                      )}
                    </button>

                    {/* <Link to="/admin/login"> <h6 className="text-center pt-3" >
                      Back To Login
                      
                    </h6>
                    </Link> */}
                  </Form>
                )}
              </Formik>
{/* 
              <div className={styles.createRegister}>
                <span>Not Registered Yet? Contact to:-</span>
                <span className="theme_txtLink"> +91 123,464,9128</span>
              </div> */}
            </div>
          </div>
          </AuthLayout>
    </>
  )
}

export default ResetPassword