import React, { useEffect, useRef } from "react";
import styles from "./payroll.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/services/payroll.svg";
import companies from "../../../../assests/homePage/companies.svg";
import companies1 from "../../../../assests/homePage/companies1.svg";
import bags from "../../../../assests/homePage/bags.svg";
import companies2 from "../../../../assests/homePage/companies2.svg";
import box from "../../../../assests/homePage/newframe.png";
import dollarImg from "../../../../assests/homePage/dollarImg.svg";
import dollarImg4 from "../../../../assests/bookKeeping1.svg";
import box1 from "../../../../assests/homePage/frame.png";
import box2 from "../../../../assests/homePage/cis4.svg";
import slide2 from "../../../../assests/homePage/cis5.svg";
import imgLarge from "../../../../assests/homePage/Large.svg";
import book from "../../../../assests/homePage/ship4.svg";
import ship1 from "../../../../assests/homePage/box2.svg";
import user from "../../../../assests/homePage/user.svg";
import whiteLineGray from "../../../../assests/services/whiteLineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/linenew.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import box4 from "../../../../assests/homePage/ship4.svg";
import box5 from "../../../../assests/homePage/box1.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import WhoDoWe from "../../../../components/webapp/whoDoWe";
import HiBooks from "../../../../components/webapp/hiBooks";
import { Link } from "react-router-dom";

const data1 = [
  {
    title: "Reliable Reminders",
    description: "Our payroll reminder helps you stay a step ahead. ",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Payslip Prep",
    description:
      "We’ll process your people’s payslips with speed and precision.",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "RTI Submissions ",
    description:
      "We’ll handle HMRC for you - RTI submissions accurate and on time, every time. ",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "Payroll Helpdesk",
    description:
      "Payroll’s not easy and getting it wrong costs you - but we’re here to help.",
    logo: slide2,
    lineBar: lineBar,
  },

  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Accounts",
    description:
      "Our comprehensive and flexible range of professional accounts gives you the time and insight to manage your business even better.",
    buttonText: "View Details",
    onclick: <Link to="/services/accounts" />,
    logo: ship1,
  },
  {
    title: "VAT Returns",
    description:
      "Stay ahead and penalty-free with fast, accurate and on-time VAT Returns.  Fully HMRC compliant and MTD-ready.",
    buttonText: "View Details",
    onclick: <Link to="/services/vat-returns" />,
    logo: box5,
  },
  {
    title: "Tax Returns",
    description:
      "Take the trauma out of tax effortlessly. We won’t just prepare your tax return accurately and expertly, we’ll optimise your tax position to minimise what you owe. ",
    buttonText: "View Details",
    onclick: <Link to="/services/tax-returns" />,
    logo: box4,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Preparation",
    description:
      "Each pay cycle we’ll ask you for your people’s pay details and we’ll calculate all pay and deductions in line with HMRC regulations.",
    logo: book,
    blueLine: whiteLineGray,
    count: "1",
  },
  {
    title: "Payslips & Reporting",
    description:
      "We’ll create and send out your people’s payslips. And of course you’ll get a summary of how much to pay them and HMRC.",
    logo: book,
    blueLine: whiteLineGray,
    count: "2",
  },
  {
    title: "Submission",
    description:
      "We’ll prepare, create and submit your RTI return to HMRC on your behalf.",
    logo: book,
    blueLine: whiteLineGray,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd",
    description:
      "Skytax Accounting Ltd. has been an absolute asset to my property management company. Their expertise and meticulous approach to handling our finances have streamlined our bookkeeping process significantly. Their team's knowledge of the property industry is unmatched, providing tailored solutions that have truly made a difference.",
    logo: user,
  },
  {
    title: "Gordon Whitings ",
    subHead: " ",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  {
    title: "Matthew",
    subHead: "Saroia Staffing Services Ltd",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  // Add more data objects as needed
];

const dataBooks = [
  {
    title: "You’re in good hands",
    description:
      "Payroll is complex and always changing. We’ve years of experience and expertise keeping every pay cycle fuss-free and easy for you. ",
    logo: bags,
  },
  {
    title: "Think peace of mind, not penalties",
    description:
      "It’s easy to get payroll wrong - and the consequences are harsh. But you can rely on us to get it right, every time.",
    logo: dollarImg,
  },
  {
    title: "Get hours of time back",
    description:
      "Get the time and headspace to work on strategy. We’ll look after the details. ",
    logo: companies1,
  },
  // Add more data objects as needed
];

const data = [
  {
    title: "Payroll",
    price: "",
    description:
      "Accurate and reliable payroll services from as little as £5 per month.",
    buttonText: "Find out more",
    onclick: <a href="#pay" />,
    logo: dollarImg4,
  },

  // Add more data objects as needed
];

const Payroll = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const whoDoWeRef = useRef<HTMLDivElement>(null);

  const handleFindOutClick = () => {
    if (whoDoWeRef.current) {
      whoDoWeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Easy. Accurate. On Time. </h6>
                      <h1>Payroll</h1>
                      <p>
                        You take care of your people, we take care of the admin.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        <Button onClick={handleFindOutClick}>
                          Find out more
                        </Button>
                        <Link to="/about-us/contact-us">
                          <Button className={styles.actionButton}>
                            Get started
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div ref={whoDoWeRef} className={styles.WhoDoWe}>
          <Services
            data={data1}
            heading="What you get"
            subHeading="Full-service payroll administration, that keeps you, your people  - and HMRC - happy. "
            text={""}
            imageSrc={""}
            type="ServicesAccount" // imageSrc={imageSrc}
            // text=" We will never tie you in to a long-term contract. You are free
            // to leave whenever you want, we're just confident you
            // won't want to!"
          />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="How it works"
            subHeading="Take the pain out of payroll with our professional payroll services."
            type="accounts"
          />
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            type="payroll"
            heading="What it costs"
            subHeading="No-fuss, affordable payroll services that scale up or down as you do."
            innerText="*Payroll pricing varies according to your team size but it’ll always be reasonable and affordable. "
          />
        </div>

        <div className={styles.HiBooks}>
          <HiBooks
            dataBooks={dataBooks}
            heading="Why choose us?"
            subHeading="Reliable, accurate payroll at an affordable price."
            type="payroll"
            innerText="Have a workplace pension? We’ll help with that, too."
            imgLarge={imgLarge}
            lineWhite={lineWhite}
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} type="reviweBg" />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Need other accountancy and business services? No problem. We’re here to help. "
            subHeading="Don’t limit yourself. Choose from a tried, tested and trusted range of expert services that make life easier and business better. "
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            id="pay"
            heading="Great support for your business, fast."
            subheading="Get a quote for our Payroll Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our Payroll  services now. Simply complete and submit the form."
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default Payroll;
