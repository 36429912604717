import React from "react";
import styles from "./services.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import lineBar from "../../../assests/homePage/lineLongs.svg";

interface DataItem {
  title: string;
  // price: string;
  description: string;
  // buttonText: string;
  logo: string; // Assuming logo is a URL
  lineBar: string;
}

interface WhoDoWeProps {
  data: DataItem[]; // Use the defined interface for the data prop
  heading: string;
  subHeading?: string;
  text: string;
  imageSrc: string;
  type?: string;
  textHead?: string;
}
const NewServices: React.FC<WhoDoWeProps> = ({
  heading,
  subHeading,
  data,
  imageSrc,
  text,
  type,
  textHead,
}) => {
  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-2" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
            </div>
          </Col>

          {data.map((item, index) => (
            <Col
              key={index}
              className={type === "ServicesAccount" ? "col-md-3" : "col-md-4"}
              xs={12}
            >
              <div className={`text-center ${styles.box} hoverDivWhite`}>
                <div className={styles.boxImg}>
                  <img src={item.logo} alt={`Logo ${index}`} />
                </div>
                <div className={styles.boxText}>
                  <h2>{item.title}</h2>

                  <p>{item.description}</p>
                  {/* <img src={item.lineBar} alt="Logo" /> */}
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {/* {type === "ServicesAccount" ? (
          ""
        ) : (
          <>
            <Row>
              <Col className="col-md-12" xs={12}>
                <div className={styles.longterm}>
                  <img src={imageSrc} alt="Logo" />

                  <img src={lineBar} className={styles.lineWidth} alt="Logo" />
                  <h4>{textHead}</h4>
                  <p>{text}</p>
                </div>
              </Col>
            </Row>
          </>
        )} */}
      </Container>
    </div>
  );
};

export default NewServices;
