import { Formik } from "formik";
import { Form, Spinner } from "react-bootstrap";
import * as yup from "yup";
import styles from "./AddUsersForm.module.scss";
import "react-quill/dist/quill.snow.css"; // Import styles
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ToastContainer, toast } from "react-toastify";
import { BASE_IMAGE_URL } from "../../../services/constants";
import { BsEye, BsEyeSlash } from "react-icons/bs";

interface IProps {
  toggleSidebar?: any;
  editableItem?: any;
  editView?: boolean;
  setInitinalValues?: any;
  initinalValues?: any;
}

const AddUsersForm = ({
  editableItem,
  initinalValues,
  setInitinalValues,
  editView,
}: IProps) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const schema = yup.object().shape({
    first_name: yup.string().required("First name is required "),
    last_name: yup.string().required("Last name is required "),

    email: editView
      ? yup.string()
      : yup.string().required("email is required "),
    password: editView
      ? yup.string()
      : yup.string().required("Password is required "),
  });

  const [userApi] = useFetch(
    editView && editableItem?.item?.id
      ? `/v1/user/edit/${editableItem.item.id}`
      : `/v1/user/add`,
    {
      method: editView ? "post" : "post",
    }
    // "formdata"
  );

  useEffect(() => {
    if (editableItem) {
      setInitinalValues({
        first_name: editableItem?.item?.first_name,
        last_name: editableItem?.item?.last_name,
        email: editableItem?.item?.email,
        password: editableItem?.item?.password,
      });
    }
    // eslint-disable-next-line
  }, [editableItem]);

  const handleSubmit = async (values) => {
    let confirmValues = {};

    if (!editView) {
      confirmValues = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
      };
    } else {
      // Include only first_name and last_name for edit case
      confirmValues = {
        first_name: values.first_name,
        last_name: values.last_name,
      };
    }

    try {
      setLoading(true);
      // const response = await userApi();
      const response = await userApi({ ...confirmValues });
      setLoading(false);
      if (response.data && response.data.status === 1) {
        navigate("/admin/users");
      } else {
        console.error("API Request Error:", response?.data?.message);
        if (response.data && response.data.status === 0) {
          toast.error(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className={styles.blogBox}>
      <ToastContainer />
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={initinalValues}
        enableReinitialize={editView}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <>
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className={styles.formStep}>
                <h4> {editView ? "Edit User" : "Add User"} </h4>

                <div className={styles.twoFieldsWrap}>
                  <div className={styles.halfDivs}>
                    <Form.Group className={styles.singleField}>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      {errors?.first_name && touched.first_name ? (
                        <p className="error">{errors?.first_name.toString()}</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    <Form.Group className={styles.singleField}>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      {errors?.last_name && touched.last_name ? (
                        <p className="error">{errors?.last_name.toString()}</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>

                  <div
                    className={`${styles.halfDivs} ${
                      editView ? styles.newClassInEditView : styles.halfDivs
                    }`}
                  >
                    <Form.Group className={styles.singleField}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        disabled={editView}
                      />
                      {errors?.email && touched.email ? (
                        <p className="error">{errors?.email.toString()}</p>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    {!editView && (
                      <Form.Group className={styles.singleField}>
                        <Form.Label>Password</Form.Label>
                        <div className={styles.passwordEyes}>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                          {showPassword ? (
                            <BsEyeSlash
                              onClick={handleTogglePassword}
                              className="password-icon"
                            />
                          ) : (
                            <BsEye
                              onClick={handleTogglePassword}
                              className="password-icon"
                            />
                          )}
                        </div>
                        {errors?.password && touched.password ? (
                          <p className="error">{errors?.password.toString()}</p>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    )}
                  </div>

                  <button
                    className={`mb-0 ms-3 btn btn-primary ${styles.subBtn}`}
                    type="submit"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only ms-1">
                          {editView ? "Update" : "Save"}
                        </span>
                      </>
                    ) : (
                      `${editView ? "Update" : "Save"}`
                    )}
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddUsersForm;
