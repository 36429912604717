import React from "react";
import styles from "./limitedCompanies.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface DataItem {
  title: string;
  description: string;
  buttonText?: string;
  onclick?: any;
  logo: string; // Assuming logo is a URL
}

interface WhoDoWeProps {
  CompanyData: DataItem[]; // Use the defined interface for the data prop
  heading: string;
  subHeading: string;
}
const NewLimitedCompanies: React.FC<WhoDoWeProps> = ({
  heading,
  subHeading,
  CompanyData,
}) => {
  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-4" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
              <p>{subHeading}</p>
            </div>
          </Col>

          {/* <Col  className="col-md-4" xs={12}> */}
          <div className={styles.dataContainer}>
            {CompanyData.map((item, index) => (
              <div
                key={index}
                className={`text-center ${styles.box} hoverDivWhite`}
              >
                <div className={styles.boxImg}>
                  <img src={item.logo} alt={`Logo ${index}`} />
                </div>
                <div className={`${styles.boxText} hoverBtn`}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                  {item.buttonText ? (
                    <Link
                      to={
                        item?.onclick?.props?.to ? item?.onclick?.props?.to : ""
                      }
                    >
                      <Button>{item.buttonText}</Button>
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default NewLimitedCompanies;
