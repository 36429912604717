import React, { useEffect } from "react";
import styles from "./blogArticlePage.module.scss";
import blogArticle from "../../../../assests/blogArticle.svg";
import { Col, Container, Row } from "react-bootstrap";
import calenderArticle1 from "../../../../assests/calendarArticle1.svg";
import userArticle from "../../../../assests/userArticle.svg";
import clockArticle from "../../../../assests/clockArticle.svg";
import CustomBtn from "../../../../common/theme/button";
import blogArticleVideo from "../../../../assests/blogArticleVideo.svg";
import blogArticle1 from "../../../../assests/blogArticle1.svg";
import blogArticleLineGray from "../../../../assests/blogArticleLineGray.svg";
import cardImg from "../../../../assests/cardImg.svg";
import commas from "../../../../assests/commas.svg";
import horizontalLine from "../../../../assests/horizontalLine.svg";
import bottomCard from "../../../../assests/bottomCard.svg";
import HotPress from "../../../../components/webapp/hotPress";
import readMorePost from "../../../../assests/readMorePost.svg";
import AboutUsFooter from "../../../../components/webapp/footer/aboutUsFooter";
import Header from "../../../../components/webapp/header";
import useFetch from "../../../../hooks/useFetch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { BASE_IMAGE_URL } from "../../../../services/constants";
import NewslaterForm from "./newslaterForm";
import { useDispatch, useSelector } from "react-redux";
import { setCategoryId } from "../../../../redux/category";
const dataPress = [
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "18th July 2023",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.",
    logo: readMorePost,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "18th July 2023",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.",
    logo: readMorePost,
  },
  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "18th July 2023",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.",
    logo: readMorePost,
  },

  {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "18th July 2023",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.",
    logo: readMorePost,
  },

  // Add more data objects as needed
];
const BlogArticle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [blogListApi, { response: apiResponse }] = useFetch(
    `/v1/public-blog/list`,
    {
      method: "post",
    }
  );

  const allBlogs = apiResponse ? apiResponse?.data?.list.slice(0, 4) : [];

  useEffect(() => {
    blogListApi();
  }, []);

  const [blogDetailApi, { response: apiDetailsResponse }] = useFetch(
    `/v1/public-blog/detail/${id}`,
    {
      method: "post",
    }
  );

  const allDetailsBlogs = apiDetailsResponse ? apiDetailsResponse?.data : [];

  useEffect(() => {
    blogDetailApi();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      <Header />
      <Container>
        <div className={styles.blogArticleContainer}>
          <div className={styles.image}>
            {/* <img src={blogArticle} alt="" /> */}
            <img src={`${BASE_IMAGE_URL}${allDetailsBlogs?.image}`} alt="" />
          </div>
          <div className={styles.registerContent}>
            <h1>
              {/* How Do I Register A Business <br />
              Partnership? */}
              {allDetailsBlogs?.title}
            </h1>
            {/* <p>
        

              {allDetailsBlogs?.description}
            </p> */}
          </div>
          <div className={styles.userArticleWrapper}>
            <div className={styles.usersArticle}>
              <span className={styles.users}>
                <img src={calenderArticle1} alt="" />
                {moment(allDetailsBlogs?.createdAt).format("DD MMM YYYY ")}
              </span>
              <span className={styles.users}>
                <img src={userArticle} alt="" />
                {/* Elizabeth Hughes */}
                {allDetailsBlogs?.added_by_name}
              </span>
              {/* <span className={styles.users}>
                <img src={clockArticle} alt="" />7 minutes
              </span> */}
            </div>
            <div className={`${styles.categoryWrapper} hoverBtn`}>
              <span className={styles.categoryText}>Categories</span>
              {allDetailsBlogs?.blog_categories?.map((category, index) => (
                <>
                  <span
                    onClick={() => {
                      navigate("/resources/blog");
                      dispatch(setCategoryId(category?.category_id));
                    }}
                    key={index}
                  >
                    <CustomBtn variant="bgGrey-primary">
                      {category?.category_name}
                    </CustomBtn>
                  </span>
                </>
              ))}
            </div>
          </div>
        </div>
        <Container>
          <div className={styles.goodHands}>
            {/* <Row>
              <Col>
                <h5>You're in good hands</h5>
                <p>
                  The reviews we receive from our customers show how hard we
                  work to maintain an extremely high level of
                  <br /> service, and help us to make sure we’re always hitting
                  the mark.
                  <br /> <br />
                  We’re always striving to bring our clients the best possible
                  business accounting services at affordable prices,
                  <br /> and our user reviews show just how committed we are.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.goodhandsImg}>
                  <img src={blogArticleVideo} alt="" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.onlineAccountants}>
                  <h5>Online accountants for thriving clients</h5>
                  <p>
                    To us, being an online accountant means providing highly
                    professional accounting services to businesses – we
                    <br /> just happen to do it online. And that’s it. You’ll
                    receive an excellent level of service, and all our teams
                    work
                    <br /> together at our offices in the Northwest and in
                    London.
                    <br />
                    <br /> You’ll always know who you’re dealing with, starting
                    with our meet the team page. You’ll be able to put faces to
                    <br />
                    the people named in our reviews!
                    <br />
                    <br /> We’re an experienced bunch of accountants,
                    bookkeepers, and payroll clerks here at The Accountancy
                    <br /> Partnership, with many years of practical knowledge
                    working with all types of clients, across a huge range of
                    <br /> sectors.
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className={styles.card}>
                  <div className={styles.cardImg}>
                    <img src={blogArticle1} alt="" />
                  </div>
                  <div>
                    <h6>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h6>
                    <p className="small">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                  </div>

                  <div>
                    <img src={blogArticleLineGray} alt="" />
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  We provide year-round advice and services to thousands of
                  small businesses, partnerships, sole traders,
                  <br /> contractors and freelancers. Our affordable accounting
                  services are ideal for any new business starting out,
                  <br /> especially when budgets are tight.
                  <br />
                  <br />
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </li>
                    <li>Vivamus id odio sapien.</li>
                    <li>Curabitur ut velit eget ligula finibus dignissim.</li>
                    <li>
                      Duis faucibus metus nec mauris pharetra, nec dictum odio
                      vulputate.
                    </li>
                    <li>Vestibulum leo turpis, cursus non dapibus vel.</li>
                    <li>Mauris non felis non felis sodales sagittis.</li>
                    <li> Morbi nec eleifend libero.</li>
                  </ul>
                  We also work with established SME’s, providing a number of
                  business accounting services including payroll,
                  <br /> bookkeeping, and tax returns, VAT returns and CIS
                  returns.
                </p>
              </Col>
            </Row>
            <Row>
              <div className={styles.thirdSection}>
                <Row>
                  <Col className={`col-md-2 ${styles.card_img}`} xs={12}>
                    <img src={cardImg} alt="" />
                  </Col>
                  <Col className="col-md-10" xs={12}>
                    <div className={styles.commaAndText}>
                      <img src={commas} alt="" />
                      <h5>
                        I used to dread accounting month but the last 2 years
                        have
                        <br />
                        been utterly pain free because of this super lot!
                        Efficient,
                        <br /> professional, thorough and really dedicated to
                        saving you
                        <br /> money. The savings they made me more than paid
                        for their
                        <br /> services! Many thanks.
                      </h5>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium,
                      <br /> totam rem aperiam totam rem aperiam.
                    </p>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row>
              <Col>
                <div className={styles.onlineAccountants}>
                  <h5>Reviews mean happy customers</h5>
                  <p>
                    More and more review websites appear each day on the web,
                    and that means customers have more access to
                    <br /> platforms where they can leave reviews on a service
                    they have received. Any company like The Accountancy
                    <br /> Partnership that prides itself on its customer
                    service, welcomes any kind of feedback or review from
                    customers.
                    <br />
                    <br /> It helps us make sure that we always provide the very
                    best levels of service and customer care to our accounting
                    <br /> clients.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.onlineAccountants}>
                  <h5>The Accountancy Partnership – How can we help you?</h5>
                  <p>
                    Are you a brand new contractor? Or an established small
                    business unhappy with your current accounting service
                    <br /> and their cost? Demand for affordable, highly
                    professional accounting services has risen, which is why
                    we’re here
                    <br /> to help our clients all year round.
                    <br />
                    <br /> Whatever your situation, here at The Accountancy
                    Partnership we are always happy to review your situation and
                    <br /> offer support any way we can. We have years of
                    experience working alongside SME’s, and take real pride in
                    <br />
                    helping them grow with our professional accounting services.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <img src={horizontalLine} alt="" />
              </Col>
            </Row> */}

            <Row>
              <Col>
                <div className="discriptionsss">
                  {/* {allDetailsBlogs?.description} */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: allDetailsBlogs?.description,
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              {/* <Col className="col-md-6" xs={12}>
                <div className={styles.bottomCard}>
                  <div className={styles.bottomCardImg}>
                    <div>
                      <img src={bottomCard} alt="" />
                    </div>
                    <div>
                      <p>Edward Jones</p>
                      <p className={styles.small}>CEO hibooks</p>
                    </div>
                  </div>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque. Sed ut perspiciatis unde
                    omnis iste natus error sit voluptatem accusantium
                    doloremque.
                  </p>
                  <div>
                    <CustomBtn>Call to action</CustomBtn>
                    <CustomBtn variant="noColor-primary">
                      Call to action
                    </CustomBtn>
                  </div>
                </div>
              </Col> */}
              <Col className="col-md-12" xs={12}>
                <div className={styles.bottomCard2}>
                  <h6>See hibooks in action</h6>
                  <p>
                    Fast, reliable and secure, hibooks is powerful online
                    accounting software that helps your business thrive.
                  </p>
                  {/* <form>
                    <label htmlFor="">Label</label>
                    <br />
                    <input type="text" placeholder="Enter your email" />
                  </form> */}
                  <NewslaterForm />
                  {/* <div>
                    <CustomBtn>Call to action</CustomBtn>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
      <div className={styles.lastSection}>
        <div className={styles.HiBooks}>
          <HotPress
            allBlogs={allBlogs}
            dataPress={dataPress}
            heading="More news from Skytax"
            subHeading="Explore opinions, insights and legal need-to-knows about important business accounting topics." //We'll complete comprehensive financial accounts for your business.
          />
        </div>
      </div>
      <AboutUsFooter />
    </>
  );
};

export default BlogArticle;
