import React, { useEffect, useRef } from "react";
import styles from "./cisReturns.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/services/cisreturn.svg";
import companies from "../../../../assests/homePage/companies.svg";
import companies1 from "../../../../assests/homePage/companies1.svg";
import bags from "../../../../assests/homePage/bags.svg";
import companies2 from "../../../../assests/homePage/companies2.svg";
import cis from "../../../../assests/homePage/cis.svg";
import cis1 from "../../../../assests/homePage/cis1.svg";
import cis2 from "../../../../assests/homePage/cis2.svg";
import cis3 from "../../../../assests/homePage/cis3.svg";
import dollarImg from "../../../../assests/homePage/dollarImg.svg";
import book from "../../../../assests/homePage/book.svg";
import ship1 from "../../../../assests/homePage/ship5.svg";
import user from "../../../../assests/homePage/user.svg";
import whiteLineGray from "../../../../assests/services/whiteLineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/linenew.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import box3 from "../../../../assests/homePage/box.svg";
import boxNew from "../../../../assests/homePage/box3.svg";
import box4 from "../../../../assests/homePage/box4.svg";
import box5 from "../../../../assests/homePage/box5.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import WhoDoWe from "../../../../components/webapp/whoDoWe";
import HiBooks from "../../../../components/webapp/hiBooks";
import { Link } from "react-router-dom";

const data1 = [
  {
    title: "Accurate CIS returns",
    description:
      "We’ll prepare and submit your monthly CIS returns  accurately and on-time.",
    logo: cis,
    lineBar: lineBar,
  },
  {
    title: "Comprehensive record keeping",
    description:
      "A full set of records ready and waiting for any HMRC inspection. ",
    logo: cis1,
    lineBar: lineBar,
  },
  {
    title: "Expert CIS advice and guidance ",
    description:
      "An extensive CIS compliance and advisory service, so you’re always up to date. ",
    logo: cis2,
    lineBar: lineBar,
  },
  {
    title: "HMRC have questions? We have the answers.",
    description:
      "We’ll work with HMRC on your behalf to solve any problems. It’s all part of the service.",
    logo: cis3,
    lineBar: lineBar,
  },

  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Accounts",
    description:
      "Our comprehensive and flexible range of professional accounts gives you the time and insight to manage your business even better.",
    buttonText: "View Details",
    onclick: <Link to="/services/accounts" />,
    logo: boxNew,
  },
  {
    title: "Tax Returns",
    description:
      "Take the trauma out of tax effortlessly. We won’t just prepare your tax return accurately and expertly, we’ll optimise your tax position to minimise what you owe.",
    buttonText: "View Details",
    onclick: <Link to="/services/tax-returns" />,
    logo: box4,
  },
  {
    title: "Bookkeeping ",
    description:
      "We’ll handle the hard work of paperwork, leaving you free to work on your business.",
    buttonText: "View Details",
    onclick: <Link to="/services/bookKeeping" />,
    logo: box5,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Send us your stuff",
    description: "We’ll remind you in good time, and gather your CIS records.",
    logo: book,
    blueLine: whiteLineGray,
    count: "1",
  },
  {
    title: "We’ll crunch the numbers",
    description:
      "We’ll calculate, prepare and check your CIS return so it’s accurate and ready to go.",
    logo: companies2,
    blueLine: whiteLineGray,
    count: "2",
  },
  {
    title: "We’ll submit your CIS return to HMRC",
    description:
      "Once you’ve approved it, we’ll submit your CIS return to HMRC on your behalf.",
    logo: companies,
    blueLine: whiteLineGray,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Mr. Farid",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  {
    title: "Matthew ",
    subHead: "Saroia Staffing Services Ltd ",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  {
    title: "Gordon Whitings",
    subHead: "",
    description:
      "I've always found Skytax to be contactable and prompt in making sure responsibilities for VAT and corporation tax are met on time. I've always been able to refer to yourselves for questions relating to tax and management of annual accounts.",
    logo: user,
  },
  // Add more data objects as needed
];

const dataBooks = [
  {
    title: "Peace of mind",
    description:
      "Forget fines and penalties. You can rely on us for error-free, on-time CIS returns.",
    logo: companies1,
  },
  {
    title: "Get your time back",
    description:
      "Our professional CIS return service gives you the time and headspace to work on business strategy, not business slog.",
    logo: dollarImg,
  },
  {
    title: "Stay compliant",
    description:
      "No need to do your CIS homework because our CIS service keeps you effortlessly updated and in line with anything new.",
    logo: bags,
  },
  // Add more data objects as needed
];

const data = [
  {
    title: "Simple pricing, professional service.",
    price: "From just £5 per month per subcontractor",
    description: "Expert CIS services that don’t break the bottom line.",
    buttonText: "Get started",
    onclick: <a href="#cis" />,
    logo: box3,
  },

  // Add more data objects as needed
];

const CisReturns = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const whoDoWeRef = useRef<HTMLDivElement>(null);
  const handleFindOutClick = () => {
    if (whoDoWeRef.current) {
      whoDoWeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>CIS made simple</h6>
                      <h1>CIS Returns</h1>
                      <p>
                        Taking the hard work out of the construction industry
                        scheme.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        <Button onClick={handleFindOutClick}>
                          Find out more
                        </Button>
                        <Link to="/about-us/contact-us">
                          <Button className={styles.actionButton}>
                            Get started
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div ref={whoDoWeRef} className={styles.WhoDoWe}>
          <Services
            data={data1}
            heading="What you get"
            subHeading="We make life easier for contractors in the construction industry."
            text={""}
            imageSrc={""}
            type="ServicesAccount" // imageSrc={imageSrc}
            // text=" We will never tie you in to a long-term contract. You are free
            // to leave whenever you want, we're just confident you
            // won't want to!"
          />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="How it works"
            subHeading="CIS returns in just three easy steps."
            type="accounts"
          />
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            type="cis"
            heading="What it costs"
            subHeading="CIS returns made affordable."
            innerText="*Our CIS returns are available as a standalone service (or as an add-on to our main accounting solutions). A minimum charge of £25 applies."
          />
        </div>

        <div className={styles.HiBooks}>
          <HiBooks
            dataBooks={dataBooks}
            heading="Why choose us?"
            subHeading="Making CIS simple."
            type="cisresturn"
            typeNew={true}
            innerText="Could you benefit from from our other accounting solutions like bookkeeping or tax planning?"
            imgLarge={ship1}
            lineWhite={lineWhite}
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} type="reviweBg" />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Need other accountancy and business services? No problem. We’re here to help."
            subHeading="Don’t limit yourself. Choose from a tried, tested and trusted range of expert services that make life easier and business better."
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            id="cis"
            heading="Great support for your business, fast."
            subheading="Get a quote for our CIS Return Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our CIS Return  services now. Simply complete and submit the form."
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default CisReturns;
