// Test API
// export const API_BASE_URL = "https://dev-admin.permaxo.com/api/";

// Real API
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;

// export const API_BASE_URL = "https://d233nnca1cm740.cloudfront.net/";

// export const LIMIT: number = 10;

// export const ADMIN_ROLE_ID = "620504e9f47dd88dfc51e183";
// export const CONTRACTOR_ROLE_ID = "620ca6f433032d8eb3c3b247";
// export const CONTRACTOR_STAFF_ROLE_ID = "620ca6f433032d8eb3c3b249";
// export const ADMIN_STAFF_ROLE_ID = "620ca6f433032d8eb3c3b250";

// export const HOME_OWNER_ROLE_ID = "620ca6da33032d8eb3c3b236";
// export const STAFF_ID = "620ca6f433032d8eb3c3b249";

// export const INSPECTOR_ROLE_ID = "620ca6e733032d8eb3c3b239";
// export const BUILDER_ID = "64b78b0afa6f70f5bc89984f";
// export const TOWN_SHIP_ID = "64b78b0afa6f70f5bc899850";
