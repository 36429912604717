import { Modal } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import styles from "./ConfirmationModal.module.scss";
interface IProps {
  confirm: (id: any) => void;
  handleToggle: () => void;
  title: string;
  show: boolean;
  size?: any;
}
const ConfirmationModal = ({
  confirm,
  handleToggle,
  title,
  show,
  size = "lg",
}: IProps) => {
  return (
    <Modal
      show={show}
      onHide={handleToggle}
      size={size}
      className={styles.confirmationModal}
      centered
    >
      <Modal.Body>
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>
            <span className={styles.icn}>
              <MdDeleteOutline />
            </span>
            <h4>{title}</h4>
          </div>
          <div className="d-flex justify-content-center">
            <button className={`btn btn-outline-primary ${styles.btnLeft}`} onClick={handleToggle}>
              Cancel
            </button>
            <button  className={`btn btn-primary ms-3 ${styles.btnRight}`} onClick={confirm}>
              Confirm
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
