import React from "react";
import styles from "./accountingFor.module.scss";
import { Col, Container, Row } from "react-bootstrap";

import stars from "../../../assests/homePage/stars.svg";
import { useNavigate } from "react-router-dom";

interface DataItem {
  title: string;
  description: string;
  logo: string; // Assuming logo is a URL
  buttonText: string;
  onclick?: React.ReactElement;
}

interface AccountingForProps {
  accountingData: DataItem[]; // Use the defined interface for the data prop
  type?: string;
}

const AccountingFor: React.FC<AccountingForProps> = ({
  accountingData,
  type,
}) => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.whoWe} whoWe`}>
      <Container>
        <Col className="col-md-12 mb-4" xs={12}>
          <div className={`text-center ${styles.heading}`}>
            <h2>Accounting for...</h2>
            {/* <p>Discover why our clients love our services.</p> */}
          </div>
        </Col>
        <Row className={styles.accounting_container}>
          {accountingData.map((item, index) => (
            <Col key={index} className={styles.boxWidth}>
              <div className={` ${styles.box} `}>
                <div className={styles.boxImg}>
                  <img src={item.logo} alt={`Logo ${index}`} />
                </div>
                <div className={styles.boxText}>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  <a
                    href=""
                    style={{
                      fontSize: "12px",
                      textDecoration: "underline",
                      color: "#09547e",
                      fontWeight: 700,
                      textAlign: "left",
                    }}
                    onClick={() => {
                      navigate(item?.onclick?.props?.to);
                    }}
                  >
                    {item?.buttonText}
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default AccountingFor;
