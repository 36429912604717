import React from "react";
import styles from "./pricing.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LinkProps, useNavigate } from "react-router-dom";
import { JsxElement } from "typescript";
import { string } from "yup";

export interface DataItem {
  title: string;
  price: JSX.Element | string;
  description: string;
  buttonText: string;
  onclick?: React.ReactElement;
}

interface WhoDoWeProps {
  data: any[]; // Use the defined interface for the data prop
  heading: string;
  subHeading: string;
  type?: string;
  innerText?: string;
}
const Pricing: React.FC<WhoDoWeProps> = ({
  heading,
  subHeading,
  data,
  type,

  innerText,
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-5" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
              <div className="d-flex justify-content-center">
              <p>{subHeading}</p>
              </div>
            </div>
          </Col>

          <div className={styles.pricingContainer}>
            {/* <Col className="col-md-12 mb-5" xs={12}> */}
            {data.map((item, index) => (
              <div key={index} className={styles.pricingCard}>
                <h2 className={styles.pricingCard__title}>{item.title}</h2>
                <h3 className={styles.pricingCard__plan}>{item.plan}</h3>
                <div className={styles.pricingCard__price}>
                  <span className={styles.priceOriginal}>
                    £{item.originalPrice}
                  </span>
                  <span className={styles.priceDiscounted}>
                    £{item.discountedPrice}
                  </span>
                  <p className={styles.perMonth}>Per Month</p>
                </div>
                <p className={styles.pricingCard__description}>
                  Ideal for growing Limited Companies
                </p>
                <ul className={styles.pricingCard__features}>
                  {item.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <div className={styles.pricingCard__buttonContainer}>
                <button className={styles.pricingCard__button}>
                  Find out more
                </button>
                </div>
              </div>
            ))}
            {/* </Col> */}
          </div>

          {type === "account" ? (
            <></>
          ) : (
            <>
              {" "}
              <Col className="col-md-12">
                <h5 className={styles.weAlso}>{innerText}</h5>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Pricing;
