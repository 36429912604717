import styles from "./sidebar.module.scss";
import { BsPencilSquare } from "react-icons/bs";
import { RiGuideFill, RiLogoutCircleRLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../assests/logo.svg";
import { AiOutlineDashboard, AiOutlineUsergroupAdd } from "react-icons/ai";
import { TbCategory2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { MdOndemandVideo } from "react-icons/md";

interface IProps {
  sidebarToggle: boolean;
}
const Sidebar = ({ sidebarToggle }: IProps) => {
  const navigate = useNavigate();

  const logout = () => {
    // Perform any additional logout actions if needed
    // For example, you can make an API call to invalidate the token on the server

    // Remove the token from local storage
    localStorage.removeItem("authToken");

    // Redirect to the login page
    navigate("/admin/login");
  };

  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.sidebarList}>
        <div className={styles.logo}>
          <Link to="/">
            <span>
              <img src={logo} alt="dummy" />
            </span>
          </Link>
        </div>
        <ul>
          <NavLink to="/admin/dashboard">
            <li>
              <AiOutlineDashboard />
              Dashboard
            </li>
          </NavLink>

          <NavLink to="/admin/blog">
            <li>
              <BsPencilSquare />
              Blog
            </li>
          </NavLink>
          <NavLink to="/admin/category">
            <li>
              <TbCategory2 />
              Categories
            </li>
          </NavLink>

          <NavLink to="/admin/guides">
            <li>
              <RiGuideFill />
              Guides
            </li>
          </NavLink>

          <NavLink to="/admin/videos">
            <li>
              <MdOndemandVideo />
              Videos
            </li>
          </NavLink>

          <NavLink to="/admin/users">
            <li>
              <AiOutlineUsergroupAdd />
              Users
            </li>
          </NavLink>
        </ul>
      </div>
      <div className={styles.sidebarList}>
        <ul>
          {/* <NavLink to="/admin/login"> */}
          <li onClick={logout}>
            <RiLogoutCircleRLine />
            Logout
          </li>
          {/* </NavLink> */}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
