import React, { useState } from "react";
import styles from "../hiBooks/hiBooks.module.scss";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../../../common/theme/button";

interface DataItem {
  title: string;
  description: string;
  logo: string; // Assuming logo is a URL
}

interface SoftwareServiceFeatureProps {
  softwareFeature: DataItem[];
  serviceFeature: DataItem[];
  softwareHeading: string;
  softwareSubHeading: string;
  serviceHeading: string;
  serviceSubHeading: string;
  type?: string;
  typeNew?: boolean;
  innerText?: string;
  imgLarge?: string;
  lineWhite?: string;
}

const SoftwareServiceFeature: React.FC<SoftwareServiceFeatureProps> = ({
  softwareFeature,
  serviceFeature,
  softwareHeading,
  softwareSubHeading,
  serviceHeading,
  serviceSubHeading,
  type,
  typeNew,
  innerText,
  imgLarge,
  lineWhite,
}) => {
  const [currentTab, setCurrentTab] = useState<"software" | "service">(
    "software"
  );
  const navigate = useNavigate();

  const dataBooks =
    currentTab === "software" ? softwareFeature : serviceFeature;
  const heading = currentTab === "software" ? softwareHeading : serviceHeading;
  const subHeading =
    currentTab === "software" ? softwareSubHeading : serviceSubHeading;
  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-4" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
              <p>{subHeading}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
                marginTop: "60px",
              }}
            >
              <CustomBtn
                style={{
                  backgroundColor:
                    currentTab === "software" ? "#09547e" : "transparent",
                  color: currentTab === "software" ? "#fff" : "#09547e",
                  border: "1px solid #BBBBBD",
                  textDecoration: "underline",
                  fontSize: "12px",
                  padding: "0px 30px",
                  height: "45px",
                }}
                onClick={() => setCurrentTab("software")}
              >
                Software features
              </CustomBtn>
              <CustomBtn
                style={{
                  backgroundColor:
                    currentTab === "service" ? "#09547e" : "transparent",
                  color: currentTab === "service" ? "#fff" : "#09547e",
                  border: "1px solid #BBBBBD",
                  textDecoration: "underline",
                  fontSize: "12px",
                  padding: "0px 30px",
                  height: "45px",
                }}
                onClick={() => setCurrentTab("service")}
              >
                Service features
              </CustomBtn>
            </div>
          </Col>

          {dataBooks.map((item, index) => (
            <Col key={index} className="col-md-4" xs={12}>
              <div className={`${styles.box} hoverDivWhite`}>
                <div className={styles.boxImg}>
                  <img
                    src={item.logo}
                    alt={`Logo ${index}`}
                    width={48}
                    height={58}
                  />
                </div>
                <div className={styles.boxText}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default SoftwareServiceFeature;
