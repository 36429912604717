import React from "react";
import styles from "./patnerShip.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import CustomBtn from "../../../common/theme/button";
import { Link } from "react-router-dom";

interface DataItem {
  title: string;
  description: string;
  logo: string; // Assuming logo is a URL
  count: string;
  blueLine: string;
}

interface PatnerShipdataProps {
  PatnerShipdata: DataItem[]; // Use the defined interface for the data prop
  heading: string;
  subHeading: string;
  type?: string;
  title1?: string;
  title2?: string;
  subtitle?: string;
}
const PatnerShip: React.FC<PatnerShipdataProps> = ({
  PatnerShipdata,
  heading,
  subHeading,
  type,
  title1,
  title2,
  subtitle,
}) => {
  return (
    <Container>
      <div className={styles.headings}>
        <h1>{title1}</h1>
        <h2>{title2}</h2>
        <p>{subtitle}</p>
      </div>
      <div
        className={
          type === "accounts"
            ? `${styles.whoWe} ${styles.bgWhites}`
            : styles.whoWe
        }
      >
        <Row className="align-items-center">
          <Col className="col-md-12 mb-4" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h2>{heading}</h2>
              <p>{subHeading}</p>
            </div>
          </Col>

          {PatnerShipdata.map((item, index) => (
            <Col key={index} className="col-md-4" xs={12}>
              <div className={`text-center ${styles.box} hoverDivWhite`}>
                <div className={styles.boxImg}>
                  <img src={item.logo} alt={`Logo ${index}`} />
                  <span className={styles.countText}>{item.count}</span>
                </div>
                <div className={styles.boxText}>
                  {index < PatnerShipdata.length - 1 && ( // Check if it's not the last item
                    <img
                      src={item.blueLine}
                      alt={`Logo ${index}`}
                      className={styles.line}
                    />
                  )}
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Link to="/about-us/contact-us">
          <CustomBtn className={styles.btnText}>
            Request a free consultation
          </CustomBtn>
        </Link>
      </div>
    </Container>
  );
};

export default PatnerShip;
