/* eslint-disable jsx-a11y/anchor-has-content */
import styles from "./accounts.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/services/serviceBannernew.svg";
import companies from "../../../../assests/homePage/ship2.svg";
import companies1 from "../../../../assests/homePage/companies.svg";
import companies2 from "../../../../assests/homePage/companies2.svg";
import box from "../../../../assests/homePage/new2.svg";
import boxcost1 from "../../../../assests/homePage/box4.svg";
import boxcost2 from "../../../../assests/homePage/box5.svg";
import boxcost3 from "../../../../assests/guidesIcon.svg";
import box1 from "../../../../assests/homePage/slide3.svg";
import box2 from "../../../../assests/homePage/service6.svg";
import boxnew from "../../../../assests/homePage/service5.svg";
import ship from "../../../../assests/homePage/box1.svg";
import ship1 from "../../../../assests/homePage/box2.svg";
import ship2 from "../../../../assests/homePage/box3.svg";
import user from "../../../../assests/homePage/user.svg";
import whiteLineGray from "../../../../assests/services/whiteLineGray.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import box3 from "../../../../assests/homePage/bags.svg";
import box4 from "../../../../assests/homePage/dollarImg.svg";
import box5 from "../../../../assests/homePage/companies1.svg";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import WhoDoWe from "../../../../components/webapp/whoDoWe";
import HiBooks from "../../../../components/webapp/hiBooks";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const data1 = [
  {
    title: "Full accounts prepared and filed.",
    description:
      "We’ll keep you legit with professionally-prepared accounts and tax returns filed punctually.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Flexible reporting",
    description:
      "Management accounts. Cash flow statements. The insight you need, created monthly or quarterly. Your choice.",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Specialist knowledge ",
    description:
      "We always use our specialist knowledge to maximise your profit and minimise liabilities.",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "Personal, dedicated accountant",
    description:
      "You’ll be looked after by your own named, friendly, professional, qualified accountant.",
    logo: boxnew,
    lineBar: lineBar,
  },

  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "VAT Returns",
    description:
      "Stay ahead and penalty-free with fast, accurate and on-time VAT Returns.  Fully HMRC compliant and MTD-ready.",
    buttonText: "Free Download",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies,
  },
  {
    title: "Bookkeeping",
    description:
      "We’ll handle the hard work of paperwork, leaving you free to work on your business.",
    buttonText: "Free Download",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies1,
  },
  {
    title: "Payroll",
    description:
      "We’ll help you pay your people the right amount at the right time.",
    buttonText: "Free Download",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Preparation",
    description:
      "We’ll give you a heads up when your financial accounts are due, and ask for your records.",
    logo: ship,
    blueLine: whiteLineGray,
    count: "1",
  },
  {
    title: "Completion",
    description:
      "Your dedicated Skytax  accountant will complete your accounts punctually and professionally.",
    logo: ship1,
    blueLine: whiteLineGray,
    count: "2",
  },
  {
    title: "Submission",
    description:
      "Once approved, we’ll submit your accounts to the authorities on your behalf.",
    logo: ship2,
    blueLine: whiteLineGray,
    count: "3",
  },
  // Add more data objects as needed
];
const dataReview = [
  {
    title: "Dr J Afridi ",
    subHead: "   ",
    description:
      "Skytax Accounting has been a game-changer for my medical practice. Their tailored approach and expertise in healthcare finances have simplified my workload. I highly recommend them to fellow doctors and healthcare professionals— their dedication and support have been invaluable.",
    logo: user,
  },
  {
    title: "Mr. Farid  ",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  {
    title: "Matthew",
    subHead: "Saroia Staffing Services Ltd",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  // Add more data objects as needed
];

const dataBooks = [
  {
    title: "Peace of mind",
    description:
      "With us on your side, you don’t need to worry about deadlines, penalties or fines. Everything is done for you, at exactly the right time.",
    logo: box3,
  },
  {
    title: "Better business decisions",
    description:
      "We make the numbers work for you. We’ll unlock hidden profits and tax efficiencies, streamline financial operations and give you data-driven  insight for smarter decisions. ",
    logo: box4,
  },
  {
    title: "Professionalism",
    description:
      "We pride ourselves on our total expertise and professionalism. We won’t ask for your trust. We’ll earn it.",
    logo: box5,
  },
  // Add more data objects as needed
];

const data = [
  {
    title: "Sole Traders",
    price: "From £30 per month",
    description:
      "Reliable, professional accounts services, at an affordable price.",
    buttonText: "Get started",
    onclick: <a href="#account" />,
    logo: boxcost1,
  },
  {
    title: "Limited Companies",
    price: "From £120 per month",
    description: "Accounting and tax expertise to help your business grow.",
    buttonText: "Get started",
    onclick: <a href="#account" />,

    logo: boxcost2,
  },
  {
    title: "Partnerships",
    price: "From £50 per month",
    description:
      "Managing the complexities of partnership accounts with ease and expertise.",
    buttonText: "Get started",
    onclick: <a href="#account" />,

    logo: boxcost3,
  },
  // Add more data objects as needed
];

const Accounts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const whoDoWeRef = useRef<HTMLDivElement>(null);

  const handleFindOutClick = () => {
    if (whoDoWeRef.current) {
      whoDoWeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Fast. Professional. Accurate.</h6>
                      <h1>Accounts</h1>
                      <p>
                        Get peace of mind - and your time back - with our
                        professional, budget-friendly accounts service.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        <Button onClick={handleFindOutClick}>
                          Find out more
                        </Button>
                        <Link to="/about-us/contact-us">
                          <Button className={styles.actionButton}>
                            Get started
                          </Button>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div ref={whoDoWeRef} className={styles.WhoDoWe}>
          <Services
            data={data1}
            heading="What you get"
            subHeading="A comprehensive and flexible range of professional accounts services to help you get where you want to go."
            text={""}
            imageSrc={""}
            type="ServicesAccount" // imageSrc={imageSrc}
            // text=" We will never tie you in to a long-term contract. You are free
            // to leave whenever you want, we're just confident you
            // won't want to!"
          />
        </div>

        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="How it works"
            subHeading="Simple, reliable accounting in just three steps:"
            type="accounts"
          />
        </div>

        <div className={styles.WhoDoWe}>
          <WhoDoWe
            data={data}
            type="account"
            heading="What it costs"
            subHeading="All our accounts packages include the preparation and submission of formal accounts and tax returns. Need more help? It’s easy to add on other services."
          />
        </div>

        <div className={styles.HiBooks}>
          <HiBooks
            dataBooks={dataBooks}
            heading="Why choose us?"
            subHeading="Financial expertise and reliable professionalism. And always with peace of mind fitted as standard."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} type="reviweBg" />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Need other accountancy and business services? No problem. We’re here to help."
            subHeading="Don’t limit yourself. Choose from a tried, tested and trusted range of expert services that make life easier and business better."
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            id="account"
            heading="Great support for your business, fast."
            subheading="Get a quote for our Accounts Service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="Perhaps just one of our services is right for you - or perhaps the whole package. But whatever you choose, you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="We hope you like what you see so far.  If you’re curious about how much it’ll cost, don’t wait, get a quote for our accounts services now. Simply complete and submit the form."
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default Accounts;
