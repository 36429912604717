import React from "react";
import styles from "./faqs.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

interface FaqItemBody {
  type: "paragraph" | "list"; // Use specific literal types here
  content: string | string[]; // Content can be a string (for paragraphs) or string array (for lists)
}

interface FaqItem {
  header: string;
  body: FaqItemBody[];
}

interface PressProps {
  faqItems?: FaqItem[];
  heading: string;
  subHeading: string;
}
const faqItems = [
  {
    header: "What is a Limited Company?",
    body: [
      {
        type: "paragraph",
        content:
          "A limited company is a distinct business entity independent from its owners, typically shareholders and subscribers. Its primary advantage lies in providing ‘limited liability’, shielding owners from personal responsibility for the company’s financial losses. This structure also enhances corporate credibility, optimizes tax arrangements, opens doors to investment and financing, and facilitates future business sales.",
      },
    ],
  },
  {
    header: "How do I set up a limited company?",
    body: [
      {
        type: "paragraph",
        content:
          "To set up a limited company, you need to register with Companies House. This involves choosing a company name, appointing directors and a company secretary, and registering your office address. You’ll also need to prepare documents like the memorandum and articles of association.",
      },
    ],
  },
  {
    header: "Do I need a business bank account for a limited company?",
    body: [
      {
        type: "paragraph",
        content:
          "Yes, it’s advisable to have a separate business bank account for a limited company. This helps keep your company’s finances separate from your personal finances, making it easier to manage your accounts and comply with legal requirements.",
      },
      {
        type: "paragraph",
        content:
          "It also enhances your company’s professionalism and credibility with clients and suppliers. Additionally, having a dedicated business account simplifies the process of tracking business expenses and income, which is crucial for accurate financial reporting and tax purposes.",
      },
    ],
  },
  {
    header:
      "What are the obligations once a private limited company is registered?",
    body: [
      {
        type: "paragraph",
        content:
          "Once registered as a private limited company, you have several obligations to fulfill:",
      },
      {
        type: "list",
        content: [
          "Annual Accounts: Prepare annual financial statements (accounts) showing the company’s financial position and performance.",
          "Annual Confirmation Statement: File an annual confirmation statement with Companies House, confirming details like shareholders, directors, and registered office address.",
          "Tax Returns: File a Company Tax Return (CT600) with HM Revenue and Customs (HMRC) annually, reporting the company’s income, expenses, and tax liabilities.",
          "Pay Taxes: Pay Corporation Tax on profits, typically within 9 months and 1 day after the end of your company’s financial year.",
          "Compliance: Comply with legal obligations, including maintaining company records, holding annual general meetings (if applicable), and adhering to relevant laws and regulations.",
          "Shareholder Communication: Keep shareholders informed about the company’s activities, financial performance, and any major decisions.",
          "Reporting Changes: Notify Companies House of any changes to company details, such as directors, shareholders, registered office address, or share structure.",
        ],
      },
      {
        type: "paragraph",
        content:
          "These obligations ensure transparency, accountability, and compliance with UK company law.",
      },
    ],
  },
  {
    header: "Do you need an accountant to run a private limited company?",
    body: [
      {
        type: "paragraph",
        content:
          "While it’s not a legal requirement to have an accountant for a limited company, many small business owners find it highly beneficial. Here are some reasons why you might consider hiring one:",
      },
      {
        type: "list",
        content: [
          "Compliance: Accountants help ensure your company complies with all relevant laws and regulations, including filing annual accounts and tax returns on time.",
          "Tax Efficiency: They can advise on tax planning and help you take advantage of any available tax reliefs, potentially saving your business money.",
          "Financial Management: Accountants can provide valuable insights into your company’s financial health, helping you make informed business decisions.",
          "Time-Saving: Managing finances can be time-consuming. An accountant can handle these tasks, allowing you to focus on running and growing your business.",
          "Avoiding Penalties: Mistakes in financial reporting can lead to penalties. An accountant can help you avoid these by ensuring accuracy in your financial records.",
        ],
      },
      {
        type: "paragraph",
        content:
          "If you feel confident in managing your company’s finances, you might choose to handle it yourself, especially with the help of accounting software. However, many business owners find the expertise and peace of mind an accountant provides to be well worth the investment.",
      },
    ],
  },
  {
    header: "How much is the corporation tax rate for a UK limited company?",
    body: [
      {
        type: "paragraph",
        content:
          "As of April 1, 2023, the corporation tax rate for UK limited companies depends on their profits:",
      },
      {
        type: "list",
        content: [
          "19% for companies with profits up to £50,000.",
          "25% for companies with profits over £250,000.",
          "For companies with profits between £50,000 and £250,000, a marginal relief rate applies, which gradually increases the effective tax rate from 19% to 25%.",
        ],
      },
    ],
  },
  {
    header: "When does corporation tax need to be paid?",
    body: [
      {
        type: "paragraph",
        content:
          "Corporation tax for UK limited companies must be paid nine months and one day after the end of your company’s accounting period. For example, if your accounting period ends on December 31st, your corporation tax payment is due by October 1st of the following year. If your company’s profits exceed £1.5 million, you may need to pay your corporation tax in installments.",
      },
    ],
  },
  {
    header:
      "What is the difference between a limited company and a sole trader?",
    body: [
      {
        type: "paragraph",
        content:
          "There are several key differences between a limited company and a sole trader:",
      },
      {
        type: "list",
        content: [
          "Legal Structure: Limited Company provides limited liability protection, while Sole Trader has unlimited liability.",
          "Taxation: Limited Company pays Corporation Tax; Sole Trader pays Income Tax on profits.",
          "Financial Reporting: Limited Company files accounts with Companies House; Sole Trader files Self-Assessment tax return annually.",
          "Administration: Limited Company has more administrative responsibilities, Sole Trader has simpler requirements.",
          "Perception and Business Opportunities: Limited Company often seen as more credible; Sole Trader is simpler, lower-cost.",
        ],
      },
    ],
  },
  {
    header: "What records do I need to keep?",
    body: [
      {
        type: "paragraph",
        content:
          "You need to keep records of all company transactions, including sales and purchases, as well as records of the company’s assets and liabilities. You must also keep records of the company’s shareholders and directors.",
      },
    ],
  },
  {
    header: "Do limited companies pay dividends?",
    body: [
      {
        type: "paragraph",
        content:
          "Yes, limited companies can pay dividends to their shareholders. Dividends are a portion of the company’s profits distributed to shareholders as a return on their investment.",
      },
      {
        type: "paragraph",
        content:
          "The company must have sufficient profits after tax to cover the dividend payments. Dividends are typically paid out of retained earnings, and the amount each shareholder receives is proportional to their shareholding in the company. It’s important to note that dividends are subject to different tax rules compared to salaries.",
      },
    ],
  },
  {
    header: "How do I close my limited company?",
    body: [
      {
        type: "paragraph",
        content:
          "Closing a UK limited company involves several steps and legal requirements. Here’s a general outline of the process:",
      },
      {
        type: "list",
        content: [
          "Board Resolution: Hold a board meeting and pass a resolution to close the company.",
          "Dissolve the Company: Apply for voluntary strike-off or proceed with liquidation if insolvent.",
          "Tax and Final Returns: File final accounts and a Company Tax Return.",
          "Inform Stakeholders: Notify HMRC, employees, and creditors.",
          "Cancel VAT Registration and PAYE.",
          "Close Bank Accounts and cancel contracts.",
          "Companies House: File required documents to officially dissolve.",
        ],
      },
      {
        type: "paragraph",
        content:
          "It’s crucial to follow these steps carefully to comply with legal obligations and avoid personal liability. Seeking professional advice is advisable to ensure everything is handled correctly.",
      },
    ],
  },
];

const NewFAQS: React.FC<PressProps> = ({ heading, subHeading }) => {
  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-4" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
              <p>{subHeading}</p>
            </div>
          </Col>

          <div className={`${styles.accordions}`}>
            <Col md={12}>
              <Accordion
                defaultActiveKey="0"
                className={`${styles.acodion_main}  `}
              >
                {faqItems.map((item, index) => (
                  <Accordion.Item
                    eventKey={index.toString()}
                    key={index}
                    className={`${styles.acodion_items} hoverFaq `}
                  >
                    <Accordion.Header className={styles.customAccordionHeader}>
                      {item.header}
                      <span className={styles.plusMinusIcon}>+</span>
                    </Accordion.Header>
                    <Accordion.Body className={styles.acodion_body}>
                      {item.body.map((section, sectionIndex) => (
                        <div key={sectionIndex}>
                          {section.type === "paragraph" && (
                            <p>{section.content}</p>
                          )}
                          {section.type === "list" &&
                            Array.isArray(section.content) && (
                              <ul>
                                {section.content.map((listItem, listIndex) => (
                                  <li key={listIndex}>{listItem}</li>
                                ))}
                              </ul>
                            )}
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default NewFAQS;
