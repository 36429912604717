import { useEffect, useState } from "react";
import styles from "./guides.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";

import { useLocation } from "react-router-dom";
import GuidesForm from "./guidesModal/GuidesForm";

function AddEditGuides() {
  const [addEmploySidebar, setAddEmploySidebar] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<any | null>(null);
  const [editView, setEditView] = useState(false);
  const [initinalValues, setInitinalValues] = useState({
    // id: "",
    files: "",
    title: "",
  });

  const location = useLocation();

  const toggleEmploySidebar = () => {
    setAddEmploySidebar(!addEmploySidebar);
  };

  useEffect(() => {
    if (location.state) {
      setEditView(true);
      setEditableItem(location.state);
      setInitinalValues({
        // id: location?.state?.item?.id,
        title: location?.state?.item?.title,
        files: location?.state?.item?.files,
      });
    }
  }, [location.state]);

  return (
    <DashboardLayout>
      <div className={styles.users_page}>
        <GuidesForm
          toggleSidebar={toggleEmploySidebar}
          editView={editView}
          editableItem={editableItem}
          initinalValues={initinalValues}
          // initialValues={initinalValues}
          setInitinalValues={setInitinalValues}
        />
      </div>
    </DashboardLayout>
  );
}
export default AddEditGuides;
