import React, { ReactNode } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import styles from "./theme.module.scss";

interface IProps extends ButtonProps {
  children: ReactNode;
  fullWidth?: boolean;
}

const CustomBtn = ({ children, fullWidth, ...rest }: IProps) => {
  return (
    <Button
      {...rest}
      className={`${styles.customBtn} ${rest.className}  ${
        fullWidth ? "w-100" : ""
      }`}
    >
      {children}
    </Button>
  );
};

export default CustomBtn;
