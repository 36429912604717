import React, { useEffect } from "react";
import styles from "./freelancers.module.scss";
import WebappLayout from "../../../../components/webapp/webappLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import soleTrade from "../../../../assests/homePage/freelancernew.png";
import companies from "../../../../assests/homePage/ship2.svg";
import companies1 from "../../../../assests/homePage/box1.svg";
import companies2 from "../../../../assests/homePage/box2.svg";
import box from "../../../../assests/homePage/cis.svg";
import box1 from "../../../../assests/homePage/cis2.svg";
import box2 from "../../../../assests/homePage/cis5.svg";
import box4 from "../../../../assests/homePage/Medium.svg";
import box5 from "../../../../assests/homePage/newframe.png";
import box6 from "../../../../assests/homePage/cis4.svg";
import ship from "../../../../assests/homePage/ship5.svg";
import ship1 from "../../../../assests/homePage/ship1.svg";
import ship2 from "../../../../assests/homePage/box.svg";
import user from "../../../../assests/homePage/user.svg";
import blueLine from "../../../../assests/homePage/lineBlue.svg";
import circleBlue from "../../../../assests/homePage/circleBlue.svg";
import lineWhite from "../../../../assests/homePage/lineWhite.svg";
import lineBluelIght from "../../../../assests/homePage/lineBluelIght.svg";
import imageSrc from "../../../../assests/homePage/longterm.svg";
import lineGrays from "../../../../assests/homePage/lineGray.svg";
import lineBar from "../../../../assests/homePage/lineLongs.svg";
import whiteCrcle from "../../../../assests/homePage/whiteCrcle.svg";
import circleLight from "../../../../assests/homePage/circleLight.svg";
import banRigh from "../../../../assests/homePage/banRigh.svg";
import trade from "../../../../assests/homePage/n1.png";
import trade2 from "../../../../assests/homePage/n2.png";
import trade3 from "../../../../assests/homePage/n3.png";
import ReviweSection from "../../../../components/webapp/reviweSection";
import PatnerShip from "../../../../components/webapp/patnerShipSection";
import Quote from "../../../../components/webapp/quote/Quote";
import Services from "../../../../components/webapp/services";
import IncludeSection from "../../../../components/webapp/includeSection";
import LimitedCompanies from "../../../../components/webapp/limitedCompanies";
import { Link, useNavigate } from "react-router-dom";

const data = [
  {
    title: "Full-service accounts",
    description:
      "Statutory accounts prepared and submitted. Management accounts and a full suite of reports for the business insight you need.",
    logo: box,
    lineBar: lineBar,
  },
  {
    title: "Free online bookkeeping software",
    description:
      "Free access to hibooks means you can ditch manual data entry for simple snap’n’go online bookkeeping - and there’s much more!",
    logo: box1,
    lineBar: lineBar,
  },
  {
    title: "Self-assessment",
    description:
      "No more penalties. No more last-minute filing stress. We’ll take care of your self-assessment tax return. Accurate and on-time. Always.",
    logo: box2,
    lineBar: lineBar,
  },
  {
    title: "VAT Return",
    description:
      "Your VAT Returns submitted on time, always. We’re fully HMRC-approved and Making Tax Digital compliant",
    logo: box4,
    lineBar: lineBar,
  },
  {
    title: "Expert advice",
    description:
      "Your very own Skytax accountant is always just a call away when you need help and advice. It’s all part of the service.",
    logo: box5,
    lineBar: lineBar,
  },
  {
    title: "HMRC representation",
    description:
      "We can directly liaise with HMRC to handle your tax affairs on your behalf – saving you time and reducing stress.",
    logo: box6,
    lineBar: lineBar,
  },
  // Add more data objects as needed
];

const dataInclude = [
  {
    title: "Searching for a fresh accounting partner?",
    description:
      "Skytax can offer you tailored advice and optimise your business finances to minimise tax liabilities.",
    logo: trade,
    sub: "Experts in freelancer accounting.",
    subp: "It’s easy to switch accountants – we’ll take care of the handover with your previous accountant so you can focus on running your freelancing business.",
    subpp:
      "As your new accountants we’ll take care of the entire changeover from your previous accountant or bookkeeper. We will start by contacting them to request all the information we need to correspond with HMRC and act on your behalf. Being a sole trader doesn’t mean you’re alone!",
  },
  {
    title: "Accounting services to suit you.",
    description:
      "Our accounting solutions can flex and scale throughout your entrepreneurial journey.",
    logo: trade2,
    sub: "Affordable, personalised solutions.",
    subp: "Just need help with self-assessment? Tick. Need bookkeeping, expenses review and tax planning too? We’ve got you covered.",
    subpp:
      "That’s why we offer a range of additional accounting services to sole traders, such as bookkeeping, VAT returns, payroll or pensions, ready for you to add or remove as and when you need them.",
  },
  {
    title: "Get hibooks for free",
    description:
      "Imagine if you had more time for strategy. More headspace for marketing. More bandwidth for delighting your clients while still taking care of the day-to-day numbers.  You can. Welcome to hibooks ",
    logo: trade3,
    sub: "Welcome to hibooks.",
    subp: "hibooks is our hassle-free, online bookkeeping platform that’s built to eliminate manual data tasks and streamline your finances. It’s packed with the AI-driven features like smart invoicing, snap-n-go expenses, and automated real-time reporting that give you months’ of time and focus back.",
    subpp:
      "To protect you from the stress of an HMRC audit or inspection, we will act on your behalf and fight your corner with HMRC, at no additional cost.",
  },
  // Add more data objects as needed
];

const CompanyData = [
  {
    title: "Business Option Guide",
    description:
      "Just starting out? Know your business options and the implications on accounting.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies,
  },
  {
    title: "Limited vs. Sole Trader Guide",
    description:
      "Take a close look at the pros and cons of setting up as a limited company or going it alone.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies1,
  },
  {
    title: "Guide to Expenses",
    description:
      "Worried you’re paying too much tax? Check you’ve deducted all allowable freelancer expenses.",
    buttonText: "Get the guide",
    onclick: <Link to="/resources/guides-and-videos" />,
    logo: companies2,
  },
  // Add more data objects as needed
];

const PatnerShipdata = [
  {
    title: "Reach out to us",
    description: "Simply pop your details into our online form here .",
    logo: ship,
    blueLine: blueLine,
    count: "1",
  },
  {
    title: "Let’s talk",
    description:
      "We’ll call you for a no-obligation chat about the services you’d like.",
    logo: ship1,
    blueLine: blueLine,
    count: "2",
  },
  {
    title: "We do the rest",
    description:
      "Once confirmed, we’ll set everything up for you - you don’t need to lift a finger!",
    logo: ship2,
    blueLine: blueLine,
    count: "3",
  },
  // Add more data objects as needed
];

const dataReview = [
  {
    title: "Dr J Afridi ",
    subHead: "   ",
    description:
      "Skytax Accounting has been a game-changer for my medical practice. Their tailored approach and expertise in healthcare finances have simplified my workload. I highly recommend them to fellow doctors and healthcare professionals— their dedication and support have been invaluable.",
    logo: user,
  },
  {
    title: "Mr. Farid  ",
    subHead: "Entralon Ltd ",
    description:
      "I highly recommend Skytax Accounting Ltd. to any property management firm seeking reliable, knowledgeable, and efficient accounting services. Their dedication and expertise have been instrumental in ensuring our financial health and allowing us to focus on delivering exceptional service to our clients.",
    logo: user,
  },
  {
    title: "Matthew",
    subHead: "Saroia Staffing Services Ltd",
    description:
      "I wanted to take a moment to express my gratitude for the exceptional support your firm has provided to my recruitment business. Your team's expertise in handling our financial matters has been invaluable. From tax guidance to financial planning, your proactive approach and attention to detail have truly made a difference. Your professionalism and dedication have not only ensured compliance but have also optimized our financial operations. Thank you for being a reliable partner in our success story!",
    logo: user,
  },
  // Add more data objects as needed
];

const Freelancers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <WebappLayout>
      <>
        <div className={styles.homePage}>
          <Container>
            <div className={styles.mainPage}>
              <Row>
                <Col className="d-flex align-items-center col-md-5" xs={12}>
                  <div className={styles.leftPage}>
                    <div className={styles.linegrayssTop}>
                      <img src={lineBluelIght} alt="" />
                    </div>
                    <div className={styles.provide}>
                      <h6>Professional. Affordable. Reliable.</h6>
                      {/* <h1>
                        Comprehensive accountancy <br /> services for limited{" "}
                        <br />
                        companies
                      </h1> */}
                      <h1>
                        Expert accounting services, created especially for
                        freelancers.
                      </h1>
                      <p>
                        Freelancers are defined by their love of freedom. Our
                        comprehensive accounting services frees you from
                        financial hard labour, so you can focus on what you do
                        best.
                      </p>
                      <div className={`${styles.buttons} hoverBtn`}>
                        {/* <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                        >
                          Get started
                        </Button> */}
                        <Button
                          onClick={() => {
                            navigate("/about-us/contact-us");
                          }}
                          className={styles.actionButton}
                        >
                          Get started
                        </Button>
                      </div>
                    </div>
                    <div className={styles.linegrayss}>
                      <img src={lineGrays} alt="" />
                    </div>
                  </div>

                  <div className={`${styles.circlelight} ${styles.grow}`}>
                    <img src={circleLight} alt="" />
                  </div>
                  <div
                    className={`${styles.circlelight} ${styles.topLight} ${styles.shrink}`}
                  >
                    <img src={whiteCrcle} alt="" />
                  </div>
                </Col>
                <Col className="col-md-7" xs={12}>
                  <div className={styles.banRightLine}>
                    <img src={banRigh} alt="" />
                  </div>
                  <div className={styles.rightPage}>
                    <img src={soleTrade} alt="" />
                  </div>
                  <div className={styles.circleblue}>
                    <img src={circleBlue} alt="" />
                  </div>
                </Col>
              </Row>
              <div className={styles.lineWhite}>
                <img src={lineWhite} alt="" />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.WhoDoWe}>
          <Services
            data={data}
            heading="What you get"
            subHeading="A proactive, professional and freelancer-friendly accounts and tax service, with peace of mind always fitted as standard."
            imageSrc={imageSrc}
            text=" We’re as flexible as our freelancer clients. That’s why there’s no lengthy contracts. Just transparent pricing, a simple pay-per-month  setup and, of course, you can. cancel anytime."
          />
        </div>

        <div className={styles.HiBooks}>
          <ReviweSection dataReview={dataReview} height={375} />
        </div>
        <div className={styles.HiBooks}>
          <PatnerShip
            PatnerShipdata={PatnerShipdata}
            heading="Getting started"
            subHeading="Get Skytax comprehensive accounting services specially created for freelancers  in just three simple steps."
          />
        </div>

        <div className={styles.HiBooks}>
          <IncludeSection dataInclude={dataInclude} />
        </div>

        <div className={styles.HiBooksCompany}>
          <LimitedCompanies
            CompanyData={CompanyData}
            heading="Downloadable resources for freelancers"
            subHeading=""
            // subHeading="We have a comprehensive range of free resources available to help your business run as smoothly."
          />
        </div>

        <div className={styles.HiBooksQuete}>
          <Quote
            heading="Great support for your business, fast."
            subheading="Get a quote for our Freelancers service"
            headingOne="Your business is different. It has unique challenges and strengths, and that’s why our services aren’t one-size-fits-all."
            headingTwo="But you’ll find our monthly fee is transparent, fair, clear and affordable. No hidden extras. No surprises."
            headingthree="If you’re curious about how much it’ll cost, don’t wait, get a quote for our comprehensive freelance services now. Simply complete and submit the form."
            id={undefined}
          />
        </div>
      </>
    </WebappLayout>
  );
};

export default Freelancers;
