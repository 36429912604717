import { useEffect, useState } from "react";
import styles from "./users.module.scss";
import DashboardLayout from "../../../components/admin/layouts/DashboardLayout";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import useFetch from "../../../hooks/useFetch";
import { Link, useParams } from "react-router-dom";
import moment from "moment"; // Import moment
import { Badge } from "react-bootstrap";
import { BASE_IMAGE_URL } from "../../../services/constants";

function UsersDetails() {
  const [userDetails, setUserDetails] = useState<any>();
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const [userDetailsApi] = useFetch(`/v1/user/profile/${id}`, {
    method: "post",
  });

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const { data } = await userDetailsApi();

        setUserDetails(data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoading(false);
      }
    };

    fetchBlogDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.users_page}>
        <div className={styles.prof_page}>
          <div className={styles.backBtn}>
            <Link to="/admin/users">
              <button className="btn">
                <BsFillArrowLeftCircleFill />
                Back
              </button>
            </Link>
          </div>

          <div className={styles.prof_detail}>
            <div className="theme_tabs">
              <div className={styles.detail_wrap}>
                <div className={styles.detail_fields}>
                  <div className={styles.detail_col12}>
                    <div className={styles.detail_singles}>
                      <h5>User Details</h5>

                      {/* <p> {userDetails?.image}</p> */}
                    </div>
                  </div>

                  <div className={styles.detail_col6}>
                    <div className={styles.detail_fields}>
                      <div className={styles.detail_col12}>
                        <div className={styles.detail_single}>
                          <h5>Name</h5>
                          <p>
                            {userDetails?.first_name} {userDetails?.last_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.detail_col6}>
                    <div className={styles.detail_fields}>
                      <div className={styles.detail_col12}>
                        <div className={styles.detail_single}>
                          <h5>Email</h5>
                          <p>{userDetails?.email}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.detail_col6}>
                    <div className={styles.detail_fields}>
                      <div className={styles.detail_col12}>
                        <div className={styles.detail_single}>
                          <h5>created at</h5>
                          <p>
                            {moment(userDetails?.createdAt).format(
                              "MMMM D, YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className={styles.prof_details}>
            <div className="theme_tabs">
              <div className={styles.detail_wrap}>
                <div className={styles.detail_fields}>

                  <div className={styles.detail_col4} >
                    <div className={styles.detail_single}>
                      <h5>description</h5>
                      <p dangerouslySetInnerHTML={{ __html: userDetails?.description }} />
                    </div>
                  </div>
                  <div className={styles.detail_col4} >
                    <div className={styles.detail_single}>
                      <h5>description</h5>
                      <p dangerouslySetInnerHTML={{ __html: userDetails?.description }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </DashboardLayout>
  );
}
export default UsersDetails;
