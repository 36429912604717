import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import styles from "../../../../components/admin/addBlogs/AddBlogForm.module.scss";
import "react-quill/dist/quill.snow.css"; // Import styles
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

interface IProps {
  toggleSidebar?: any;
  editableItem?: any;
  editView?: boolean;
  setInitinalValues?: any;
  initinalValues?: any;
}

const GuidesForm = ({
  editableItem,
  initinalValues,
  setInitinalValues,
  editView,
}: IProps) => {
  const { id } = useParams();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const navigate = useNavigate();

  const schema = (editView: any) =>
    yup.object().shape({
      title: yup.string().required("Title is required "),

      files: editView ? yup.string() : yup.string().required("PDF is required"),
    });

  const [guideDetailsApi] = useFetch(`/v1/guide/detail/${id}`, {
    method: "post",
  });

  useEffect(() => {
    if (editView) {
      guideDetailsApi();
    }
    // eslint-disable-next-line
  }, [editView]);

  const [guideApi] = useFetch(
    editView && editableItem?.item?.id ? `/v1/guide/edit` : `/v1/guide/add`,
    // `/v1/guide/edit/${editableItem.item.id}`
    {
      method: editView ? "post" : "post",
    },
    "formdata"
  );

  useEffect(() => {
    if (editableItem) {
      setInitinalValues({
        title: editableItem?.item?.title,
      });
      // setFieldValue("files", e.target.files[0]);
      setSelectedImage(editableItem?.item.pdf);
    }
    // eslint-disable-next-line
  }, [editableItem]);

  const handleSubmit = async (values) => {
    if (selectedImage === null) {
      setErrorImg(true);
      return false;
    }
    let formdata = new FormData();
    if (editView) {
      formdata.append("title", values.title);
      formdata.append("files", selectedImage || "");
      formdata.append("guide_id", editableItem?.item?.id);
    } else {
      formdata.append("title", values.title);
      formdata.append("files", selectedImage || "");
    }

    try {
      setLoading(true);
      const response = await guideApi(formdata);
      setLoading(false);
      if (response.data && response.data.status === 1) {
        navigate("/admin/guides");
      } else {
        console.error("API Request Error:", response?.data?.message);
        if (response.data && response.data.status === 0) {
          toast.error(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      console.error("API Request Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className={styles.blogBox}>
      <ToastContainer />
      <Formik
        validationSchema={schema(editView)}
        onSubmit={handleSubmit}
        // initialValues={initinalValues}
        initialValues={initinalValues} // Change this to initialValues
        enableReinitialize={editView}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <div className={styles.formStep}>
                <h4>{editView ? "Edit Guide" : "Add Guides"}</h4>

                <div className={styles.twoFieldsWrap}>
                  <div className={styles.halfDivs}>
                    <Form.Group className={styles.singleField}>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        // isInvalid={!!errors.title && touched.title ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {typeof errors?.title === "string"
                          ? touched.title && errors?.title
                          : ""}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className={styles.singleField}>
                      <Form.Label>PDF Upload</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Enter name"
                        name="files"
                        // value={values?.files}
                        accept="application/pdf"
                        onChange={(e: any) => {
                          setFieldValue("files", e.target.files[0]);
                          setSelectedImage(e.target.files[0]);
                        }}
                      />

                      {selectedImage && editView && (
                        <div className={styles.pdfClass}>
                          <BsFillFileEarmarkPdfFill />
                        </div>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {typeof errors?.files === "string"
                          ? touched.files && errors?.files
                          : ""}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className={styles.singleField}>
                    <button
                      className={`mt-2 mb-3 btn btn-primary ${styles.subBtn}`}
                      type="submit"
                      disabled={loading} // Disable the button when loading
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only ms-1">
                            {editView ? "Update" : "Save"}
                          </span>
                        </>
                      ) : (
                        `${editView ? "Update" : "Save"}`
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default GuidesForm;
