import React from "react";
import styles from "./reviweSection.module.scss";
import { Col, Container, Row } from "react-bootstrap";

import stars from "../../../assests/homePage/stars.svg";
import { useLocation, useParams } from "react-router-dom";

interface DataItem {
  title: string;
  subHead: string;
  description: string;
  logo: string; // Assuming logo is a URL
}

interface ReviweSectionProps {
  dataReview: DataItem[]; // Use the defined interface for the data prop
  type?: string;
  height?: number | string;
}

const ReviweSection: React.FC<ReviweSectionProps> = ({
  dataReview,
  type,
  height,
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div
      className={
        type === "reviweBg"
          ? `${styles.whoWe} ${styles.whoWeWhiteBg} `
          : styles.whoWe
      }
    >
      <Container>
        <Row className="">
          <Col className="col-md-12 mb-5" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h2>What people say</h2>
              <p>Discover why our clients love our services.</p>
            </div>
          </Col>

          {dataReview.map((item, index) => (
            <Col key={index} className="col-md-4" xs={12}>
              <div
                className={`text-center ${styles.box} hoverDivWhite`}
                style={{ height: height ? height : "" }}
              >
                <div className={styles.boxImg}>
                  <img src={item.logo} alt={`Logo ${index}`} />
                  <div className={styles.userText}>
                    <h3>{item.title}</h3>
                    <p>{item.subHead}</p>
                    <div className={styles.star}>
                      <img src={stars} alt={`star`} width={120} height={18} />
                      {/* <img src={star} alt={`star`} />
                    <img src={star} alt={`star`} />
                    <img src={star} alt={`star`} />
                    <img src={star} alt={`star`} /> */}
                    </div>
                  </div>
                </div>

                <div className={styles.boxText}>
                  <p>{item.description}</p>
                  {pathname.includes("/whoWe") ? (
                    <a
                      href=""
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                        color: "#09547e",
                        fontWeight: 700,
                      }}
                    >
                      Read more
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ReviweSection;
