import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CategoryState {
  data: number; // Assuming data is of type number
}

const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: -1,
  } as CategoryState,
  reducers: {
    setCategoryId(state: any, action: PayloadAction<number>) {
      state.data = action.payload;
    },
  },
});
export const { setCategoryId } = categorySlice.actions;
export default categorySlice.reducer;
