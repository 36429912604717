import React from "react";
import styles from "./hiBooks.module.scss";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../../../common/theme/button";

interface DataItem {
  title: string;
  description: string;
  logo: string; // Assuming logo is a URL
}

interface HiBooksProps {
  dataBooks: DataItem[]; // Use the defined interface for the data prop
  heading: string;
  subHeading: string;
  type?: string;
  typeNew?: boolean;
  innerText?: string;
  imgLarge?: string;
  lineWhite?: string;
}

const HiBooks: React.FC<HiBooksProps> = ({
  dataBooks,
  heading,
  subHeading,
  type,
  typeNew,
  innerText,
  imgLarge,
  lineWhite,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.whoWe}>
      <Container>
        <Row className="align-items-center">
          <Col className="col-md-12 mb-4" xs={12}>
            <div className={`text-center ${styles.heading}`}>
              <h1>{heading}</h1>
              <p>{subHeading}</p>
            </div>
          </Col>

          {dataBooks.map((item, index) => (
            <Col key={index} className="col-md-4" xs={12}>
              <div className={`${styles.box} hoverDivWhite`}>
                <div className={styles.boxImg}>
                  <img
                    src={item.logo}
                    alt={`Logo ${index}`}
                    width={48}
                    height={58}
                  />
                </div>
                <div className={styles.boxText}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            </Col>
          ))}

          {type === "payroll" || type === "cisresturn" ? (
            <>
              <Col className="col-md-12">
                <div
                  className={
                    type === "cisresturn"
                      ? `${styles.LargeImg} hoverBtn `
                      : `${styles.LargeImg} hoverBtn hoverDivWhite`
                  }
                >
                  <img src={imgLarge} alt="dummy" />
                  <img src={lineWhite} className={styles.Lineimg} alt="dummy" />
                  <h5 className={styles.weAlso}>{innerText}</h5>
                  {typeNew && (
                    <Button
                      onClick={() => {
                        navigate("/whoWe/contractors");
                      }}
                      className={styles.actionButton_new}
                    >
                      Find out here
                    </Button>
                  )}
                </div>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default HiBooks;
