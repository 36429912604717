import React from "react";
import styles from "./InfoSection.module.scss";
import { Col, Container, Row } from "react-bootstrap";

interface InfoSectionProps {
  heading: string;
  subheading?: string;
  description1: string;
  description2: string;
  buttonText: string;
  imageUrl: string;
  imageAlt: string;
  reverseLayout?: boolean; // New prop to control layout direction
  onButtonClick?: () => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  heading,
  subheading,
  description1,
  buttonText,
  imageUrl,
  description2,
  imageAlt,
  onButtonClick,
  reverseLayout = false, // Default to false
}) => {
  return (
    <section className={styles.infoSection}>
    <Container>
      <Row className={styles.contentSec}>
   
      {reverseLayout ? (
            <>
              {/* Image on the left */}
              <Col md={5} xs={12} className="mb-4 mb-md-0 order-2 order-md-1 " >
                <div className={styles.imageContainer}>
                  <img src={imageUrl} alt={imageAlt} className={styles.image} />
                </div>
              </Col>
              {/* Text on the right */}
              <Col md={7} xs={12} className="order-1 order-md-2">
                <div className={styles.textContainer}>
                  {subheading && <p className={styles.subheading}>{subheading}</p>}
                  <h2 className={styles.heading}>{heading}</h2>
                  <p className={styles.description}>{description1}</p>
                  <p className={styles.description2}>{description2}</p>
                  <button className={styles.button} onClick={onButtonClick}>
                    {buttonText}
                  </button>
                </div>
              </Col>
            </>
          ) : (
            <>
              {/* Default layout: Text on the left, Image on the right */}
              <Col md={8} xs={12}>
                <div className={styles.textContainer}>
                  {subheading && <p className={styles.subheading}>{subheading}</p>}
                  <h2 className={styles.heading}>{heading}</h2>
                  <p className={styles.description}>{description1}</p>
                  <p className={styles.description2}>{description2}</p>
                  <button className={styles.button} onClick={onButtonClick}>
                    {buttonText}
                  </button>
                </div>
              </Col>
              <Col md={4} xs={12} className="mb-4 mb-md-0">
                <div className={styles.imageContainer}>
                  <img src={imageUrl} alt={imageAlt} className={styles.image} />
                </div>
              </Col>
            </>
          )}
      </Row>
    </Container>
    </section>
  );
};

export default InfoSection;
